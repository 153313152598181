export class Cart {
    uid: string;
    username: string;
    productID: string;
    variantID: string;
    sellerID: string;
    quantity: number;
    createdDate: Date;
    productName: string;
    sellerName: string;
    actualPrice: number;
    deliveryCharges: number;
    discount: number;
    discountPercent: number;
    deliveryMethod: string;
    mainThumbnail: string;
    brand: string;

}

export interface CartResponse {
    message: string;
    responseStatus: number;
    data: Cart[];
}

export interface OrderRequest {
    orderObject: Order;
    paymentObject: PaymentDetails;
}
export interface PaymentDetails {
    gateway: 'stripe';
    promocodeId: string;
    stripeNonce?: string;
    cardId?: string;
    total: number;
}
export class Order {
    _id: string;
    uid: string;
    username: string;
    status: string;
    gateway: string;
    totalAmount: number;
    amountPaid: number;
    discount: number;
    buyerName: string;
    street_a: string;
    street_b: string;
    city: string;
    country: string;
    postcode: string;
    telephone: string;
    products: Cart[];
    dateCreated: Date;
    datePayment: Date;
    promocodeId: string;
}

export interface OrderResponse {
    message: string;
    responseStatus: number;
    data: Order;
}

export interface Shipment {
    _id: string;
    sellerID: string;
    productID: string;
    inventoryReference: string;
    actualPrice: number;
    deliveryCharges: number;
    orderID: string;
    uid: string;
    username: string;
    status: string;
    street_a: string;
    street_b: string;
    city: string;
    country: string;
    postcode: string;
    telephone: string;
    dateCreated: Date;
    datePayment: Date;
    dateApproved: Date;
    dateDelivered: Date;
    discount: number;
    amountPaid: number;
    dateReturned: Date;
    promocodeID: string;
    paymentReference: string;
    dateSettled: Date;
    gateway: string;
    reasonReturn: string;
    dateRefunded: Date;
    amountRefund: number;
    quantity: number;
    sellerEmail: string;
    expectedDelivery: Date;
    dateShipped: Date;
    sellerPaymentAccount: string;
    variantID: string;
    productName: string;
    reasonReturnRejected: string;
    fee: number;
    buyerName: string;
    sellerServiceEmail: string;


}

export interface ShipmentList {
    list: Shipment[];
    jsonFields: string[];
    htmlFields: string[];
}
export interface ShipmentListResponse {
    message: string;
    responseStatus: number;
    data: ShipmentList;
}

export interface Promocode {
    _id: string;
    totalAmount: number;
    currency: string;
    usedAmount: number;
    discountFlat: number;
    discountPercent: number;
    status: string;
    dateCreated: Date;
    dateExpiry: Date;
    sellerID: string;
    buyerID: string;

}
export interface PromocodeResponse {
    message: string;
    responseStatus: number;
    data: Promocode;
}

export interface Charge {
    id: string;
    amount: number;
    status: string;

}

export interface PaymentIntent {
    id: string;
    client_secret: string;
    amount: number;
    error: {
        message: string;
    };
    charges: {

        data: Charge[];
    };
    metadata: any;
}

export interface SaveOrderResponse {
    message: string;
    responseStatus: number;
    data: {
        order: Order;
        intent: PaymentIntent
    };

}
