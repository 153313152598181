// import 'rxjs/add/observable/of';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {HttpService} from './http.service';
import { SellerListResponse } from '../classes/seller';

@Injectable()
export class SellerService {

    constructor(
        private http: HttpService,

    ) { }

    getSellersList(searchJson, limit, skip, sort): Observable<SellerListResponse> {
        let url = '/v1/api/sellers/postsellerslist?';
        if (limit) { url += `limit=${limit}&`; }
        if (skip) { url += `skip=${skip}&`; }
        if (sort) { url += `sort=${sort}&`; }
        return this.http.postHttpJson(url, searchJson);
    }

}
