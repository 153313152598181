import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Review } from '../../classes/review';
import { DisplayErrorService, DisplayError } from '../../services/display-error.service';
import { Product } from '../../classes/product';
import { ReviewsService } from '../../services/reviews.service';
import { Seller } from '../../classes/seller';
import { TagService } from '../../services/tag.service';
import { Tag } from '../../classes/tag';
import { Personroom } from '../../classes/personroom';
import { Post } from '../../classes/post';
import { Profile } from '../../classes/profile';
import { ProfileService } from '../../services/profile.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-add-review',
  templateUrl: './add-review.component.html',
  styleUrls: ['./add-review.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddReviewComponent implements OnInit {

    @Input() productObj: Product;
    @Input() personroomObj: Personroom;
    @Input() postObj: Post;
    @Input() authorProfile: Profile;
    @Input() personroomCreator: Profile;

    @Input() sellerObj: Seller;
    @Input() reviewObj: Review;
    @Input() type: string;
    @Input() brandTags: Map<string, Tag> = new Map;
    @Input() roomTags: Map<string, Tag> = new Map;

    @Output() closed: EventEmitter<any> = new EventEmitter();
    @Output() addedReview: EventEmitter<Review[]> = new EventEmitter();


    addReviewError: DisplayError;
    uid: string;


    constructor(
        private errorService: DisplayErrorService,
        private reviewService: ReviewsService,
        private tagService: TagService,
        private profileService: ProfileService,
        private cd: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        console.log(this.reviewObj, this.postObj)
        switch (this.type) {
            case 'products':
                this.tagService.cachedTagsList('brand').pipe(first()).subscribe(result => {
                    this.brandTags = result;
                    this.cd.detectChanges();
                });
                break;
            case 'personrooms':
                this.tagService.cachedTagsList('room').pipe(first()).subscribe(result => {
                    this.roomTags = result;
                    this.cd.detectChanges();
                });
                break;
            default:

        }
        this.uid = this.profileService.getUid();

    }

    changeRating(reviewObj: Review, rating: number, completed: boolean) {
        if (!completed) {
            reviewObj.rating = rating;
        }
    }

    deleteReview() {
        if (this.reviewObj && this.reviewObj.uid === this.uid) {
            this.errorService.reportBusy(1);
            this.reviewService.deleteReview(this.reviewObj._id)
            .pipe(first()).subscribe(result => {
                this.errorService.reportBusy(-1);
                this.closed.emit([]);

                if (result.message) {

                    this.errorService.reportError(new DisplayError('success', result.message, '', this.cd));
                } else {
                    this.errorService.reportError(new DisplayError('success', 'Deleted review', '', this.cd));
                }
                this.cd.detectChanges();
            }, err => {
                this.errorService.reportBusy(-1);
                if (err.error && err.error.message) {
                    this.errorService.reportError(new DisplayError('error', err.error.message, '', this.cd));
                } else {
                    this.errorService.reportError(new DisplayError('error', 'Error deleting your review', '', this.cd));
                }
                this.cd.detectChanges();

            });
        }
    }

    submitReview() {

        if (this.reviewObj) {
            if (!this.reviewObj.rating) {
                this.addReviewError = new DisplayError('error', 'Please provide a rating', '', this.cd);
                return;
            }
            this.errorService.reportBusy(1);
            this.reviewService.addReview(this.reviewObj)
            .pipe(first()).subscribe(result => {
                this.errorService.reportBusy(-1);
                this.addedReview.emit([this.reviewObj]);

                this.reviewObj = new Review;
                this.closed.emit([]);

                if (result.message) {
                    this.addReviewError = new DisplayError('success', result.message, '', this.cd);
                } else {
                    this.addReviewError = new DisplayError('success', 'Submitted your review', '', this.cd);
                }
                this.cd.detectChanges();
            }, err => {
                this.errorService.reportBusy(-1);
                if (err.error && err.error.message) {
                    this.addReviewError = new DisplayError('error', err.error.message, '', this.cd);
                } else {
                    this.addReviewError = new DisplayError('error', 'Error submitting your review', '', this.cd);
                }
                this.cd.detectChanges();
            });
        }
    }

}
