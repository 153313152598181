import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { ngxZendeskWebwidgetModule, ngxZendeskWebwidgetConfig } from 'ngx-zendesk-webwidget';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
// import { HomeModule } from './home/home.module';
import { WildcardRoutingModule } from './wildcard-routing/wildcard-routing.module';

import { ContentService } from './services/content.service';
import { GameService } from './services/game.service';
import { NavigationService } from './services/navigation.service';
import { PersonroomService } from './services/personroom.service';
// import { ProductService } from './services/product.service';
import { ProfileService } from './services/profile.service';
import { SearchService } from './services/search.service';
import { PurchaseService } from './services/purchase.service';
import { ReviewsService } from './services/reviews.service';
import { TagService } from './services/tag.service';
import { UtilsService, LocalStorageWrapper, LS_CACHE } from './services/utils.service';
import { PostService } from './services/post.service';
import { SellerService } from './services/seller.service';
import { HttpService } from './services/http.service';
import { DisplayErrorService } from './services/display-error.service';
import { AuthService, AuthGuardService } from './services/auth.service';
import { GeneralHttpInterceptor, CachingInterceptor, CacheService } from './services/http.interceptor';
import { LazyLoadImageModule } from 'ng-lazyload-image';

// import { CookieService } from 'ngx-cookie-service';
import { NgxJsonLdModule } from 'ngx-json-ld';

// import { ModalModule } from 'ngx-modialog';
// import { BootstrapModalModule } from 'ngx-modialog/plugins/bootstrap/ngx-modialog-plugins-bootstrap';
// import { CookieWrapperService } from './services/_cookie-wrapper.service';
import { LocalStorage } from './services/utils.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedModule } from './shared/shared.module';
import { PostLoginComponent } from './post-login/post-login.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CommonModule } from '@angular/common';
import { NewHttpService } from './services/new.http.service.service';
import { SlickCarouselModule } from 'ngx-slick-carousel';
// import { BrowserXhr } from '@angular/http';
// import {ExtBrowserService} from './services/ext-browser.service'
// import { FacebookModule } from 'ngx-facebook';
// import * as LSCache from 'lscache';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { NotificationService } from './services/notification.service';
import { ProductModule } from './product/product.module';
export class ZendeskConfig extends ngxZendeskWebwidgetConfig {

  accountUrl = 'moodmaestro.zendesk.com';
  beforePageLoad(zE) {
    zE.setLocale('en');
    // zE.hide();
  }
}

export function _LSCache() {
  return require('lscache');
}


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PostLoginComponent
  ],
  exports: [

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'moodeco' }),
    ngxZendeskWebwidgetModule.forRoot(ZendeskConfig),
    TransferHttpCacheModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxJsonLdModule,
    // HomeModule,
    AppRoutingModule,
    WildcardRoutingModule,
    LazyLoadImageModule,
    ReactiveFormsModule,
    SharedModule,
    SlickCarouselModule,
    ProductModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    SimpleNotificationsModule.forRoot()
  ],
  providers: [
    // {provide: BrowserXhr, useClass:ExtBrowserService},
    { provide: LS_CACHE, useFactory: _LSCache },
    ContentService,
    GameService,
    NavigationService,
    PersonroomService,
    UtilsService,
    ProfileService,
    PurchaseService,
    ReviewsService,
    PostService,
    SellerService,
    HttpService,
    NewHttpService,
    AuthService,
    AuthGuardService,
    CacheService,
    SearchService,
    // CookieWrapperService,
    TagService,
    LocalStorage,
    LocalStorageWrapper,
    NotificationService,
    DisplayErrorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GeneralHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CachingInterceptor,
      multi: true
    },
    // CookieService,



  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
