import { Component, OnInit, PLATFORM_ID, Inject, Input } from '@angular/core';
import { Personroom } from '../../classes/personroom';
import { Product, Store } from '../../classes/product';
import { GTMProduct, GTMEcommerceData } from '../../classes/ecommerce';
import { isPlatformBrowser } from '@angular/common';
import { Post } from '../../classes/post';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ProductService } from '../../services/product.service';
import { Tag } from '../../classes/tag';
import { UtilsService } from '../../services/utils.service';
// import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-social-share-snippet',
  templateUrl: './social-share-snippet.component.html',
  styleUrls: ['./social-share-snippet.component.scss']
})
export class SocialShareSnippetComponent implements OnInit {
    @Input() product: Product;
    @Input() tag: Tag;
    @Input() personroom: Personroom;
    @Input() post: Post;
    @Input() brandStore: Store;
    @Input() roomStore: Store;
    @Input() categoryStore: Store;
    @Input() styleStore: Store;


    isBrowser: boolean;
    baseHref = environment.baseHref;
    app_id = environment.facebookAppID;
    itemId: string;
    isMobile: boolean;


    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private router: Router,
        private productService: ProductService,
        private utilService: UtilsService,

    ) { }

    ngOnInit() {
        this.isBrowser = isPlatformBrowser(this.platformId);
        if (this.isBrowser) {
            this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i
            .test(window.navigator.userAgent);
        }
        if (this.product) {
            this.itemId = this.product._id;
        } else if (this.tag) {
            this.itemId = this.tag._id;
        } else if (this.personroom) {
            this.itemId = this.personroom._id;
        } else if (this.post) {
            this.itemId = this.post._id;
        } else if (this.brandStore) {
            this.itemId = this.brandStore._id;
        } else if (this.roomStore) {
            this.itemId = this.roomStore._id;
        } else if (this.categoryStore) {
            this.itemId = this.categoryStore._id;
        } else if (this.styleStore) {
            this.itemId = this.styleStore._id;
        }

    }

    openShareWindow(type: 'whatsapp' | 'whatsapp-web' | 'pinterest' | 'facebook', item: Product|Personroom|Post|Store|Tag) {


        switch (type) {
            case 'whatsapp':
                // window.open(`whatsapp://send?text=${this.baseHref}${this.router.url}`);
                location.href = `whatsapp://send?text=${this.baseHref}${this.router.url}`;
                break;
            case 'whatsapp-web':
                window.open(`https://web.whatsapp.com/send?text=${this.baseHref}${this.router.url}`, '_blank');
                break;
            case 'pinterest':
                window.open(
                    `https://www.pinterest.com/pin/create/button/?url=${this.baseHref}${this.router.url}\
                    &media=${item.mainPicture}&description=${this.getUrlEncoded(item.name, item.seo_description)}`, '_blank');
                break;
            case 'facebook':
                window.open(`https://www.facebook.com/sharer/sharer.php?display=popup&app_id=${this.app_id}\
                &u=${this.baseHref}${this.router.url}`, '_blank');
                break;

            default:
                break;
        }
    }

    socialShare(type: 'whatsapp'|'whatsapp-web'|'pinterest'|'facebook', ) {
        if (!this.isBrowser) { return; }
        if (!this.product) {
            if (this.personroom) {
                this.openShareWindow(type, this.personroom);
            } else if (this.post) {
                this.openShareWindow(type, this.post);
            } else if (this.tag) {
                this.tag.seo_description = this.getDescriptionText(this.tag);
                this.openShareWindow(type, this.tag);
            } else if (this.brandStore) {
                this.openShareWindow(type, this.brandStore);
            } else if (this.roomStore) {
                this.openShareWindow(type, this.roomStore);
            } else if (this.categoryStore) {
                this.openShareWindow(type, this.categoryStore);
            } else if (this.styleStore) {
                this.openShareWindow(type, this.styleStore);

            }
        } else {
            // console.log('social share 64', type, this.product);
            this.addDataLayer('socialShare', [this.product], <GTMProduct>{list: type}, () => {
                this.openShareWindow(type, this.product);
            });

        }
    }
    getUrlEncoded(name: string, seo_description: string): string {
        return encodeURIComponent(`${name}${seo_description ? ': ' + seo_description : ''} | Moodmaestro`);
    }
    addDataLayer(type: 'socialShare', list: Product[], options?: GTMProduct, callback?: () => void) {
        if (this.isBrowser) {

            const gtmProducts = list.map((x, i) => {
                return this.productService.getGTMProduct(x, options);
            });
            window['dataLayer'] = window['dataLayer'] || [];

            switch (type) {

                case 'socialShare':
                    window['dataLayer'].push(<GTMEcommerceData>{
                        event: 'socialShare',
                        ecommerce: Object.assign(this.productService.getNewDataLayer().ecommerce, {
                            currencyCode: 'GBP',
                            detail: {
                                products: gtmProducts,
                            },
                            impressions: [],
                        }),
                        eventCallback: callback
                    });
                    break;

                default:
                    break;
            }


        }
    }
    getDescriptionText(brandTag: Tag): string {
        const limitLength = 250;
        const description = this.utilService.htmlToPlaintext(brandTag.description);
        return description.length > limitLength ? description.substring(0, limitLength) + '...' : description;
    }



}
