import { Injectable, Injector, PLATFORM_ID, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router, CanActivate, ActivatedRoute, UrlTree, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalStorage, LocalStorageWrapper } from './utils.service';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { DisplayErrorService } from './display-error.service';
import { first } from 'rxjs/operators';
const AmazonCognitoIdentity = require('amazon-cognito-auth-js/dist/amazon-cognito-auth');


@Injectable(
)
export class AuthService {
    private refreshInterval;
    congnitoConfig = environment.cognito;
    baseHref =
        `${this.document.location.protocol}//${this.document.location.hostname}${this.document.location.port ? ':'
            + this.document.location.port : ''}`;
    auth;

    constructor(
        public router: Router,
        private localStorage: LocalStorage,
        private errorService: DisplayErrorService,
        private storageService: LocalStorageWrapper,
        // private injector: Injector,
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(DOCUMENT) private document,

    ) {


        if (isPlatformBrowser(this.platformId)) {






            this.refreshInterval = setInterval(
                (function (self) {
                    return function () {
                        self.refreshSession();
                    };
                })(this), 3000 * 1000
            );

        }
        this.congnitoConfig.RedirectUriSignIn = this.congnitoConfig.RedirectUriSignIn.replace('$baseHref', this.baseHref);
        this.congnitoConfig.RedirectUriSignOut = this.congnitoConfig.RedirectUriSignOut.replace('$baseHref', this.baseHref);
        // console.log('46 ', this.congnitoConfig, this.document.location.protocol, this.document.location.hostname)
        this.auth = new AmazonCognitoIdentity.CognitoAuth(this.congnitoConfig);
        this.refreshSession();


        // this.localStorage = this.injector.get(LocalStorage);

    }

    public getSessionToken(): Observable<string> {

        return new Observable<string>((observer) => {

            if (this.auth.isUserSignedIn()) {
                this.auth.userhandler = {
                    onSuccess: result => {
                        const idToken = result.idToken.jwtToken;
                        observer.next(idToken);
                        observer.complete();
                    },
                    onFailure: err => {
                        observer.error(err);
                    }
                };
                this.auth.getSession();

            } else {
                observer.next();
                observer.complete();

            }

        });
    }

    /** signout */
    public signOut() {
        this.auth.signOut();
        this.errorService.reportLogin(false);
        this.storageService.setCart([]);
    }

    /** boolean if logged in */
    public isLoggedIn(): boolean {
        return true && this.auth.isUserSignedIn();
    }

    public getAccessToken(): Observable<string> {
        return new Observable<string>((observer) => {

            if (this.auth.isUserSignedIn()) {
                this.auth.userhandler = {
                    onSuccess: result => {
                        const accessToken = result.accessToken.jwtToken;
                        observer.next(accessToken);
                        observer.complete();
                    },
                    onFailure: err => {
                        observer.error(err);
                    }
                };
                this.auth.getSession();
            } else {
                observer.next();
                observer.complete();
            }
        });
    }
    public getRefreshToken(): Observable<string> {

        return new Observable<string>((observer) => {

            if (this.auth.isUserSignedIn()) {
                this.auth.userhandler = {
                    onSuccess: result => {
                        const refreshToken = result.refreshToken.refreshToken;
                        observer.next(refreshToken);
                        observer.complete();
                    },
                    onFailure: err => {
                        observer.error(err);
                    }
                };
                this.auth.getSession();

            } else {
                observer.next();
                observer.complete();

            }

        });
    }

    public getLastUser(): string {
        return this.auth.getLastUser();
    }

    public setSession(curUrl: string) {


        const tree: UrlTree = this.router.parseUrl(curUrl);
        if (this.localStorage.getItem('cognitoState') &&
            (!tree.queryParams['state'] || tree.queryParams['state'] !== this.localStorage.getItem('cognitoState'))) {
            return;
        }
        this.localStorage.removeItem('cognitoState');

        this.auth.userhandler = {
            onSuccess: () => {

                const prevLocation: string = this.localStorage.getItem('lastLocation');
                this.router.navigateByUrl(prevLocation || '/');
                this.localStorage.removeItem('lastLocation');
                this.errorService.reportLogin(true);

            },
            onFailure: err => {
                // TODO: report error
            },
        };
        this.auth.parseCognitoWebResponse(curUrl);

    }

    public refreshSession() {
        this.getRefreshToken()
            .pipe(first()).subscribe(token => {
                if (!token) {
                    // this.storageService.setCart([]);
                    return;
                }
                this.auth.userhandler = {
                    onSuccess: result => {

                    },
                    onFailure: err => {

                    }
                };
                this.auth.refreshSession(token);
            }, err => {

            });
    }

    private makeId() {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 12; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }

    public gotoLogin(redirect?: string) {
        const config = environment.cognito;
        const RedirectUriSignIn = `${this.baseHref}/post-login`;

        const stateValue: string = this.makeId();
        this.localStorage.setItem('cognitoState', stateValue);

        this.localStorage.setItem('lastLocation', redirect || this.router.url);

        const url = 'https://' + config.AppWebDomain + '/login?redirect_uri=' +
            RedirectUriSignIn + '&response_type=' + config.responseType +
            '&client_id=' + config.ClientId + '&state=' + stateValue;
        // Launch hosted UI
        location.assign(url);
        // this.router.navigateByUrl(url);
    }

    public gotoSignUp(redirect?: string) {
        const config = environment.cognito;
        const RedirectUriSignIn = `${this.baseHref}/post-login`;

        const stateValue: string = this.makeId();
        this.localStorage.setItem('cognitoState', stateValue);

        this.localStorage.setItem('lastLocation', redirect || this.router.url);

        const url = 'https://' + config.AppWebDomain + '/signup?redirect_uri=' +
            RedirectUriSignIn + '&response_type=' + config.responseType +
            '&client_id=' + config.ClientId + '&state=' + stateValue;

        // Launch hosted UI
        location.assign(url);
    }
}

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(public auth1: AuthService, public router: Router, private route: ActivatedRoute) { }
    canActivate(actRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.auth1.isLoggedIn()) {
            const redirect = state.url;
            this.auth1.gotoLogin(redirect);
            return false;
        }
        return true;
    }
}
