import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef,
    AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { Product } from '../../classes/product';
import { Tag } from '../../classes/tag';

@Component({
    selector: 'app-product-snippet-small',
    templateUrl: './product-snippet-small.component.html',
    styleUrls: ['./product-snippet-small.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,

})
export class ProductSnippetSmallComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() product: Product;
    @Input() brandTag: Tag;

    @Output() brandClicked: EventEmitter<any> = new EventEmitter();
    @Output() openDetails: EventEmitter<any> = new EventEmitter();



    constructor(
        private cdr: ChangeDetectorRef,

    ) { }

    ngOnInit() {

    }
    ngAfterViewInit() {
        this.cdr.detach();
    }
    ngOnChanges(changes: SimpleChanges) {
        this.cdr.detectChanges();
    }

    openProductDetails(product: Product) {
        this.openDetails.emit([product]);
    }
}
