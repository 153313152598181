import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import {Animations} from './animations';
// import { TagService } from '../../services/tag.service';
// import { isPlatformBrowser } from '@angular/common';
import { GameVerdict } from '../../classes/game';
import { Tag } from '../../classes/tag';
import { Profile } from '../../classes/profile';
import { ProfileService } from '../../services/profile.service';
import { AuthService } from '../../services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { first } from 'rxjs/operators';
// import { ActivatedRoute } from '@angular/router';


@Component({
    selector: 'app-mini-verdict',
    templateUrl: './mini-verdict.component.html',
    styleUrls: ['./mini-verdict.component.css'],
    animations: Animations.VerdictTrigger,
    changeDetection: ChangeDetectionStrategy.OnPush,

})
export class MiniVerdictComponent implements OnInit, AfterViewInit {
    @Input() verdict: GameVerdict;
    @Input() filterTags: Map<string, Tag> ;
    @Input() styleTags: Map<string, Tag> ;
    @Input() showStylePopup: boolean ;

    @Output() close: EventEmitter<any> = new EventEmitter();


    isBrowser: boolean;
    myProfile: Profile;
    startSecondAnimation = false;
    selectedFilterForPreview: string;

    constructor(
        // private route: ActivatedRoute,
        // private gameService: GameService,
        // private errorService: DisplayErrorService,
        // private router: Router,
        // private localStorage: LocalStorage,
        // @Inject(PLATFORM_ID) private platformId: Object,
        // private tagService: TagService,
        // private navigationService: NavigationService,
        private profileService: ProfileService,
        private authService: AuthService,
        private cd: ChangeDetectorRef,

    ) { }

    ngOnInit() {

        if (this.authService.isLoggedIn) {
            this.profileService.getCachedOwnProfile().pipe(first()).subscribe(profileData => {
                this.myProfile = profileData.data;
            });

        }
        if (this.verdict && this.verdict.sortedFilters && this.verdict.sortedFilters.length > 0) {
            this.selectedFilterForPreview = this.verdict.sortedFilters[0];
        }

    }
    ngAfterViewInit() {
        this.cd.detach();
    }
    closeWasClicked(): void {
        this.close.emit([]);
    }
    openTag(tagId: string) {
        window.open(`/info/tag/${tagId}`);
    }
    mathRound(x: number) {
        return Math.round(x);
    }

    startAnimation() {
        this.startSecondAnimation = true;
        this.cd.detectChanges();
    }

    previewFilter(fil: string) {
        this.selectedFilterForPreview = fil;
        this.cd.detectChanges();
    }

    stripHtml(str: string): string {
        return str.replace(/(<([^>]+)>)/ig, '');
    }


}
