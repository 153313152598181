import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Review } from '../../classes/review';
import { ProfileService } from '../../services/profile.service';
import { DisplayErrorService, DisplayError } from '../../services/display-error.service';
import { ReviewsService } from '../../services/reviews.service';
// import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import * as Filter from 'bad-words';

const customFilter = new Filter({ placeHolder: 'x'});

@Component({
  selector: 'app-reviews-snippet',
  templateUrl: './reviews-snippet.component.html',
  styleUrls: ['./reviews-snippet.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class ReviewsSnippetComponent implements OnInit, AfterViewInit {
    @Input() reviews: Review[];
    uid: string;

    constructor(
        private profileService: ProfileService,
        private errorService: DisplayErrorService,
        private reviewService: ReviewsService,
        // private router: Router,
        private cd: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        this.uid = this.profileService.getUid();

    }

    cleanComment(cmt: string): string {
        return customFilter.clean(cmt);
    }

    deleteReview(reviewObj: Review) {
        if (reviewObj && reviewObj.uid === this.uid) {
            this.errorService.reportBusy(1);
            this.reviewService.deleteReview(reviewObj._id)
            .pipe(first()).subscribe(result => {
                this.errorService.reportBusy(-1);
                this.reviews = this.reviews.filter(r => r._id !== reviewObj._id);
                // this.closed.emit([]);

                if (result.message) {

                    this.errorService.reportError(new DisplayError('success', result.message, '', this.cd));
                } else {
                    this.errorService.reportError(new DisplayError('success', 'Deleted review', '', this.cd));
                }
                this.cd.detectChanges();
            }, err => {
                this.errorService.reportBusy(-1);
                if (err.error && err.error.message) {
                    this.errorService.reportError(new DisplayError('error', err.error.message, '', this.cd));
                } else {
                    this.errorService.reportError(new DisplayError('error', 'Error deleting your review', '', this.cd));
                }
                this.cd.detectChanges();
            });
        }
    }
    ngAfterViewInit() {
        this.cd.detach();
    }
    // getSafeJsonLd() {
    //     return {

    //     };
    // }

}

