import { Injectable, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


export class DisplayError {

    public title: string;
    public type: string;
    public message: string;
    setAliveTime: 5000;

    constructor(
        type: string,
        message: string,
        title: string,
        cdr: ChangeDetectorRef,
    ) {
        this.type = type;
        this.message = message;
        this.title = title;
        // if (cdr) cdr.detectChanges();

        if (environment.showErrorTimeout) {
            setTimeout(() => {
                this.message = '';
                if (cdr && !cdr['destroyed']) { cdr.detectChanges(); }
                // console.log('24')

            }, environment.showErrorTimeout);

        }

    }

}

@Injectable()
export class DisplayErrorService {


    private observers = [];
    private isBusy = 0;
    private cartSize = 0;
    private currentSearchTerm;
    private currentModule = '';
    private busyObservers = [];
    private cartObservers = [];
    private moduleObservers = [];
    private searchObservers = [];
    private loginObservers = [];
    public searchStream: Observable<string>;
    public loginStream: Observable<boolean>;
    public errorStream: Observable<DisplayError>;
    public busyStream: Observable<number>;
    public cartStream: Observable<number>;
    public moduleStream: Observable<string>;



    constructor() {

        if (!this.observers) {
            this.observers = [];
        }
        if (!this.busyObservers) {
            this.busyObservers = [];
        }
        if (!this.cartObservers) {
            this.cartObservers = [];
        }
        if (!this.moduleObservers) {
            this.moduleObservers = [];
        }
        if (!this.searchObservers) {
            this.searchObservers = [];
        }
        if (!this.loginObservers) {
            this.loginObservers = [];
        }

        this.errorStream = new Observable(observer => {
            this.observers.push(observer);
            return {
                unsubscribe() {
                    this.observers.splice(this.observers.indexOf(observer), 1);
                }
            };
        });
        this.searchStream = new Observable(observer => {
            this.searchObservers.push(observer);
            return {
                unsubscribe() {
                    this.searchObservers.splice(this.searchObservers.indexOf(observer), 1);
                }
            };
        });
        this.busyStream = new Observable(observer => {
            this.busyObservers.push(observer);
            return {
                unsubscribe() {
                    // Remove from the observers array so it's no longer notified
                    this.busyObservers.splice(this.busyObservers.indexOf(observer), 1);
                }
            };
        });
        this.cartStream = new Observable(observer => {
            this.cartObservers.push(observer);
            return {
                unsubscribe() {
                    // Remove from the observers array so it's no longer notified
                    this.cartObservers.splice(this.cartObservers.indexOf(observer), 1);
                }
            };
        });
        this.loginStream = new Observable(observer => {
            this.loginObservers.push(observer);
            return {
                unsubscribe() {
                    // Remove from the observers array so it's no longer notified
                    this.loginObservers.splice(this.loginObservers.indexOf(observer), 1);
                }
            };
        });
        this.moduleStream = new Observable(observer => {
            this.moduleObservers.push(observer);
            return {
                unsubscribe() {
                    // Remove from the observers array so it's no longer notified
                    this.moduleObservers.splice(this.moduleObservers.indexOf(observer), 1);
                }
            };
        });

    }

    getErrorSubscription(): Observable<DisplayError> {
        return this.errorStream;
    }
    getBusySubscription(): Observable<number> {
        return this.busyStream;
    }
    getCartSubscription(): Observable<number> {
        return this.cartStream;
    }
    getModuleSubscription(): Observable<string> {
        return this.moduleStream;
    }
    getSearchSubscription(): Observable<string> {
        return this.searchStream;
    }
    getLoginSubscription(): Observable<boolean> {
        return this.loginStream;
    }


    reportError(error: DisplayError) {
        if (this.observers.length > 0) {
            this.observers.forEach(obs => obs.next(error));
        }
    }
    reportBusy(busy: number) {
        this.isBusy += busy;
        if (this.busyObservers.length > 0) {
            this.busyObservers.forEach(obs => obs.next(this.isBusy));
        }
    }
    reportCart(cartSize: number) {
        this.cartSize = cartSize;
        if (this.cartObservers.length > 0) {
            this.cartObservers.forEach(obs => obs.next(this.cartSize));
        }
    }
    reportLogin(loggedIn: boolean) {
        // this.loggedIn = cartSize;
        if (this.loginObservers.length > 0) {
            this.loginObservers.forEach(obs => obs.next(loggedIn));
        }
    }
    reportModule(mod: string) {
        this.currentModule = mod;
        if (this.moduleObservers.length > 0) {
            this.moduleObservers.forEach(obs => obs.next(this.currentModule));
        }
    }
    reportSearch(term: string) {
        this.currentSearchTerm = term;
        if (this.searchObservers.length > 0 ) {
            this.searchObservers.forEach(obs => obs.next(this.currentSearchTerm));
        }
    }

    setErrorForDeletion(err: DisplayError, durationMs: number) {
        setTimeout(() => {
            err = null;
        }, durationMs);

    }






}
