import { isPlatformBrowser } from '@angular/common';
import { Injectable } from '@angular/core';

export class Profile {
    _id: string;
    uid: string;
    name: string;
    username: string;
    city: string;
    country: string;
    postcode: string;
    telephone: string;
    address: string;
    birthdate?: Date;
    gender: string;
    pictureURL: string;
    paymentId: string;



}

export interface ProfileResponse {
    responseStatus: number;
    message: string;
    data: Profile;

}

export interface StringResponse {
    responseStatus: number;
    message: string;
    data: string;
}

export interface Favourite {

    _id: string;
    itemId: string;
    itemType: string;
    uid: string;

}

export interface FavouritesResponse {
    responseStatus: number;
    message: string;
    data: Favourite[];

}

export interface ProfileMapResponse {

    responseStatus: number;
    message: string;
    data: Map <string, Profile>;
}

export interface ProfileListResponse {
    responseStatus: number;
    message: string;
    data: Profile[];

}




