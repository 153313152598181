import { Injectable, Inject, Injector, InjectionToken } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
// import { HttpService } from './http.service';
import * as LSCache from 'lscache';
import { Cart } from '../classes/purchase';
import { Profile } from '../classes/profile';
import { ProductEvent } from '../classes/product';
import { GameVerdict } from '../classes/game';
import { GTMEcommerceData } from '../classes/ecommerce';

export const LS_CACHE = new InjectionToken('lscache');

export function debounce(delay: number = 300): MethodDecorator {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        let timeout = null;

        const original = descriptor.value;

        descriptor.value = function (...args) {
            clearTimeout(timeout);
            timeout = setTimeout(() => original.apply(this, args), delay);
        };

        return descriptor;
    };
}


@Injectable()
export class UtilsService {


    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        // private lscache: lscache;

    ) {

    }

    flags: Map<string, boolean> = new Map;
    sophomorePollFrequency = 10;
    sophomoreMaxDuration = 10000;




    markSophomore (name: string) {
        this.flags[name] = true;
        setTimeout(() => {delete this.flags[name]; }, this.sophomoreMaxDuration);
    }

    checkSophomore (name: string): Observable<string> {

        return new Observable<string> ((observer) => {

            if (!this.flags[name] || !isPlatformBrowser(this.platformId)) {
                observer.next(name);
                observer.complete();
            } else if (isPlatformBrowser(this.platformId)) {
                setInterval(() => {
                    if (!this.flags[name]) {
                        observer.next(name);
                        observer.complete();
                    }
                }, this.sophomorePollFrequency);
            }
        });
    }

    clearSophomore (name: string) {
        delete this.flags[name] ;

    }
    htmlToPlaintext(text): string {
        return text ? String(text).replace(/<[^>]+>/gm, '') : '';
    }

    // addDataLayer(data: GTMEcommerceData) {
    //     (<any>window).dataLayer.push(data);
    // }



}

@Injectable()
export class LocalStorage {
    cache: Map<string, string> = new Map;
    defaultExpiryMinutes: number = environment.defaultCacheExpiryMinutes;


    // private lscache: LSCache ;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        // @Inject(LSCache) private lscache: Object,
        @Inject(LS_CACHE) private lscache: LSCache,
        // private lscache: LSCache,
        // @Inject(PLATFORM_ID) private platformId: Object,
        // private lscache: LSCache,

    ) {
        // this.lscache = LSCache().clone();

     }

    getItem(id: string): string {

        if (isPlatformBrowser(this.platformId)) {
            return this.lscache.get(id);
            // return localStorage.getItem(id);
        } else {
            return this.cache[id];
        }
    }
    setItem(id: string, value: string, expiryMinutes?: number) {

        if (!expiryMinutes) {
            expiryMinutes = this.defaultExpiryMinutes;
        }

        if (isPlatformBrowser(this.platformId)) {
            // localStorage.setItem(id, value);
            this.lscache.set(id, value, expiryMinutes);
        } else {
            this.cache[id] = value;
        }
    }
    removeItem(id: string) {
        if (isPlatformBrowser(this.platformId)) {
            this.lscache.remove(id);
            // localStorage.removeItem(id);
        } else {
            delete this.cache[id];
        }
    }
}

@Injectable()
export class LocalStorageWrapper {

    maxProductEvents = 10;

    oneYearMinutes =  365 * 24 * 60 ;
    oneMonthMinutes =  30 * 24 * 60 ;
    oneWeekMinutes = 7 * 24 * 60 ;
    oneDayMinutes =  1 * 24 * 60;
    localStorage: LocalStorage;

    constructor(
        private injector: Injector,
        @Inject(PLATFORM_ID) private platformId: Object,

        // private localStorage: LocalStorage,
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.localStorage = this.injector.get(LocalStorage);
        }

    }

    setCart(cart: Cart[]) {

        if (!isPlatformBrowser(this.platformId) || !this.localStorage) {
            return;
        } else {
            if (!cart) {
                this.localStorage.removeItem('cart');
                return;
            }
            this.localStorage.setItem('cart', JSON.stringify(cart), this.oneYearMinutes);

        }

    }


    getCart(): Cart[] {

        if (!isPlatformBrowser(this.platformId) || !this.localStorage) {
            return;
        } else {
            try {
                const cart = JSON.parse(this.localStorage.getItem('cart'));
                return cart;
            } catch (e) {
                return null;
            }

        }
    }
    setLastUser(profile: Profile) {

        if (!isPlatformBrowser(this.platformId) || !this.localStorage) {
            return;
        } else {
            if (!profile) {
                this.localStorage.removeItem('last-user');
                return;
            }
            const allow = this.localStorage.getItem('allow-personal');
            if (!allow || allow !== 'yes') {
                return;
            }
            this.localStorage.setItem('last-user', JSON.stringify(profile), this.oneYearMinutes);

        }
    }

    getLastUser(): Profile {

        if (!isPlatformBrowser(this.platformId) || !this.localStorage) {
            return;
        } else {
            const allow = this.localStorage.getItem('allow-personal');
            if (!allow || allow !== 'yes') {
                return;
            }

            try {
                const cart = JSON.parse(this.localStorage.getItem('last-user'));
                return cart;
            } catch (e) {
                return null;
            }

        }
    }
    setLastVerdict(verdict: GameVerdict) {

        if (!isPlatformBrowser(this.platformId) || !this.localStorage) {
            return;
        } else {
            if (!verdict) {
                this.localStorage.removeItem('last-verdict');
                return;
            }
            this.localStorage.setItem('last-verdict', JSON.stringify(verdict), this.oneYearMinutes);

        }
    }

    getLastVerdict(): GameVerdict {

        if (!isPlatformBrowser(this.platformId) || !this.localStorage) {
            return;
        } else {
            try {
                const verdict = JSON.parse(this.localStorage.getItem('last-verdict'));
                return verdict;
            } catch (e) {
                return null;
            }

        }
    }

    recordProductEvent(eventArr: ProductEvent[]) {

        if (!isPlatformBrowser(this.platformId) || !this.localStorage) {
            return;
        } else {
            const allow = this.localStorage.getItem('allow-personal');
            if (!allow || allow !== 'yes') {
                return;
            }

            try {
                let events = JSON.parse(this.localStorage.getItem('record-products') || '[]');
                eventArr.forEach(event => {
                    events = events.filter(function(x) {return x._id !== event._id; });
                    events.unshift(event);
                });
                events = events.slice(0, this.maxProductEvents);

                this.localStorage.setItem('record-products', JSON.stringify(events), this.oneYearMinutes);
            } catch (e) {

            }
        }

    }

    getRecordedProductsEvents(): ProductEvent[] {

        if (!isPlatformBrowser(this.platformId) || !this.localStorage) {
            return;
        } else {
            const allow = this.localStorage.getItem('allow-personal');
            if (!allow || allow !== 'yes') {
                return;
            }

            try {
                const events = JSON.parse(this.localStorage.getItem('record-products'));
                return events;
            } catch (e) {
                return null;
            }
        }

    }
    allowPersonalData() {
        if (!isPlatformBrowser(this.platformId) ) {
            return;
        } else {
            this.localStorage.setItem('allow-personal', 'yes', this.oneYearMinutes);

        }
    }
    disallowPersonalData() {

        if (!isPlatformBrowser(this.platformId) ) {
            return;
        } else {
            this.localStorage.setItem('allow-personal', 'no', this.oneYearMinutes);
        }
    }
    getConsentCookie(): string {

        if (!isPlatformBrowser(this.platformId) ) {
            return null;
        } else {
            return this.localStorage.getItem('allow-personal');
        }
    }
    setLastSearch(type: string, value: any) {
        if (!isPlatformBrowser(this.platformId) || ['products', 'personrooms', 'posts'].indexOf(type) < 0) {
            return ;
        } else {
            this.localStorage.setItem(`last-search-${type}`, JSON.stringify(value), this.oneYearMinutes);
        }
    }
    getLastSearch(type: string): any {
        if (!isPlatformBrowser(this.platformId) || ['products', 'personrooms', 'posts'].indexOf(type) < 0) {
            return null;
        } else {
            try {
                const obj = JSON.parse(this.localStorage.getItem(`last-search-${type}`));
                return obj;
            } catch (e) {
                return null;
            }
        }
    }
    setRoomCategoryCount(value: any) {
        if (!isPlatformBrowser(this.platformId) ) {
            return ;
        } else {
            this.localStorage.setItem(`room-category-count`, JSON.stringify(value), this.oneWeekMinutes);
        }
    }
    getRoomCategoryCount(): any {
        if (!isPlatformBrowser(this.platformId) ) {
            return null;
        } else {
            try {
                const obj = JSON.parse(this.localStorage.getItem(`room-category-count`));
                return obj;
            } catch (e) {
                return null;
            }
        }
    }

    setHeaderMenus(value: any) {
        if (!isPlatformBrowser(this.platformId) ) {
            return ;
        } else {
            this.localStorage.setItem(`header-menus`, JSON.stringify(value), this.oneWeekMinutes);
        }
    }
    getHeaderMenus(): any {
        if (!isPlatformBrowser(this.platformId) ) {
            return null;
        } else {
            try {
                const obj = JSON.parse(this.localStorage.getItem(`header-menus`));
                return obj;
            } catch (e) {
                return null;
            }
        }
    }
}
