import { Profile } from './profile';

export class Review {

    _id: string;
    reviewID: string;
    type: string;
    elementID: string;
    status: string;
    username: string;
    uid: string;
    createdDate: Date;
    rating: number;
    comment: string;
    profile: Profile;

}

export interface ReviewList {
    list: Review[];
    jsonFields: string[];
    htmlFields: string[];
}


export interface ReviewListResponse {
    responseStatus: number;
    message: string;
    data: ReviewList;
}


export interface StringResponse {
    responseStatus: number;
    message: string;
    data: string;
}
