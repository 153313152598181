import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
  PLATFORM_ID,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Product } from '../../classes/product';
import { TagService } from '../../services/tag.service';
import { Tag } from '../../classes/tag';
import { isPlatformBrowser } from '@angular/common';
import { first } from 'rxjs/operators';
import { ProfileService } from '../../services/profile.service';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-product-snippet',
  templateUrl: './product-snippet.component.html',
  styleUrls: ['./product-snippet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSnippetComponent
  implements OnInit, AfterViewInit, OnChanges {
  selectedVariant: any;
  @Input() product: Product;
  @Input() brandTag: Tag;
  @Input() storeId: string;
  // @Input() verdict: Verdict;

  @Output() brandClicked: EventEmitter<any> = new EventEmitter();
  @Output() openDetails: EventEmitter<any> = new EventEmitter();

  filterTags: Map<string, Tag> = new Map();
  colourTags: Map<string, Tag> = new Map();
  materialTags: Map<string, Tag> = new Map();
  // shownFilter: Tag;
  shownFilter = 'x';
  isBrowser: boolean;

  constructor(
    private tagService: TagService,
    private profileService: ProfileService,
    private productService: ProductService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selectedVariant = this.product.variants[0];
    if (this.selectedVariant) {
      this.product.rrp =
        (this.product.price + this.selectedVariant.surcharge) *
        this.product.markUp *
        (this.product.vatTax + 1);
      this.product.currentPrice =
        this.product.rrp - this.product.rrp * this.product.promotionalDiscount;
      this.detectChanges();
    }
  }

  ngAfterViewInit() {
    if (this.isBrowser) {
      this.tagService
        .cachedTagsList('filter')
        .pipe(first())
        .subscribe((result) => {
          this.filterTags = result;
        });
      this.tagService
        .cachedTagsList('colour')
        .pipe(first())
        .subscribe((result) => {
          this.colourTags = result;
        });
      this.tagService
        .cachedTagsList('material')
        .pipe(first())
        .subscribe((result) => {
          this.materialTags = result;
        });
    }
    this.detectChanges();
    this.cdr.detach();
  }

  brandWasClicked(brandId: string): void {
    this.brandClicked.emit([brandId]);
  }

  // runAssignShownFilter(filter: string) {
  //     console.log(filter);
  // }

  openProductDetails(product: Product) {
    this.openDetails.emit(this.selectedVariant);
  }

  getMainMultipleTags(type: string, product: Product, max?: number): string[] {
    if (['category', 'colour', 'material'].indexOf(type) < 0) {
      return [];
    }
    if (!product[type] || product[type].length < 1) {
      return [];
    }
    return product[type]
      .filter((catId, i, list) => {
        const p = list.find((x, j) => x.indexOf(catId) >= 0 && i !== j);
        return !p;
      })
      .slice(0, max || 100);
  }

  markShownFilter(tagId: string) {
    this.shownFilter = tagId;
    this.detectChanges();
  }

  selectVariant(variant) {
    this.selectedVariant = variant;
    if (this.selectedVariant) {
      this.product.rrp =
        (this.product.price + this.selectedVariant.surcharge) *
        this.product.markUp *
        (this.product.vatTax + 1);
      this.product.currentPrice =
        this.product.rrp - this.product.rrp * this.product.promotionalDiscount;
      this.detectChanges();
    }
  }

  onClickBookmarkIcon(e: any, product) {
    console.log('eee: ', e);
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (product.isBookmarked) {
      this.removeBookmark(product);
    } else {
      this.addToBookmark(product);
    }
  }

  addToBookmark(product) {
    if (!this.profileService.isLoggedIn()) {
      alert('You must login first');
      this.profileService.gotoLogin();
      return;
    }
    product.isBookmarkAPIRunning = true;
    this.detectChanges();
    this.productService.saveBookmark(this.product.id).subscribe(
      (res) => {
        product.isBookmarked = true;
        product.isBookmarkAPIRunning = false;
        this.detectChanges();
      },
      (err) => {
        product.isBookmarkAPIRunning = false;
        product.isBookmarked = false;
        this.detectChanges();
      }
    );
  }

  removeBookmark(product) {
    if (!this.profileService.isLoggedIn()) {
      alert('You must login first');
      this.profileService.gotoLogin();
      return;
    }
    product.isBookmarkAPIRunning = true;
    this.detectChanges();
    this.productService.removeBookmark([this.product.id]).subscribe(
      (res) => {
        product.isBookmarkAPIRunning = false;
        product.isBookmarked = false;
        this.detectChanges();
      },
      (err) => {
        product.isBookmarkAPIRunning = false;
        product.isBookmarked = true;
        this.detectChanges();
      }
    );
  }

  detectChanges() {
    try {
      this.cdr.detectChanges();
    } catch { }
  }
}
