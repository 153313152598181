import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Location, isPlatformBrowser } from '@angular/common';
import { Product } from '../classes/product';
import { Router, Params } from '@angular/router';
import { Personroom } from '../classes/personroom';
import { Seller } from '../classes/seller';
import { Post } from '../classes/post';
import { Tag } from '../classes/tag';

@Injectable()
export class NavigationService {

    constructor(
        private router: Router,
        private location: Location,
        @Inject(PLATFORM_ID) private platformId: Object,

    ) { }

    normaliseSlug(slug: string) {
        return encodeURIComponent(slug.replace(/[\(\)]/g, '').replace(/[\s\/]/g, '-').toLowerCase());

    }

    navigateProductDetail(product: Product) {
        const url = ['', this.normaliseSlug(product.urlSlug), 'products', 'd', product._id].join('/');
        if (this.router.url !== url) { this.router.navigateByUrl(url); }
    }
    // pankaj
    navigateNewProductDetail(product: Product, selectedVariant: any) {
        const urlSlug = selectedVariant.urlSlug ? selectedVariant.urlSlug : product.urlSlug;
        if (!urlSlug) {
            alert('No urlSlug found.');
            return;
        }
        const url = ['', this.normaliseSlug(urlSlug), 'products', 'd', selectedVariant.id].join('/');
        // const url = ['products', 'd', selectedVariant.id].join('/');
        if (this.router.url !== url) {
            window.open(url, '_blank');
            // this.router.navigateByUrl(url);
        }
    }

    navigateProductDetailId(productId: string) {
        const url = ['', '_', 'products', 'd', productId].join('/');
        if (this.router.url !== url) { this.router.navigateByUrl(url); }
    }
    urlMoodmaestroLogo() {
        return '/assets/logo/mmIcon_w80.webp';
    }
    urlProductDetail(product: Product) {
        return ['', this.normaliseSlug(product.urlSlug), 'products', 'd', product._id].join('/');
    }
    // openNewWindow(url: string) {
    //     const newWin = window.open(url, '_blank');
    //     if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
    //         this.router.navigateByUrl(url);
    //     }

    // }
    openProductDetail(product: Product) {
        const url = this.urlProductDetail(product);
        this.router.navigateByUrl(url);
    }
    openProductDetailId(productId: string) {
        const url = ['', '_', 'products', 'd', productId].join('/');
        this.router.navigateByUrl(url);
    }

    navigateCheckout() {
        const url = '/purchase/checkout';
        if (this.router.url !== url) { this.router.navigateByUrl(url); }
    }


    navigateSellerStore(seller: Seller) {
        const url = ['', this.normaliseSlug(seller.sellerName), 'products', 'seller', seller.sellerID].join('/');
        if (this.router.url !== url) { this.router.navigateByUrl(url); }
    }
    navigateSellerProfile(sellerId: string) {
        const url = ['', 'profile', 'seller', sellerId].join('/');
        if (this.router.url !== url) { this.router.navigateByUrl(url); }

    }
    openSellerProfile(sellerId: string) {
        const url = ['', 'profile', 'seller', sellerId].join('/');
        this.router.navigateByUrl(url);


    }
    navigatePostDetail(post: Post) {
        const url = ['', this.normaliseSlug(post.urlSlug), 'blog', 'd', post._id].join('/');
        if (this.router.url !== url) { this.router.navigateByUrl(url); }
    }
    urlPersonroomDetail(personroom: Personroom) {
        return ['', this.normaliseSlug(personroom.urlSlug), 'moodrooms', 'd', personroom._id].join('/');
    }
    navigatePersonroomDetail(personroom: Personroom) {
        const url = this.urlPersonroomDetail(personroom);
        if (this.router.url !== url) { this.router.navigateByUrl(url); }
    }
    // changeStoreType(path: string, storeId: string): string {
    //     return path.replace(/\/products\/[pt]/, `/products/${storeId}`);
    // }
    // changeListType(path: string, storeId: string): string {
    //     return path.replace(/\/moodrooms\/[pt]/, `/moodrooms/${storeId}`);
    // }
    // changeBlogType(path: string, storeId: string): string {
    //     return path.replace(/\/blog\/[pt]/, `/blog/${storeId}`);
    // }

    getStoreUrl(tag: Tag, type: 'brand' | 'room' | 'cat' | 'style'): string {
        if (!tag) { return ''; }
        return ['', this.normaliseSlug(tag.name), 'products', 'list', type, tag._id].join('/');
    }
    getMoodroomsListUrl(tag: Tag, type: 'room' | 'style', listId?: 't'): string {
        return ['', this.normaliseSlug(tag.name), 'moodrooms', listId || 't', type, tag._id].join('/');
    }

    getRoomCategoryUrl(room: Tag, catId: string): string {
        return ['', this.normaliseSlug(room.name), 'products', 'list', 'room', room._id, 'cat', catId].join('/');
    }

    navigateGeneralStore(params?: Params) {
        const url = this.urlGeneralStore();
        if (this.router.url !== url) {
            // this.router.navigate(['_', 'products', 'list'], {
            //     queryParams: params || {},
            //     queryParamsHandling: 'merge',
            // });
            this.router.navigate(['products', 'list'], {
                queryParams: params || {},
                queryParamsHandling: 'merge',
            });
        }
    }
    urlGeneralStore() {
        // return ['', '_', 'products', 'list'].join('/');
        return ['products', 'list'].join('/');
    }
    urlCategoryStore(categoryId: string) {
        // return categoryId ? ['', '_', 'products', 'list', 'cat', categoryId].join('/') : ['', '_', 'products', 'list'].join('/');
        return categoryId ? ['products', 'list', 'cat', categoryId].join('/') : ['', '_', 'products', 'list'].join('/');
    }
    navigateGeneralMoodroomList(params?: Params) {
        const url = this.urlGeneralMoodroomList();
        if (this.router.url !== url) {
            this.router.navigate(['_', 'moodrooms', 't'], {
                queryParams: params || {},
                queryParamsHandling: 'merge',
            });
        }
    }
    urlGeneralMoodroomList(listId?: 't') {
        return ['', '_', 'moodrooms', listId || 't'].join('/');
    }

    navigateGeneralBlog(params?: Params) {
        const url = this.urlGeneralBlog();
        if (this.router.url !== url) {
            // this.router.navigateByUrl(url, {queryParams: params||{}});
            this.router.navigate(['_', 'blog', 't'], {
                queryParams: params || {},
                queryParamsHandling: 'merge',
            });
        }
    }
    urlGeneralBlog() {
        return ['', '_', 'blog', 't'].join('/');
    }
    navigateToCart() {
        const url = '/purchase/cart';
        if (this.router.url !== url) { this.router.navigateByUrl(url); }
    }
    navigateToNewCart() {
        const url = '/purchase/cart-new';
        if (this.router.url !== url) { this.router.navigateByUrl(url); }
    }
    navigateToProfileEdit() {
        const url = '/profile/edit';
        if (this.router.url !== url) { this.router.navigateByUrl(url); }
    }
    navigateToOrdersList() {
        const url = '/purchase/orders-list';
        if (this.router.url !== url) { this.router.navigateByUrl(url); }
    }
    navigateToMyOrdersList() {
        const url = '/purchase/orders-list-new';
        if (this.router.url !== url) { this.router.navigateByUrl(url); }
    }
    navigateToPaymentMethods() {
        const url = '/purchase/payment-methods';
        if (this.router.url !== url) { this.router.navigateByUrl(url); }
    }
    navigateToFavourites() {
        const url = '/profile/bookmarks';
        if (this.router.url !== url) { this.router.navigateByUrl(url); }
    }
    goFavourites(type: string) {
        this.location.go(`/profile/bookmarks/${type}`);
    }
    navigateToChangePassword() {
        const url = '/profile/changepassword';
        if (this.router.url !== url) { this.router.navigateByUrl(url); }
    }
    navigateToProductRequest() {
        const url = '/profile/productrequest';
        if (this.router.url !== url) { this.router.navigateByUrl(url); }
    }
    navigateStartGame() {
        const url = this.urlStartGame();
        if (this.router.url !== url) { this.router.navigateByUrl(url); }
    }
    urlStartGame() {
        return '/game';
    }
    navigateOrderConfirmation(orderId: string) {
        const url = `/purchase/order-confirmation/${orderId}`;
        if (this.router.url !== url) { this.router.navigateByUrl(url); }
    }
    navigateGameRound(gameId: string, round: number): string {
        const url = `/game/play/${gameId}/round/${round}`;
        this.router.navigateByUrl(url);
        // location.assign(url);
        return url;
    }
    navigateGameResult(gameId: string): string {
        const url = `/game/play/${gameId}/result`;
        this.router.navigateByUrl(url);
        return url;

    }
    // navigatePersonalisedStore(verdictId: string): string {
    //     let url = `/products/p?`;
    //     if (verdictId) { url += `verdict=${verdictId}`; }
    //     this.router.navigateByUrl(url);
    //     return url;
    // }
    // navigatePersonalisedMoodrooms(verdictId: string): string {
    //     let url = `/_/moodrooms/p?`;
    //     if (verdictId) { url += `verdict=${verdictId}`; }
    //     this.router.navigateByUrl(url);
    //     return url;
    // }
    // navigatePersonalisedBlog(verdictId: string): string {
    //     let url = `/_/blog/p?`;
    //     if (verdictId) { url += `verdict=${verdictId}`; }
    //     this.router.navigateByUrl(url);
    //     return url;
    // }
    tagPageWindow(tagId: string, sameWindow?: boolean) {

        const url = `/info/tag/${tagId}`;
        if (isPlatformBrowser(this.platformId) && !sameWindow) {
            window.open(url);
        } else {
            // location.assign(url);
            this.router.navigateByUrl(url);
        }
    }
    navigateInfoPage(contentId: string) {

        const url = `/info/${contentId}`;
        if (this.router.url !== url) { this.router.navigateByUrl(url); }

        // if (isPlatformBrowser(this.platformId)) {
        //     window.open(url);
        // } else {
        //     location.assign(url);
        // }
    }

}
