import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { NewHttpService } from './new.http.service.service';

@Injectable()
export class SearchService {

    constructor(
        private http: HttpService,
        private htttp: NewHttpService,
        //   private auth: AuthService
    ) { }

    autocomplete(index: string, term: string, val: any, page: Number, limit: number, skip: number, sort: string): Observable<any> {
        if (!term || term === '') {
            return of({ data: [] });
        }
        // const url = '/v1/api/search/postautocomplete?index=' + index;

        // const obj: any = {
        //     search: {prefix: term}
        // };
        // if (limit && limit > 0) { obj.limit = limit; }
        // if (skip && skip > 0) { obj.skip = skip; }
        // if (sort && sort !== '') { obj.sort = sort; }

        // return this.http.postHttpJson(url, obj);
        const url = `web/products/suggestions?page=${page}`;
        // return this.http.postHttpNewJson(url, { query: term });
        return this.http.postHttpNewJson(url, { query: term, ...val });


    }

    keywordSearch(index: string, term: string, jsonSearch: any, limit: number, skip: number, sort: string): Observable<any> {
        const url = '/v1/api/search/postsearch?index=' + index;
        const obj: any = {
            search: { prefix: term, query: jsonSearch }
        };
        if (limit && limit > 0) { obj.limit = limit; }
        if (skip && skip > 0) { obj.skip = skip; }
        if (sort && sort !== '') { obj.sort = sort; }

        return this.http.postHttpJson(url, obj);
    }


}
