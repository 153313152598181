import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  AfterViewInit,
  SimpleChanges,
  OnDestroy
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ProductService } from '../../services/product.service';
declare let $: any;

const FILTER_TYPE = {
  brand: {},
  category: {},
  room: {},
  colour: {},
  material: {},
  filter: {},
  style: {}
};

@Component({
  selector: 'app-tags-filter',
  templateUrl: './tags-filter.component.html',
  styleUrls: ['./tags-filter.component.scss'],
})
export class TagsFilterComponent implements OnInit, OnDestroy {

  // @Input() tags: any;
  @Input() selectedTag: any;
  @Output() isView = new EventEmitter<boolean>();
  object = Object;
  myForm: FormGroup;
  selectedTags = FILTER_TYPE;
  showFilter = true;
  selectedChildren = false;
  tags: any = [];
  subscription: any = null;
  isFetchingTags = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productService: ProductService) {
  }

  ngOnInit() {
    this.selectedTags = this.productService.getSelectedFilters();
    this.tags = this.productService.getTags();
    this.subscription = this.productService.updatedTags.subscribe((response: any) => {
      this.tags = response.tags;

      this.isFetchingTags = false;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  test() {
    setTimeout(() => {
      const offset = $('#tagsList ul li').first().position().top;
      $('#tagsList').scrollTop(
        $(`#${this.selectedTag}`).parent().position().top - offset
      );
    }, 2150);
  }

  // makeForm() {
  //   this.myForm = this.fb.group({
  //     option1: this.fb.array([])
  //   });
  //   Object.keys(this.tags).forEach((tag) => {
  //     this.tags[tag].forEach((p) => {
  //       let aa = this.fb.array([]);
  //       if (p.children && p.children.length) {
  //         p.children.forEach((c) => {
  //           aa.push(this.fb.group({
  //             isSelected: [false],
  //             ...c
  //           }))
  //         });
  //       }
  //       let parentGroup = this.fb.group({
  //         isSelected: [false],
  //         children: aa,
  //         ...p
  //       });

  //       (<FormArray>this.myForm.controls.option1).push(parentGroup);
  //     })
  //   });
  //   console.log("this.form: ", this.myForm);
  // }

  getControlsForOption1() {
    return (<FormArray>this.myForm.controls.option1).controls;
  }

  stopPropagation(e: any) {
    if (e) {
      e.stopPropagation();
    }
  }

  closeFilter() {
    this.isView.emit(false);
  }

  clearAll() {
    this.showFilter = false;
    this.selectedTags = null;
    this.selectedTags = {
      brand: {},
      category: {},
      room: {},
      colour: {},
      material: {},
      filter: {},
      style: {}
    };
    this.productService.setSelectedFilters(this.selectedTags);
    this.productService.setTagNameListForSelectedFilters([]);
    this.productService.setFilters(this.selectedTags);
  }

  onChangeSelection(item: any) {
    this.isFetchingTags = true;
    setTimeout(() => {
      this.onView(item);
    }, 300);
  }

  onView(tagInfo: any) {
    console.log('this.selectedTags: ', this.selectedTags, tagInfo);
    const val = {
      filter: {
        brands: Object.keys(this.selectedTags.brand) || null,
        categories: Object.keys(this.selectedTags.category) || null,
        rooms: Object.keys(this.selectedTags.room),
        colours: Object.keys(this.selectedTags.colour),
        materials: Object.keys(this.selectedTags.material),
        filters: Object.keys(this.selectedTags.filter),
        styles: Object.keys(this.selectedTags.style)
      }
    };

    this.showFilter = false;
    console.log('ON SUBMIT: ', val);

    // It means, this perticular tag has parent tag
    if (tagInfo.parent) {
      this.selectedTags.category[tagInfo.parent] = false;
    }

    this.productService.setSelectedFilters(this.selectedTags);
    this.productService.setFilters(this.selectedTags);

    // To show chips of product name which is selected
    let selectedFiltersNames: any = this.productService.getTagNameListForSelectedFilters();

    if (!selectedFiltersNames.length && tagInfo.parent) {
      selectedFiltersNames.push({
        tagId: tagInfo.tagId,
        name: tagInfo.name,
        type: tagInfo.type,
        parent: tagInfo.parent || null
      });
    } else if (selectedFiltersNames.findIndex(tag => tag.tagId == tagInfo.tagId) == -1) {

      if (tagInfo.type == 'category' && tagInfo.parent) { // Remove parent category if tag has parent category
        selectedFiltersNames = selectedFiltersNames.filter((val) => {
          return val.parent != null;
        });
      }
      selectedFiltersNames.push({
        tagId: tagInfo.tagId,
        name: tagInfo.name,
        type: tagInfo.type,
        parent: tagInfo.parent || null
      });
    } else if (selectedFiltersNames.length) {
      selectedFiltersNames.splice(selectedFiltersNames.findIndex(tag => tag.tagId == tagInfo.tagId), 1);
    }
    this.productService.setTagNameListForSelectedFilters(selectedFiltersNames);
  }
}
