import { HeaderMenu, HeaderColumn, HeaderLink, HeaderGroup } from '../classes/header';


const _storeMenu = <HeaderMenu>[
    <HeaderColumn>[
        <HeaderGroup>{
            title: <HeaderLink>{id: 'cat-furniture', type: 'cat'},
            items: [
                <HeaderLink>{id: 'cat-furniture-barstools', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-chairs', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-chaiselonges', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-ottomans', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-recamieres', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-rockingarmchairs', type: 'cat'},
                // <HeaderLink>{id: 'cat-furniture-shelving', type: 'cat'},
                // <HeaderLink>{id: 'cat-furniture-tvstands', type: 'cat'},

                <HeaderLink>{id: 'cat-furniture-nestingtables', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-sofabeds', type: 'cat'},
            ],
        },
        <HeaderGroup>{
            title: <HeaderLink>{id: 'cat-finishes', type: 'cat'},
            items: [
                <HeaderLink>{id: 'cat-finishes-floorrugs', type: 'cat'},
                <HeaderLink>{id: 'cat-finishes-floortiles', type: 'cat'},
                <HeaderLink>{id: 'cat-finishes-floorveneers', type: 'cat'},
                <HeaderLink>{id: 'cat-finishes-wallpaint', type: 'cat'},
                <HeaderLink>{id: 'cat-finishes-walltiles', type: 'cat'},
                <HeaderLink>{id: 'cat-finishes-carpets', type: 'cat'},
            ],
        }
    ],
    <HeaderColumn>[
        <HeaderGroup>{
            title: <HeaderLink>{id: 'cat-lighting', type: 'cat'},
            items: [
                <HeaderLink>{id: 'cat-lighting-ceilingmountedlights', type: 'cat'},
                <HeaderLink>{id: 'cat-lighting-chandeliers', type: 'cat'},
                <HeaderLink>{id: 'cat-lighting-floorlights', type: 'cat'},
                <HeaderLink>{id: 'cat-lighting-freestandinglights', type: 'cat'},
                <HeaderLink>{id: 'cat-lighting-pendantlights', type: 'cat'},
                <HeaderLink>{id: 'cat-lighting-tablelights', type: 'cat'},
                <HeaderLink>{id: 'cat-lighting-wallmountedlights', type: 'cat'},

            ],
        },
        <HeaderGroup>{
            title: <HeaderLink>{id: 'cat-storage', type: 'cat'},
            items: [
                <HeaderLink>{id: 'cat-storage-cabinetorganization', type: 'cat'},
                <HeaderLink>{id: 'cat-storage-cabinets', type: 'cat'},
                <HeaderLink>{id: 'cat-storage-chestofdrawers', type: 'cat'},
                <HeaderLink>{id: 'cat-storage-mobilekitchenunits', type: 'cat'},
                <HeaderLink>{id: 'cat-storage-rack', type: 'cat'},

                <HeaderLink>{id: 'cat-storage-shelving', type: 'cat'},
                <HeaderLink>{id: 'cat-storage-displaycabinets', type: 'cat'},
                <HeaderLink>{id: 'cat-storage-wallstoragesystem', type: 'cat'},
                <HeaderLink>{id: 'cat-storage-trolleys', type: 'cat'},
                <HeaderLink>{id: 'cat-storage-magazinestands', type: 'cat'},
                <HeaderLink>{id: 'cat-storage-tvstands', type: 'cat'},

            ],
        }
    ],
    <HeaderColumn>[
        <HeaderGroup>{
            title: <HeaderLink>{id: 'room-living', type: 'room'},
            items: [
                <HeaderLink>{id: 'cat-furniture-armchair', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-coffeetables', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-consoletables', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-recliners', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-recliningsofas', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-sideboards', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-sidetables', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-sofas', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-wingchairs', type: 'cat'},
            ],
        },
        <HeaderGroup>{
            title: <HeaderLink>{id: 'room-bedroom', type: 'room'},
            items: [
                <HeaderLink>{id: 'cat-furniture-beds', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-bedsidechests', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-benches', type: 'cat'},
                <HeaderLink>{id: 'cat-storage-chestofdrawers', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-desks', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-dressingtables', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-nightstands', type: 'cat'},
                <HeaderLink>{id: 'cat-storage-wardrobes', type: 'cat'},
            ],
        },
        <HeaderGroup>{
            title: <HeaderLink>{id: 'room-bathroom', type: 'room'},
            items: [
                <HeaderLink>{id: 'cat-accessories-baths', type: 'cat'},
                <HeaderLink>{id: 'cat-accessories-sinks', type: 'cat'},
                <HeaderLink>{id: 'cat-accessories-taps', type: 'cat'},

            ],
        },
    ],
    <HeaderColumn>[
        <HeaderGroup>{
            title: <HeaderLink>{id: 'room-kitchen', type: 'room'},
            items: [
                <HeaderLink>{id: 'cat-furniture-barchairs', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-barstools', type: 'cat'},
                <HeaderLink>{id: 'cat-storage-cabinetorganization', type: 'cat'},
                <HeaderLink>{id: 'cat-storage-cabinets', type: 'cat'},

            ],
        },
        <HeaderGroup>{
            title: <HeaderLink>{id: 'room-dining', type: 'room'},
            items: [
                <HeaderLink>{id: 'cat-furniture-diningchairs', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-diningtables', type: 'cat'},

            ],
        },
        <HeaderGroup>{
            title: <HeaderLink>{id: 'room-garden', type: 'room'},
            items: [
                <HeaderLink>{id: 'cat-furniture-gardenarmchairs', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-gardenlounges', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-gardensofas', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-poufs', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-stools', type: 'cat'},
                <HeaderLink>{id: 'cat-furniture-sunloungers', type: 'cat'},

            ],
        },
        <HeaderGroup>{
            title: <HeaderLink>{displayName: 'More'},
            items: [
                <HeaderLink>{id: 'cat-accessories', type: 'cat'},
                <HeaderLink>{id: 'cat-fabrics', type: 'cat'},
                // <HeaderLink>{id:'cat-fixtures',type:'cat'},
                <HeaderLink>{id: 'room-study', type: 'room'},
                <HeaderLink>{id: 'room-children', type: 'room'},

            ],
        },


    ],

    <HeaderColumn>[
        <HeaderGroup>{
            title: <HeaderLink>{displayName: 'Styles', url: '/store/styles'},
            items: [
                <HeaderLink>{id: 'st-artdeco', type: 'style'},
                <HeaderLink>{id: 'st-industrial', type: 'style'},
                <HeaderLink>{id: 'st-classic', type: 'style'},
                <HeaderLink>{id: 'st-designer', type: 'style'},
                <HeaderLink>{id: 'st-mainstream', type: 'style'},
                <HeaderLink>{id: 'st-midcentury', type: 'style'},
                <HeaderLink>{id: 'st-ethnic', type: 'style'},
                <HeaderLink>{id: 'st-baroque', type: 'style'},
                <HeaderLink>{id: 'st-playful', type: 'style'},
                <HeaderLink>{id: 'st-rustic', type: 'style'},
                <HeaderLink>{id: 'st-shabby', type: 'style'},
                <HeaderLink>{id: 'st-zen', type: 'style'},
            ],
        },
        <HeaderGroup>{
            title: <HeaderLink>{displayName: 'Brands', url: '/store/brands'},
            items: [
                <HeaderLink>{displayName: 'View all brands', url: '/store/brands'},
            ],
        },
        <HeaderGroup>{
            title: <HeaderLink>{displayName: 'Categories', url: '/store/categories'},
            items: [
                <HeaderLink>{displayName: 'View all categories', url: '/store/categories'},
            ],
        },
        <HeaderGroup>{
            title: <HeaderLink>{displayName: 'Rooms', url: '/store/rooms'},
            items: [
                <HeaderLink>{displayName: 'View all rooms', url: '/store/rooms'},
            ],
        },

    ],
];

const _moodroomMenu = <HeaderMenu>[
    <HeaderColumn>[
        <HeaderGroup>{
            title: <HeaderLink>{displayName: 'Rooms'},
            items: [
                <HeaderLink>{id: 'room-living', type: 'room'},
                <HeaderLink>{id: 'room-kitchen', type: 'room'},
                <HeaderLink>{id: 'room-dining', type: 'room'},
                <HeaderLink>{id: 'room-bedroom', type: 'room'},
                <HeaderLink>{id: 'room-family', type: 'room'},
                <HeaderLink>{id: 'room-bathroom', type: 'room'},
                <HeaderLink>{id: 'room-garden', type: 'room'},
                <HeaderLink>{id: 'room-children', type: 'room'},
            ],
        }
    ],
    <HeaderColumn>[
        <HeaderGroup>{
            title: <HeaderLink>{displayName: 'Styles'},
            items: [
                <HeaderLink>{id: 'st-artdeco', type: 'style'},
                <HeaderLink>{id: 'st-industrial', type: 'style'},
                <HeaderLink>{id: 'st-classic', type: 'style'},
                <HeaderLink>{id: 'st-designer', type: 'style'},
                <HeaderLink>{id: 'st-mainstream', type: 'style'},
                <HeaderLink>{id: 'st-midcentury', type: 'style'},
                <HeaderLink>{id: 'st-ethnic', type: 'style'},
                <HeaderLink>{id: 'st-baroque', type: 'style'},
                <HeaderLink>{id: 'st-playful', type: 'style'},
                <HeaderLink>{id: 'st-rustic', type: 'style'},
                <HeaderLink>{id: 'st-shabby', type: 'style'},
                <HeaderLink>{id: 'st-zen', type: 'style'},
            ],
        }
    ],

];

export class HeaderMenus {
    public static store = _storeMenu;
    public static moodroom = _moodroomMenu;

}

