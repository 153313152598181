// import 'rxjs/add/observable/of';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { PostListResponse, Post, PostResponse, PostEvent } from '../classes/post';
import { ProfileListResponse } from '../classes/profile';
import { LocalStorage } from './utils.service';
import { AuthService } from './auth.service';
// import { Verdict } from '../classes/game';
// import { ErrorObservable } from 'rxjs/observable/ErrorObservable';

@Injectable()
export class PostService {

  constructor(
    private http: HttpService,
    // private auth: AuthService,
    private localStorage: LocalStorage,

  ) { }

    getPostsList(searchJson, limit, skip, sort): Observable<PostListResponse> {
        let url = '/v1/api/posts/postpostslist?';
        if (limit) { url += `limit=${limit}&`; }
        if (skip) { url += `skip=${skip}&`; }
        if (sort) { url += `sort=${sort}&`; }
        return this.http.postHttpJson(url, searchJson);
    }
    getPostDetails(id: string): Observable<PostResponse> {
        return this.http.getHttpJson('/v1/api/posts/getpostdetails?postID=' + id);
    }
    getAllAuthors(): Observable<ProfileListResponse> {
        return this.http.getHttpJson('/v1/api/posts/getauthorslist');
    }
    elasticPostsList(
        searchJson: any, prefix: string, limit: number, skip: number, sort: string, seed: string
    ): Observable<PostListResponse> {
        let url = '/v1/api/posts/postelasticsearchposts?';
        if (limit) { url += `limit=${limit}&`; }
        if (skip) { url += `skip=${skip}&`; }
        if (sort) { url += `sort=${sort.replace(' ', '+')}&`; }
        if (prefix) { url += `prefix=${prefix.replace(' ', '+')}&`; }
        if (seed) { url += `seed=${seed}&`; }
        return this.http.postHttpJson(url, searchJson);
    }
    // postpersonalisedposts
    // elasticPersonalisedBlog( // new
    //     verdict: Verdict, searchJson: any, prefix: string, limit: number, skip: number, sort: string, seed: string
    // ): Observable<PostListResponse> {



    //     let url = '/v1/api/posts/postpersonalisedposts?';
    //     if (limit) { url += `limit=${limit}&`; }
    //     if (skip) { url += `skip=${skip}&`; }
    //     if (sort) { url += `sort=${sort.replace(' ', '+')}&`; }
    //     if (prefix) { url += `prefix=${prefix.replace(' ', '+')}&`; }
    //     // if (verdictId) { url += `verdictID=${verdictId}&`; }
    //     if (seed) { url += `seed=${seed}&`; }

    //     return this.http.postHttpJson(url, {verdict: verdict, search: searchJson});
    // }
    // elasticPersonalisedPostsList(
    //     verdictId: string, searchJson: any, prefix: string, limit: number, skip: number, sort: string, seed: string
    // ): Observable<PostListResponse> {

    //     if (!this.auth.isLoggedIn()) {
    //         return throwError({error: {message: 'You must be logged in'}});
    //     }

    //     let url = '/v1/api/posts/postpersonalisedsearchposts?';
    //     if (limit) { url += `limit=${limit}&`; }
    //     if (skip) { url += `skip=${skip}&`; }
    //     if (sort) { url += `sort=${sort.replace(' ', '+')}&`; }
    //     if (prefix) { url += `prefix=${prefix.replace(' ', '+')}&`; }
    //     if (verdictId) { url += `verdictID=${verdictId}&`; }
    //     if (seed) { url += `seed=${seed}&`; }

    //     return this.http.postHttpJson(url, searchJson);
    // }

    clearSearchParameters() {
        this.localStorage.setItem('posts/search', null);
    }
    savePostSearch(obj: any) {
        this.localStorage.setItem('posts/search', JSON.stringify(obj));
    }
    updatePostSearch(propertyKey: string, prop: any, clearOthers: boolean) {
        const searchStr = this.localStorage.getItem('posts/search');
        if (searchStr) {
            try {
                let obj = JSON.parse(searchStr);
                if (clearOthers) { obj = {}; }
                obj[propertyKey] = prop;
                this.localStorage.setItem('posts/search', JSON.stringify(obj));
            } catch (e) {
            }
        }
    }

    restorePostSearch(): any {
        const searchStr = this.localStorage.getItem('posts/search');
        if (searchStr) {
            try {
                const obj = JSON.parse(searchStr);
                return obj;

            } catch (e) {
                return null;
            }
        } else { return null; }
    }

    getRecommmedations(list: PostEvent[], limit: number, skip: number, sort: string): Observable<PostListResponse> {
        let url = '/v1/api/posts/postrecommendations?';
        if (limit) { url += `limit=${limit}&`; }
        if (skip) { url += `skip=${skip}&`; }
        if (sort) { url += `sort=${sort.replace(' ', '+')}&`; }
        return this.http.postHttpJson(url, list);
    }



}
