import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit, Input, SimpleChange } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit, AfterViewInit {

    @Input() footerConfig: any = {
        logo: null,
        links: [],
    };
    constructor(private cdr: ChangeDetectorRef) { }

    ngOnInit() {
    }

    ngOnChanges(change) {
        if ('footerConfig' in change) {
            const footerConfig: SimpleChange = change['footerConfig'];
            this.footerConfig = footerConfig.currentValue;
            this.cdr.detectChanges();
        }
        this.cdr.detectChanges();
    }

    ngAfterViewInit() {
        this.cdr.detach();
    }
    getSafeJsonLd(key: string) {
        const jsonld = {
            'organization': {
                '@context': 'http://schema.org',
                '@type': 'Organization',
                'name': 'Moodmaestro - interior design to your taste',
                'legalName': 'Moodmaestro Limited',
                'url': environment.baseHref,
                'logo': environment.baseHref + '/assets/logo/mmIcon_w80.webp',
                'address': {
                    '@type': 'PostalAddress',
                    'streetAddress': 'Battle House 1 East Barnet Road',
                    'addressLocality': 'New Barnet',
                    'addressRegion': 'Barnet Hertfordshire',
                    'postalCode': 'EN4 8RR',
                    'addressCountry': 'GB'
                },
                'sameAs': [
                    'https://www.facebook.com/moodmaestro/',
                    'https://twitter.com/mood_maestro',
                    'https://www.instagram.com/moodmaestro/',
                ]
            }, 'footer': {
                '@context': 'http://schema.org',
                '@graph': [{
                    '@type': 'SiteNavigationElement',
                    'position': 1,
                    '@id': '#footer-menu',
                    'name': 'Terms of Use',
                    'url': environment.baseHref + '/info/terms-of-use',

                }, {
                    '@type': 'SiteNavigationElement',
                    'position': 2,
                    '@id': '#footer-menu',
                    'name': 'Privacy Policy',
                    'url': environment.baseHref + '/info/privacy-policy',
                }, {
                    '@type': 'SiteNavigationElement',
                    'position': 3,
                    '@id': '#footer-menu',
                    'name': 'About Us',
                    'url': environment.baseHref + '/info/about-us',
                }, {
                    '@type': 'SiteNavigationElement',
                    'position': 4,
                    '@id': '#footer-menu',
                    'name': 'Frequently Asked Questions',
                    'url': environment.baseHref + '/info/faq',
                }]

            }
        };
        return jsonld[key];
    }
}
