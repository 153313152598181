import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
export const Animations = {
    'VerdictTrigger': [
        trigger('widthCalibratorPrimary', [
            state('in', style({opacity: '1', width: '*'})),
            transition('void => *',  animate('4s 0.5s ease-in-out', keyframes([
                style({opacity: 0, width: '50%', offset: 0}),
                style({opacity: 1, width: '75%', offset: 0.55}),
                style({opacity: 1, width: '*',   offset: 1.0}),
            ]))),
        ]),
        trigger('widthCalibratorSecondary', [
            state('in', style({opacity: '1'})),
            transition('void => *',  animate('4s 0.5s ease-in-out', keyframes([
                style({opacity: 0, offset: 0}),
                style({opacity: 1, offset: 0.55}),
                style({opacity: 1, offset: 1.0}),
            ]))),
        ]),
        trigger('animationBeginTextSizer', [
            state('in', style({})),
            transition('void => *',  animate('4s 0s ease-in', keyframes([
                style({'font-size': '20px', color: 'lightgray', opacity: 1, offset: 0}),
                style({'font-size': '40px', color: 'black', opacity: 1, offset: 0.75}),
                style({'font-size': '40px', color: 'black', opacity: 0, offset: 1}),
            ]))),
        ]),
        trigger('filterFloatSizer', [
            state('in', style({})),
            transition('void => *',  animate('2s 2s ease-in', keyframes([
                style({'font-size': '12px', color: 'lightgray', opacity: 1, offset: 0}),
                style({'font-size': '18px', color: 'black', opacity: 1, offset: 0.7}),
                style({'font-size': '18px', color: 'black', opacity: 0, offset: 1}),
            ]))),
        ]),

    ]
};

/*Sequence 1: Your personalised store*/
// @keyframes animationBeginTextSizer{
//     0% {font-size:20px;color: lightgray;opacity:1;}
//     75% {font-size:40px;color: black;opacity:1;}
//     100% {font-size:40px;color: black;opacity:0;}
// }
// @-webkit-keyframes animationBeginTextSizer{
//     0% {font-size:20px;color: lightgray;opacity:1;}
//     75% {font-size:40px;color: black;opacity:1;}
//     100% {font-size:40px;color: black;opacity:0;}
// }

/*Sequence 2: filter tags*/
// @keyframes filterFloatSizer{
//     0% {font-size:12px;color: lightgray;;opacity:1;}
//     70% {font-size:18px;color: black;;opacity:1;}
//     100% {font-size:18px;color: black;;opacity:0;}
// }
// @-webkit-keyframes filterFloatSizer{
//     0% {font-size:12px;color: lightgray;;opacity:1;}
//     70% {font-size:18px;color: black;;opacity:1;}
//     100% {font-size:18px;color: black;;opacity:0;}
// }
