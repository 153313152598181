import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { NavigationService } from '../../../services/navigation.service';
import { ProductService } from '../../../services/product.service';

const CURRENT_PAGE = 1;

@Component({
  selector: 'app-similar-products',
  templateUrl: './similar-products.component.html',
  styleUrls: ['./similar-products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimilarProductsComponent implements OnInit {

  @Input() product: any;
  @Input() productId: any;

  showLoader = false;
  similarProducts = [];
  filteredTagsArr = [];

  slideConfig = {
    slidesToShow: 5,
    slidesToScroll: 1,
    dots: false,
    centerMode: false,
    centerPadding: '0',
    autoplay: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 1365,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          centerPadding: '0',
        },
      },

      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerPadding: '0',
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: '0',
          centerMode: false,

        },
      },
    ],
  };

  constructor(
    private productService: ProductService,
    private navigationService: NavigationService,
    private cdr: ChangeDetectorRef) {

  }

  ngOnInit() {
    if (this.product && this.product.tags && this.product.tags.length) {
      let filteredTagsArr = [];
      this.product.tags.forEach((tag) => {
        if (tag && (tag.type == 'room' || tag.type == 'category')) {
          filteredTagsArr.push(tag);
        }
      });

      if (this.product.variants && this.product.variants.length) {
        filteredTagsArr = filteredTagsArr.concat(this.product.variants[0].style);
      }
      this.filteredTagsArr = filteredTagsArr;
      this.getSimilarProducts();
    }
  }

  getSimilarProducts() {
    this.showLoader = true;
    this.detectChanges();
    this.productService.getSimilarProducts(this.productId).subscribe((products: any) => {
      this.similarProducts = products;
      for (const product of this.similarProducts) {
        const findValue = this.findPropertyValue(product.variants, 'inventoryStatus');
        product['priceShow'] = true; 
        if(findValue === 'order_by_email'){
          product['priceShow'] = false;
        }
      }
      this.showLoader = false;
      this.detectChanges();
    }, (err) => {
      this.showLoader = false;
      this.detectChanges();
    });
  }

  getSelectedFilters() {
    const category = [];
    const style = [];
    const room = [];

    if (this.filteredTagsArr.length) {
      this.filteredTagsArr.forEach((tag: any) => {
        if (tag.type == 'category') {
          category.push(tag.tagId);
        } else if (tag.type == 'room') {
          room.push(tag.tagId);
        } else if (tag.type == 'style') {
          style.push(tag.tagId);
        }
      });
    }

    return {
      filter: {
        categories: category,
        rooms: room,
        styles: style
      }
    };
  }

  manipulateFiltersData(val: any) {
    const a = [];
    Object.keys(val).forEach((key: any) => {
      if (val[key]) {
        a.push(key);
      }
    });
    return a;
  }

  goToProductDetails(details) {
    if (details.productId) {
      const selectedVariant = details.variants && details.variants[0];
      this.navigationService.navigateNewProductDetail(details, selectedVariant);
    }
  }

  detectChanges() {
    try {
      this.cdr.detectChanges();
    } catch { }
  }

  findPropertyValue(obj, key) {
    if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        const result = this.findPropertyValue(obj[i], key);
        if (result !== undefined) {
          return result;
        }
      }
    } else if (typeof obj === 'object') {
      for (const prop in obj) {
        if (prop === key) {
          return obj[prop];
        } else if (typeof obj[prop] === 'object') {
          const result = this.findPropertyValue(obj[prop], key);
          if (result !== undefined) {
            return result;
          }
        }
      }
    }
  }
}
