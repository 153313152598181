import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpService } from './http.service';
import { GameRoundResponse, RoundResult, GameResponse,
    GameDetailResponse, VerdictResponse, VerdictListResponse, GameVerdict, ImageListResponse} from '../classes/game';
import { StringResponse } from '../classes/profile';
import { LocalStorage } from './utils.service';
import { AuthService } from './auth.service';
import { throwError } from 'rxjs';
// import { switchMap } from 'rxjs/operator/switchMap';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class GameService {

    public updateStyle = new BehaviorSubject(false);

    constructor(
        private http: HttpService,
        private auth: AuthService,
        private localStorage: LocalStorage,
    ) { }

    startGame(): Observable<GameResponse> {
        return this.http.getHttpJson('/v1/api/pictionaires/getstartpictionaire', true);
    }

    getRound(gameId: String, round: number, prevRound: RoundResult): Observable<GameRoundResponse> {
        return this.http.postHttpJson(`/v1/api/pictionaires/postpictionaireround?pictionaireID=${gameId}&roundnumber=${round}`, prevRound);
    }

    getGameDetails(gameId: String): Observable<GameDetailResponse> {
        return this.http.getHttpJson(`/v1/api/pictionaires/getpictionairedetails?pictionaireID=${gameId}`);
    }


    overrideVerdict(gameId: String, verdict: GameVerdict): Observable<GameDetailResponse> {
        this.clearCachedVerdicts();
        if (!this.auth.isLoggedIn()) {
            return throwError({error: {message: 'You must be logged in'}});
        }
        return this.http.postHttpJson(`/v1/api/pictionaires/postoverridepictionairestyle?pictionaireID=${gameId}`, verdict);
    }

    saveVerdict(gameId: string, verdictName: string): Observable<VerdictResponse> {
        this.clearCachedVerdicts();
        if (!this.auth.isLoggedIn()) {
            return throwError({error: {message: 'You must be logged in'}});
        }
        return this.http.postHttpJson(`/v1/api/verdicts/postsaveverdict?pictionaireID=${gameId}`, {
            verdictName: verdictName
        });
    }
    getVerdicts(): Observable<VerdictListResponse> {
        const output = this.getCachedVerdicts();
// console.log('get versicts', output)
        if (output) {
            return of(output);
        }

        if (!this.auth.isLoggedIn()) {
            return throwError({error: {message: 'You must be logged in'}});
        }

        const url = `/v1/api/verdicts/postverdictslist?sort=${'modifiedDate desc'.replace(' ', '+')}`;
        return this.http.postHttpJson(url, {})
        .pipe(switchMap((result: VerdictListResponse) => {
            this.saveCachedVerdicts(result);
            return of(result);
        }));
    }
    saveCachedVerdicts(obj: VerdictListResponse) {
        this.localStorage.setItem('verdicts/list', JSON.stringify(obj));
    }

    // NOTE: this function can be deleted eventually
    getImagesList(searchObj: any, limit: number, sort: string): Observable<ImageListResponse> {
        const url = `/v1/api/images/postimageslist?limit=${limit}&sort=${sort.replace(' ', '+')}`;
        return this.http.postHttpJson(url, searchObj);
    }

    getCachedVerdicts(): VerdictListResponse {
        const str = this.localStorage.getItem('verdicts/list');
// console.log('get cached versicts', str)
        if (str && str !== '') {
            try {
                return JSON.parse(str);
            } catch (e) {
                return null;
            }
        } else { return null; }
    }
    clearCachedVerdicts() {
        this.localStorage.setItem('verdicts/list', '');

    }
    markVerdict(verdictId: string): Observable<StringResponse> {
        this.clearCachedVerdicts();
        if (!this.auth.isLoggedIn()) {
            return throwError({error: {message: 'You must be logged in'}});
        }
        return this.http.getHttpJson(`/v1/api/verdicts/getmarkverdict?verdictID=${verdictId}`);
    }
    deleteVerdict(verdictId: string): Observable<StringResponse> {
        this.clearCachedVerdicts();
        if (!this.auth.isLoggedIn()) {
            return throwError({error: {message: 'You must be logged in'}});
        }
        return this.http.getHttpJson(`/v1/api/verdicts/getdeleteverdict?verdictID=${verdictId}`);
    }

    getVerdictList() {
        return this.http.postHttpJson(`/v1/api/verdicts/postverdictslist?sort=modifiedDate+desc`, {});
    }

    getStyleSelectorInfo() {
        return this.http.getCustomerJsonBaseUrl(`/api/customer/style-selector`);
    }

}
