import { Component, OnInit, Input, ViewChild, AfterViewInit, ElementRef, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
declare let $: any;
@Component({
  selector: 'app-zoom-image',
  templateUrl: './zoom-image.component.html',
  styleUrls: ['./zoom-image.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZoomImageComponent implements OnInit, AfterViewInit, OnChanges {
  cy: number;
  cx: number;
  myresult: any;
  image: any;
  lens: any;



  @Input() img: string;
  @Input() title: string;
  @Input() id: string;
  constructor(
    // private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.myresult = document.getElementById(`myresult_${this.id}`);
    this.image = document.getElementById(`image_${this.id}`);
    this.myresult.style.display = 'block';
    this.lens = document.getElementById(`lens_${this.id}`);
    this.image.src = this.img;
    this.image.alt = this.title;

    this.myresult.style.visibility = 'hidden';
    this.image.addEventListener('load', () => {
      this.myresult.style.display = 'none';
      this.myresult.style.visibility = 'unset';
      this.myresult.style.backgroundImage = `url('${this.img}')`;
      this.myresult.style.backgroundSize = (this.image.width * this.cx) + 'px ' + (this.image.height * this.cy) + 'px';
    });
    /*calculate the ratio between result DIV and lens:*/
    this.cx = this.myresult.offsetWidth / this.lens.offsetWidth;
    this.cy = this.myresult.offsetHeight / this.lens.offsetHeight;
  }


  ngOnChanges() {

  }

  onMouseOut(e) {
    e.preventDefault();
    e.stopPropagation();
    this.myresult.style.display = 'none';

  }

  onMouseOver(e) {
    e.preventDefault();
    e.stopPropagation();
    this.myresult.style.display = 'block';
  }

  onMouseMove(e) {
    let pos, x, y;
    /*prevent any other actions that may occur when moving over the image:*/
    e.preventDefault();
    e.stopPropagation();
    /*get the cursor's x and y positions:*/
    pos = this.getCursorPos(e);
    /*calculate the position of the lens:*/
    x = pos.x - (this.lens.offsetWidth / 2);
    y = pos.y - (this.lens.offsetHeight / 2);
    /*prevent the lens from being positioned outside the image:*/
    if (x > this.image.width - this.lens.offsetWidth) { x = this.image.width - this.lens.offsetWidth; }
    if (x < 0) { x = 0; }
    if (y > this.image.height - this.lens.offsetHeight) { y = this.image.height - this.lens.offsetHeight; }
    if (y < 0) { y = 0; }
    /*set the position of the lens:*/
    this.lens.style.left = x + 'px';
    this.lens.style.top = y + 'px';
    /*display what the lens "sees":*/
    this.myresult.style.backgroundPosition = '-' + (x * this.cx) + 'px -' + (y * this.cy) + 'px';
  }

  getCursorPos(e) {
    let a, x = 0, y = 0;
    e = e || window.event;
    /*get the x and y positions of the image:*/
    a = this.image.getBoundingClientRect();
    /*calculate the cursor's x and y coordinates, relative to the image:*/
    x = e.pageX - a.left;
    y = e.pageY - a.top;
    /*consider any page scrolling:*/
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return { x: x, y: y };
  }

}
