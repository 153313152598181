import { NotificationsService } from 'angular2-notifications';
import { Injectable } from '@angular/core';
@Injectable()
export class NotificationService {

    constructor(
        private _notifications: NotificationsService,
    ) {}

    openNotification(title, content, type) {
        this._notifications.create(title, content, type);
    }

}

