import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProductListComponent } from './product-list/product-list.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { SellerStoreComponent } from './seller-store/seller-store.component';
import { ProductsComponent } from './products/products.component';

const routes: Routes = [
    // t = general store, p = personalised store
    // { path: '', redirectTo: 'products', pathMatch: 'full' },
    { path: ':slug/products/d/:variantId', component: ProductDetailComponent, pathMatch: 'full' },
    { path: 'seller/:sellerId', component: SellerStoreComponent, pathMatch: 'full' },
    { path: 'products', component: ProductsComponent },
    { path: 'products/:categoryId', component: ProductsComponent },
    { path: 'products/:categoryId/:filter1', component: ProductsComponent },
    { path: 'products/:categoryId/:filter1/:fliter2', component: ProductsComponent },
    { path: 'products/:categoryId/:filter1/:fliter2/:filter3', component: ProductsComponent },
    { path: 'products/:categoryId/:filter1/:fliter2/:filter3/:filter4', component: ProductsComponent },
    { path: 'products/:categoryId/:filter1/:fliter2/:filter3/:filter4/:filter5', component: ProductsComponent },
    { path: 'products/:categoryId/:filter1/:fliter2/:filter3/:filter4/:filter5/:filter6', component: ProductsComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProductRoutingModule { }

