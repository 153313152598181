import { InventoryList, Inventory } from './inventory';
import { Verdict } from './game';
import { Tag } from './tag';
import { ReviewList } from './review';

export interface Media {
    url: string;
    s3key: string;
    thumbnail: string;
}

export interface Variant {
    id: string;
    _id: string;
    url: string;
    name: string;
    s3key: string;
    thumbnail: string;
    inventoryReference: string;
    productID: string;
    createdDate: Date;
    status: string;
    description: string;
    urlSlug: string;
    metaTitle: string;
    mainThumbnail: any;
    seoTitle: string;
    tags: Array<Tag>;
    colour: Array<Tag>;
    material: Array<Tag>;
    filter: Array<Tag>;
    style: Array<Tag>;
    surcharge: number;
    rrp: number;
    rrpWithoutDiscount: number;
    inventoryStatuses: Array<Inventory>;
    sellerInventories: Array<any>;
    medias: Array<Media>;
    seoDescription?: string;
    selectedSwatch?: any;
    selectedOption?: any;
    variantSwatchTags?: any;
}

export interface Product {
    id?: string;
    _id: string;
    markUp: number;
    vatTax: number;
    productId: string;
    productID: string;
    parent: string;
    parentName: string;
    name: string;
    bookmarks: number;
    actualPrice: number;
    numberReviews: number;
    scoreReviews: number;
    keywords: string[];
    deliveryNote: string;
    productSummary: string;
    detailedDescription: string;
    description: string;
    status: string;
    numberComments: number;
    createdDate: Date;
    updatedDate: Date;
    lastVerifiedDate: Date;

    style: string[];
    filter: string[];
    brand: string;
    category: string[];
    room: string[];
    material: string[];
    colour: string[];

    picture: Media[];
    medias: Media[];

    media: Media[];
    layout: Media[];


    mainPicture: string;
    mainThumbnail: string;

    link: string;
    associatedProducts: string[];
    variants: Variant[];
    inventory: InventoryList;
    reviews: ReviewList;

    inventoryReference: string;
    averageReview: number;

    heightDim: number;
    lengthDim: number;
    widthDim: number;

    eanNumber: string;
    priceSuffix: string;
    defaultVariant: string;
    urlSlug: string;
    brandObj: Tag;
    tags: Tag[];

    isBookmarked: boolean;
    seo_title: string;
    seo_description: string;
    rrp: number;
    price: number;
    currentPrice: number;
    promotionalDiscount: number;
    swatchComponents?: Array<any>;
}

export interface ProductList {
    list: Product[];
    jsonFields: string[];
    htmlFields: string[];
    verdict: Verdict;
    aggregations: Map<string, Aggregation>;
}

export interface Store {
    name: string;
    seo_description: string;
    mainPicture: string;
    _id: string;
}
export interface VariantList {
    list: Variant[];
    jsonFields: string[];
    htmlFields: string[];

}
export interface ProductResponse {
    responseStatus: number;
    message: string;
    data: Product;

}
export interface ProductListResponse {
    responseStatus: number;
    message: string;
    data: ProductList;

}
export interface VariantListResponse {
    responseStatus: number;
    message: string;
    data: VariantList;

}

export interface Aggregation {
    buckets: {
        key: string;
        doc_count: number;
    }[];
}

export class ProductEvent {
    _id: string;
    name: string;
    category: string[];
    brand: string;
    room: string[];

    constructor(product: Product) {
        this._id = product._id;
        this.name = product.name;
        this.category = product.category;
        this.brand = product.brand;
        this.room = product.room;
    }
}
