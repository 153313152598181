import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ProfileService } from './services/profile.service';
// import { TagService } from './services/tag.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styles: []
})

export class AppComponent implements OnInit {
    options = {
        position: ['top', 'right'],
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true,
    };
    logoUrl:string = '';
    footer: { logo: string | null; links: any[]; } = { logo: null, links: [] }

    constructor(private location: Location, public profileService: ProfileService) { }

    ngOnInit() {
        const pathWithoutHash = this.location.path(false);
        this.location.replaceState(pathWithoutHash);
        this.profileService.fetchTemplateDetails().subscribe(
            (res: any) => {
                if (res) {
                    this.profileService.templatDetails = res;
                    this.logoUrl = res['logoUrl'] || null;
                    this.footer = {
                        logo: res['footerLogoUrl'] || null,
                        links: res['footerSocialLinks'] || null
                    }
                    this.profileService.reRendersliderContent.next(true);
                }
            })
    }

    getScrollHeight(event) {
        console.log(event);
    }

}
