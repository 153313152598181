import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth.service';
import { PostLoginComponent } from './post-login/post-login.component';

const routes: Routes = [
    { path: '', loadChildren: './dashboard/dashboard.module#DashboardModule' },
    { path: 'post-login', component: PostLoginComponent, pathMatch: 'full' },
    { path: 'profile', loadChildren: './profile/profile.module#ProfileModule' },
    { path: 'game', loadChildren: './game/game.module#GameModule' },
    { path: 'info', loadChildren: './info/info.module#InfoModule' },
    { path: 'purchase', loadChildren: './purchase/purchase.module#PurchaseModule' },
    { path: 'store', loadChildren: './stores/stores.module#StoresModule' },
    { path: ':slug/blog', loadChildren: './blog/blog.module#BlogModule' },
    { path: ':slug/moodrooms', loadChildren: './personroom/personroom.module#PersonroomModule' },
    // { path: 'store', loadChildren: './product/product.module#ProductModule' },
    // { path: ':slug/products', loadChildren: './product/product.module#ProductModule' },
    { path: '', loadChildren: './wildcard-routing/wildcard-routing.module#WildcardRoutingModule' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false }), ],
    exports: [RouterModule],
    providers: [AuthGuardService],
})
export class AppRoutingModule { }

