// import 'rxjs/add/observable/of';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {HttpService} from './http.service';
import { ContentResponse, Content, ChatResponse, ChatInput } from '../classes/content';


@Injectable()
export class ContentService {

    testC: Content ;

  constructor(
    private http: HttpService,

  ) { }

    getContentDetails(id: string): Observable<ContentResponse> {
        return this.http.getHttpJson('/v1/api/contents/getcontentdetails?contentID=' + id);
    }

    chatBot(data: ChatInput): Observable<ChatResponse> {
        return this.http.postHttpJson('/v1/api/contents/chatbot', data);
    }

    getMenus() {
      const hostName = window.location.hostname;
      let baseUrl:string = 'http://localhost:4200';

      if(hostName.includes('moodmaestro')){
        baseUrl = 'https://www.moodmaestro.co.uk'
      }
      else if(hostName.includes('decoraitrade')){
        baseUrl = 'https://decoraitrade.com';
      }
      
      return of([
        {
          id: 'cat-furniture',
          name: 'Furniture',
          apiname:'FURNITURE',
          url: '/products/category-furniture',
          subMenus: []
        },
        {
          id: '',
          name: 'Outdoors',
          apiname:'OUTDOORS',
          url: '/products/room-garden',
          subMenus: []
        },
        {
          id: 'cat-accessories',
          name: 'Accessories & Décor',
          apiname:'ACCESSORIESNDECOR',
          url: '/products/category-accessories',
          subMenus: []
        },
        {
          id: 'cat-lighting',
          name: 'Lighting',
          apiname:'LIGHTING',
          url: '/products/category-lighting',
          subMenus: []
        },
        {
          id: '',
          name: 'Brands',
          apiname:'BRANDS',
          url: '/store/brands',
          subMenus: []
        },
        {
          id: '',
          name: 'Blogs',
          apiname:'BLOGS',
          url: '/_/blog/t',
          subMenus: []
        },
      ]);
    }

    getDynamicMenu(menuName:string){
      return this.http.getHttpNewJson(`web/retailer-menu?menuType=${menuName}`)
    }

}

