
// import { Injectable } from '@angular/core';
import { Injectable, Injector } from '@angular/core';

import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse,
} from '@angular/common/http';
// import { HttpService } from './http.service';
import { Observable, Subject, of } from 'rxjs';
// import 'rxjs/add/operator/do';
import { tap, mergeMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
// import { DisplayErrorService } from './display-error.service';
import { environment } from '../../environments/environment';


/**
 * Cache Service is an observables based in-memory cache implementation
 * Keeps track of in-flight observables and sets a default expiry for cached values
 * @export
 * @class CacheService
 */
interface CacheContent {
    expiry: number;
    value: any;
}

@Injectable()
export class CacheService {
    private cache: Map<string, CacheContent> = new Map<string, CacheContent>();
    private inFlightObservables: Map<string, Subject<any>> = new Map<string, Subject<any>>();
    readonly DEFAULT_MAX_AGE: number = 300000;

    /**
     * Gets the value from cache if the key is provided.
     * If no value exists in cache, then check if the same call exists
     * in flight, if so return the subject. If not create a new
     * Subject inFlightObservable and return the source observable.
     */
    get(key: string, fallback?: Observable<any>, maxAge?: number): Observable<any> | Subject<any> {

        // if (this.hasValidCachedValue(key)) {
        //     console.log(`%cGetting from cache ${key}`, 'color: green');
        //     return of(this.cache.get(key).value);
        // }

        if (!maxAge) {
            maxAge = this.DEFAULT_MAX_AGE;
        }

        if (this.inFlightObservables.has(key)) {
            return this.inFlightObservables.get(key);
        } else if (fallback && fallback instanceof Observable) {
            this.inFlightObservables.set(key, new Subject());
            // console.log(`%c Calling api for ${key}`, 'color: purple');
            return fallback.pipe(tap(
                value => { this.set(key, value, maxAge); }
            ));
        } else {
            return null;
        }

    }

    /**
     * Sets the value with key in the cache
     * Notifies all observers of the new value
     */
    set(key: string, value: any, maxAge: number = this.DEFAULT_MAX_AGE): void {
        this.cache.set(key, { value: value, expiry: Date.now() + maxAge });
        this.notifyInFlightObservers(key, value);
    }

    /**
     * Checks if the a key exists in cache
     */
    has(key: string): boolean {
        return this.cache.has(key);
    }

    isCachable(req: HttpRequest<any>): boolean {
        return req.method === 'GET' && !/_force=true/.test(req.url);
    }

    getCacheKey(req: HttpRequest<any>): string {
        return req.url;
    }

    /**
     * Publishes the value to all observers of the given
     * in progress observables if observers exist.
     */
    private notifyInFlightObservers(key: string, value: any): void {
        if (this.inFlightObservables.has(key)) {
            const inFlight = this.inFlightObservables.get(key);
            const observersCount = inFlight.observers.length;
            if (observersCount) {
                // console.log(`%cNotifying ${inFlight.observers.length} flight subscribers for ${key}`, 'color: blue');
                inFlight.next(value);
            }
            inFlight.complete();
            this.inFlightObservables.delete(key);
        }
    }

    /**
     * Checks if the key exists and   has not expired.
     */
    private hasValidCachedValue(key: string): boolean {
        if (this.cache.has(key)) {
            if (this.cache.get(key).expiry < Date.now()) {
                this.cache.delete(key);
                return false;
            }

            return true;
        } else {
            return false;
        }
    }
}

@Injectable()
export class GeneralHttpInterceptor implements HttpInterceptor {

    private apiBaseUrl = environment.apiNewUrl;
    private styleBaseUrl = environment.styleBaseUrl;
    constructor(
        // private http: HttpService,
        private injector: Injector,
        // private auth: AuthService
    ) { }

    private defaultHeaders = {
        'content-type': 'application/json',
    };

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authenticationService = this.injector.get(AuthService);

        return authenticationService.getSessionToken().pipe(mergeMap(token => {
            const headers: any = Object.assign({}, this.defaultHeaders);
            if (authenticationService.isLoggedIn() && token && request.url.indexOf(this.styleBaseUrl) >= 0) {
                headers['Authorization'] = 'Bearer ' + token;
            } else {
                if (authenticationService.isLoggedIn() && token && request.url.includes('/api/customer/')) {
                    headers['Authorization'] = 'Bearer ' + token;
                } else {
                    const hostname = window.location.hostname;
                    if (hostname.includes('moodmaestro')) {
                        headers['X-Api-Token'] = 'dec7bb30-3742-11ed-a261-0242ac120002';
                    }
                    else if(hostname.includes('decoraitrade')){
                        headers['X-Api-Token'] = 'c268f160-260d-440c-8537-59bb6643639c';
                    }
                    else if(hostname.includes('decorait.shop')){
                        headers['X-Api-Token'] = '53809d3d-beee-4004-bd3e-b4c3466c50e7';
                    }
                    else if(hostname.includes('jazzmyroom.com')){
                        headers['X-Api-Token'] = 'd6787f2a-fe6e-45d6-aa06-581d98e92c24';
                    }
                    else if(hostname.includes('localhost')){
                        headers['X-Api-Token'] = 'aed3fcd8-ac8a-465d-9078-0b587b8e564f';
                    } 
                }
            }

            request = request.clone({
                setHeaders: headers
            });

            return next.handle(request);
        }));
    }
}

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
    constructor(private cache: CacheService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {

        // continue if not cachable.
        if (!this.cache.isCachable(req)) { return next.handle(req); }

        const cachedResponse = this.cache.get(this.cache.getCacheKey(req));

        return cachedResponse ?
            cachedResponse : next.handle(req).pipe(tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.cache.set(this.cache.getCacheKey(req), event);
                }
            }, (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    this.cache.set(this.cache.getCacheKey(req), err);
                }
            }));
    }
}

