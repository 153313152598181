import { Component, OnInit, ChangeDetectionStrategy, AfterViewInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent implements OnInit, AfterViewInit {

    constructor(
        private cd: ChangeDetectorRef,

    ) { }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.cd.detach();
    }

}
