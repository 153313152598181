import { Component, OnInit, HostListener, PLATFORM_ID, Inject,
    ChangeDetectionStrategy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DisplayErrorService, DisplayError } from '../../services/display-error.service';
import { ProductService } from '../../services/product.service';
import { Product } from '../../classes/product';
import { Tag } from '../../classes/tag';
import { TagService } from '../../services/tag.service';
import { NavigationService } from '../../services/navigation.service';
import { Verdict } from '../../classes/game';
import { Seller } from '../../classes/seller';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { first } from 'rxjs/operators';
// import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-seller-store',
  templateUrl: './seller-store.component.html',
  styleUrls: ['./seller-store.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SellerStoreComponent implements OnInit, AfterViewInit {

    Object = Object;
    productsList: Product[];
    roomTags: Map<string, Tag> = new Map;
    styleTags: Map<string, Tag> = new Map;
    brandTags: Map<string, Tag> = new Map;
    categoryTags: Map<string, Tag> = new Map;
    aggregations: Map<string, Map<string, number>> = new Map;
    sortList = null;
    perPage = 12;

    verdictList: Verdict[];
    sellerId: string;
    categoryShowingMore: boolean;
    moreProductsRemaining: boolean;

    roomId: string[] = [];
    categoryId: string[] = [];
    brandId: string[] = [];
    maxPrice: number;
    searchSeed: string;
    prefix: string ;
    searchJson: any = {};
    showPersonalisedFlag: boolean;
    storeId: string;
    selectedVerdict: Verdict;
    selectedVerdictId: string;
    sellerObj: Seller;
    isBrowser: boolean;
    isScrolledDown: boolean;


    @HostListener('window:scroll', [])
    onScroll(): void {
        if (isPlatformBrowser(this.platformId)) {
            if (this.moreProductsRemaining && (window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                this.getProductList(true);
            }
            if (window.scrollY > 200) {
                this.isScrolledDown = true;
            } else {
                this.isScrolledDown = false;
            }
        }
    }


    constructor(
        private route: ActivatedRoute,
        private productService: ProductService,
        private tagService: TagService,
        private navigationService: NavigationService,
        private errorService: DisplayErrorService,
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(DOCUMENT) private document: any,
        private cd: ChangeDetectorRef,
        // private titleService: Title,
        // private metaService: Meta,

    ) {

     }

    ngOnInit() {
        this.isBrowser = isPlatformBrowser(this.platformId);

        this.route.paramMap.pipe(first()).subscribe(params => {
            if (params['params']  && params['params']['sellerId']) {
                this.sellerId = params['params']['sellerId'];
                this.getProductList(false);
            }
        });





    }
    ngAfterViewInit() {
        if (this.isBrowser) {
            this.tagService.cachedTagsList('room').pipe(first()).subscribe(result => {
                this.roomTags = result;
            });
            this.tagService.cachedTagsList('brand').pipe(first()).subscribe(result => {
                this.brandTags = result;
            });
            this.tagService.cachedTagsList('style').pipe(first()).subscribe(result => {
                this.styleTags = result;
            });
            this.tagService.cachedTagsList('category').pipe(first()).subscribe(result => {
                this.categoryTags = result;
            });
            this.getSellerDetails(this.sellerId);
            this.errorService.reportModule('products');
            this.cd.detectChanges();
        }

    }
    openSellerProfile() {
        this.navigationService.navigateSellerProfile(this.sellerObj.sellerID);
    }

    getSellerDetails(sellerId) {

        this.errorService.reportBusy(1);
        this.productService.sellersList([sellerId], 1, 0, null)
        .subscribe(result => {
            this.errorService.reportBusy(-1);

            if (result.data && result.data[0]) {
                this.sellerObj = result.data[0];
            }

        }, err => {
            this.errorService.reportBusy(-1);
            if (err.error && err.error.message) {
                this.errorService.reportError(new DisplayError('error', err.error.message, '', this.cd));
            } else {
                this.errorService.reportError(new DisplayError('error', 'Error retrieving seller details', '', this.cd));
            }
        });

    }
    updateSearchPrice(price: number) {
        this.maxPrice = price;
        if (!price) {
            delete this.searchJson['actualPrice'];
        } else {
            this.searchJson['actualPrice'] = {$lt: price};

        }
        this.getProductList(false);
    }
    resetSearch() {
        this.roomId = [];
        this.brandId = [];
        this.categoryId = [];
        this.prefix = '';
        this.searchJson = {};
        delete this.searchJson['actualPrice'];
        this.maxPrice = null;
        this.getProductList(false);

    }


    openProductDetails(product: Product) {
        this.navigationService.navigateProductDetail(product);
    }


    mathRound(x: number) {
        return Math.round(x);
    }
    scrollTopPage() {
        if (this.isBrowser) {
            this.document.body.scrollTop = 0; // For Safari
            this.document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }
    }


    updateCategory(catId: string) {
        const index = this.categoryId.indexOf(catId);
        if (!catId) {
            this.categoryId = [];
        } else if (index >= 0) {
            this.categoryId = this.categoryId.slice(0, index + 1);
        } else {
            this.categoryId.push(catId);
        }
        if (catId) {
            this.searchJson['category'] = {$json_contains: catId};
        }  else {
            delete this.searchJson['category'];
        }
        this.getProductList(false);
    }


    getProductList( more: boolean) {

        const skip = more ? this.productsList.length : 0;
        const searchJson = this.searchJson;

        if (!more || !this.searchSeed || this.searchSeed === '') {
            this.searchSeed = String(Date.now());
            this.sortList = `RAND(${this.searchSeed})`;
        }

        this.errorService.reportBusy(1);
        this.productService.getSellerStore(
            this.searchJson, this.sellerId, this.perPage, skip, this.sortList
        ).subscribe(result => {
            this.errorService.reportBusy(-1);
            if (result.data.list.length >= this.perPage) {
                this.moreProductsRemaining = true;
            } else {
                this.moreProductsRemaining = false;
            }
            const jsonFields = result.data.jsonFields;

            result.data.list.forEach(element => {
                Object.keys(element).forEach(key => {
                    if (jsonFields.indexOf(key) >= 0) {
                        try {
                            element[key] = JSON.parse(element[key]);
                        } catch (e) {

                        }
                    }
                });
            });
            if (more) {
                this.productsList = this.productsList.concat(result.data.list);
            } else {
                this.productsList = result.data.list;
            }
            this.cd.detectChanges();

        }, err => {
            this.errorService.reportBusy(-1);
            if (err.error && err.error.message) {
                this.errorService.reportError(new DisplayError('error', err.error.message, '', this.cd));
            } else {
                this.errorService.reportError(new DisplayError('error', 'Error retrieving the seller store', '', this.cd));
            }
        });
    }

    // updateBrand(brandId: string) {
    //     const index = this.brandId.indexOf(brandId);
    //     if (!brandId) {
    //         this.brandId = [];
    //     } else if (index >= 0) {
    //         this.brandId = this.brandId.slice(0, index + 1);
    //     } else {
    //         this.brandId.push(brandId);
    //     }
    //     this.searchJson['brand'] = brandId;
    //     this.getProductList(false);

    // }
    updateRoom(roomId: string) {
        const index = this.roomId.indexOf(roomId);
        if (!roomId) {
            this.roomId = [];
        } else if (index >= 0) {
            this.roomId = this.roomId.slice(0, index + 1);
        } else {
            this.roomId.push(roomId);
        }
        if (roomId) {
            this.searchJson['room'] = {$json_contains: roomId};

        } else {
            delete this.searchJson['room'];
        }
        this.getProductList(false);

    }

}
