import { Injectable } from '@angular/core';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { StringResponse } from '../../classes/profile';
import { CartResponse, Cart } from '../../classes/purchase';
import { AuthService } from '../../services/auth.service';
import { DisplayErrorService } from '../../services/display-error.service';
import { HttpService } from '../../services/http.service';
import { LocalStorageWrapper } from '../../services/utils.service';
import { HttpParams } from '@angular/common/http';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
@Injectable()
export class CartService {
  brandIcon = {
    'Visa': 'https://js.stripe.com/v3/fingerprinted/img/visa-d6c6e0a636f7373e06d5fb896ad49475.svg',
    'MasterCard': 'https://js.stripe.com/v3/fingerprinted/img/mastercard-a96ee3841a5e1e28d05ed3f0f4da62b8.svg',
    'American Express': 'https://js.stripe.com/v3/fingerprinted/img/amex-edf6011de255d8a4c22904795c9d8770.svg',
    'Discover': 'https://js.stripe.com/v3/fingerprinted/img/discover-8f3d8fc6ef836da1fcac12c095ee6fb8.svg',
    'Diners': 'https://js.stripe.com/v3/fingerprinted/img/diners-fced9e136fd8c25f40a3e7b37a51dc1d.svg',
    'Jcb': 'https://js.stripe.com/v3/fingerprinted/img/jcb-1b12d588a1e9465d4d9fb84a610f9136.svg'

  };
  order_by_email_summary = new BehaviorSubject({});
  constructor(
    private http: HttpService,
    private storageService: LocalStorageWrapper,
    private auth: AuthService,
    private errorService: DisplayErrorService
  ) { }



  addToCart(
    productId: string,
    variantId: string,
    sellerId: string,
    quantity: number,
    optionId: string,
    swatchId: string
  ): Observable<CartResponse> {

    if (!this.auth.isLoggedIn()) {
      return throwError({ error: { message: 'You must be logged in' } });
    }

    const cartItem = {
      variantId: variantId,
      sellerId: sellerId,
      quantity: quantity,
    };
    if (optionId) { cartItem['optionId'] = optionId; }
    if (swatchId) { cartItem['swatchId'] = swatchId; }

    return this.http.postCustomerJsonBaseUrl(`/api/customer/cart`, cartItem).pipe(
      switchMap((result) => {
        let cart = this.storageService.getCart() as Array<any> || [];
        if (!cart.hasOwnProperty('length')) {
          cart = [];
        }
        const sameProduct = cart.length && cart.find(p => p.id === result.id);
        if (sameProduct) {
          sameProduct.quantity += quantity;
        } else {
          cart.push(result);
        }
        this.storageService.setCart(cart);
        this.errorService.reportCart(result.length);
        return of(result);
      })
    );
  }

  editCart(cartId: string, qty: Number): Observable<StringResponse> {
    if (!this.auth.isLoggedIn()) {
      return throwError({ error: { message: 'You must onRemoveProduct logged in' } });
    }
    const item = {
      quantity: qty
    };
    return this.http.putCustomerJsonBaseUrl(`/api/customer/cart/${cartId}?quantity=${qty}`, {}).pipe(
      switchMap((result) => {
        let cart = this.storageService.getCart() as Array<any> || [];
        if (!cart.hasOwnProperty('length')) {
          cart = [];
        }
        const index = cart.findIndex(f => f.id === result.id);
        cart[index] = result;
        this.storageService.setCart(cart);

        this.errorService.reportCart(result.length);
        return of(result);
      })
    );
  }

  removeFromCart(cartId: string): Observable<StringResponse> {
    if (!this.auth.isLoggedIn()) {
      return throwError({ error: { message: 'You must be logged in' } });
    }
    return this.http.deleteCustomerJsonBaseUrl(`/api/customer/cart/${cartId}`).pipe(
      switchMap((result) => {
        let cart = this.storageService.getCart() as Array<any> || [];
        if (!cart.hasOwnProperty('length')) {
          cart = [];
        }
        cart.splice(
          cart.findIndex((a) => a.id == result.id),
          1
        );
        this.storageService.setCart(cart);
        this.errorService.reportCart(result.length);
        return of(result);
      })
    );
  }

  getCachedCart(): Observable<CartResponse> {
    const result = this.storageService.getCart();
    return of({ message: '', responseStatus: 1, data: result || [] });
  }

  getCart(): Observable<CartResponse> {
    if (!this.auth.isLoggedIn()) {
      return throwError({ error: { message: 'You must be logged in' } });
    }
    return this.http.getCustomerJsonBaseUrl(`/api/customer/carts`).pipe(
      switchMap((result) => {
        this.storageService.setCart(result);
        this.errorService.reportCart(result.length);
        return of(result);
      })
    );
  }

  removeAllFromCart() {
    return this.http.deleteCustomerJsonBaseUrl(`/api/customer/carts/clear`).pipe(
      switchMap((result) => {
        this.storageService.setCart(result);
        this.errorService.reportCart(result.length);
        return of(result);
      })
    );
  }
  addAddress(
    address: any
  ): Observable<any> {

    if (!this.auth.isLoggedIn()) {
      return throwError({ error: { message: 'You must be logged in' } });
    }
    return this.http.postCustomerJsonBaseUrl(`/api/customer/address`, address).pipe(
      switchMap((result) => {
        return of(result);
      })
    );
  }

  editAddress(addressId: string, address: any): Observable<StringResponse> {
    if (!this.auth.isLoggedIn()) {
      return throwError({ error: { message: 'You must be logged in' } });
    }
    return this.http.putCustomerJsonBaseUrl(`/api/customer/address/${addressId}`, address).pipe(
      switchMap((result) => {
        return of(result);
      })
    );
  }

  getAddress(): Observable<any> {
    if (!this.auth.isLoggedIn()) {
      return throwError({ error: { message: 'You must be logged in' } });
    }

    return this.http.getCustomerJsonBaseUrl(`/api/customer/addresses`).pipe(
      switchMap((result) => {
        return of(result);
      })
    );
  }

  getAddressFromPostalCode(address) {
    if (!this.auth.isLoggedIn()) {
      return throwError({ error: { message: 'You must be logged in' } });
    }

    return this.http.postCustomerJsonBaseUrl(`/api/customer/address`, address).pipe(
      switchMap((result) => {
        return of(result);
      })
    );
  }

  removeAddress(addresId: string): Observable<StringResponse> {
    if (!this.auth.isLoggedIn()) {
      return throwError({ error: { message: 'You must be logged in' } });
    }
    return this.http.deleteCustomerJsonBaseUrl(`/api/customer/address/${addresId}`).pipe(
      switchMap((result) => {
        return of(result);
      })
    );
  }

  saveOrder(
    id: string,
  ): Observable<any> {

    if (!this.auth.isLoggedIn()) {
      return throwError({ error: { message: 'You must be logged in' } });
    }

    const addressItem = {
      addressId: id,
    };

    return this.http.postCustomerJsonBaseUrl(`/api/customer/order`, addressItem).pipe(
      switchMap((result) => {
        return of(result);
      })
    );
  }

  searchAddress(postcode: string, checkLogin:boolean = true): Observable<any> {
    if (!this.auth.isLoggedIn() && checkLogin) {
      return throwError({ error: { message: 'You must logged in' } });
    }

    return this.http.getCustomerAddressJsonBaseUrl(`/api/address?postcode=${postcode}`, {}).pipe(
      switchMap((result) => {
        return of(result);
      })
    );
  }

  getOrders(page: any, size: any, status: string): Observable<any> {
    if (!this.auth.isLoggedIn()) {
      return throwError({ error: { message: 'You must be logged in' } });
    }
    const customUrl = status == 'all' ? `/api/customer/orders?page=${page}&size=${size}` : `/api/customer/orders?page=${page}&size=${size}&status=${status}`;
    return this.http.getCustomerJsonBaseUrl(customUrl).pipe(
      switchMap((result) => {
        return of(result);
      })
    );
  }

  getOrderDetails(id: string) {
    if (!this.auth.isLoggedIn()) {
      return throwError({ error: { message: 'You must be logged in' } });
    }

    return this.http.getCustomerJsonBaseUrl(`/api/customer/order/${id}`).pipe(
      switchMap((result) => {
        return of(result);
      })
    );
  }

  cancelOrder(orderId: string, id: string, value: any): Observable<any> {
    if (!this.auth.isLoggedIn()) {
      return throwError({ error: { message: 'You must be logged in' } });
    }
    const cancelItem = {
      id: id,
      comment: value.comment
    };
    return this.http.putCustomerJsonBaseUrl(`/api/customer/order/${orderId}/cancel`, cancelItem).pipe(
      switchMap((result) => {
        return of(result);
      })
    );
  }

  returnOrder(orderId: string, id: string, value: any): Observable<any> {
    if (!this.auth.isLoggedIn()) {
      return throwError({ error: { message: 'You must be logged in' } });
    }
    const returnItem = {
      id: id,
      comment: value.comment
    };
    return this.http.putCustomerJsonBaseUrl(`/api/customer/order/${orderId}/return`, returnItem).pipe(
      switchMap((result) => {
        return of(result);
      })
    );
  }

  addCard(record: any): Observable<any> {
    if (!this.auth.isLoggedIn()) {
      return throwError({ error: { message: 'You must be logged in' } });
    }
    return this.http.postCustomerJsonBaseUrl(`/api/customer/order/payment`, record).pipe(
      switchMap((result) => {
        return of(result);
      })
    );
  }

  listCard(): Observable<any> {
    if (!this.auth.isLoggedIn()) {
      return throwError({ error: { message: 'You must be logged in' } });
    }
    return this.http.getCustomerJsonBaseUrl(`/api/customer/cards`).pipe(
      switchMap((result) => {
        return of(result);
      })
    );
  }

  getCommentOption(type: string): Observable<any> {
    if (!this.auth.isLoggedIn()) {
      return throwError({ error: { message: 'You must be logged in' } });
    }
    return this.http.getCustomerJsonBaseUrl(`/api/comments?type=${type}`).pipe(
      switchMap((result) => {
        return of(result);
      })
    );
  }
  getOrderSummary(groupId: string): Observable<any> {
    if (!this.auth.isLoggedIn()) {
      return throwError({ error: { message: 'You must be logged in' } });
    }
    return this.http.getCustomerJsonBaseUrl(`/api/order/summary?groupId=${groupId}`).pipe(
      switchMap((result) => {
        return of(result);
      })
    );
  }
  removeCard(cardId: string): Observable<any> {
    if (!this.auth.isLoggedIn()) {
      return throwError({ error: { message: 'You must be logged in' } });
    }
    return this.http.deleteCustomerJsonBaseUrl(`/api/customer/card/${cardId}`).pipe(
      switchMap((result) => {
        return of(result);
      })
    );

  }
}
