import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  Inject,
  PLATFORM_ID,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterViewInit,
  Injector,
  Input,
} from '@angular/core';
import {
  Router,
  ActivatedRoute,
  Params,
  NavigationEnd,
  NavigationStart,
  NavigationExtras,
} from '@angular/router';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { SearchService } from '../services/search.service';
import * as _ from 'lodash';
import { debounce } from 'lodash';
import {
  DisplayErrorService,
  DisplayError,
} from '../services/display-error.service';
import { PurchaseService } from '../services/purchase.service';
import { CartResponse } from '../classes/purchase';
import { ProductService } from '../services/product.service';
import { NavigationService } from '../services/navigation.service';
import { Subscription, forkJoin } from 'rxjs';
import { PostService } from '../services/post.service';
import { PersonroomService } from '../services/personroom.service';
import { LocalStorageWrapper } from '../services/utils.service';
import { debounceTime, switchMap, first } from 'rxjs/operators';
import { isPlatformBrowser, KeyValue } from '@angular/common';
import { ProfileService } from '../services/profile.service';
import { Profile } from '../classes/profile';
import { environment } from '../../environments/environment';
import { TagService } from '../services/tag.service';
import { Tag } from '../classes/tag';
import { HeaderMenus } from './menu';
import { filter } from 'rxjs/operators';
import {
  HeaderMenu,
  HeaderGroup,
  HeaderColumn,
  HeaderLink,
} from '../classes/header';
import { ContentService } from '../services/content.service';
import { ChatInput, Chat } from '../classes/content';
import { ngxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { SidebarNavigationService } from '../services/SidebarNavigationService';
import { Sidebar } from '../product/sidebar/sidebar.component';
import { GameService } from '../services/game.service';
import { CartService } from '../purchase/cart/cart.service';
import { NotificationService } from '../services/notification.service';
export function compare(a, b) {
  if (a.quantity > b.quantity) {
    return -1;
  }
  if (a.quantity < b.quantity) {
    return 1;
  }
  return 0;
}
declare let $: any;
declare let window: Window;
const FILTER_TYPE = {
  brand: {},
  category: {},
  room: {},
  colour: {},
  material: {},
  filter: {},
  style: {}
};
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, AfterViewInit {

  @Input() logoUrl: string = null;
  @Input() seller: string = null;
  menus: any[] = [];

  @ViewChild('cartDetailsHolder', { static: true }) cartDetailsHolder!: ElementRef;
  @ViewChild('cartButton', { static: true }) cartButton!: ElementRef;

  cartList:any[] = [];
  subTotal: any;
  totalItems: Number;
  isCartPage:boolean = false;

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private errorService: DisplayErrorService,
    private searchService: SearchService,
    private navigationService: NavigationService,
    private purchaseService: PurchaseService,
    private productService: ProductService,
    private postService: PostService,
    private personroomService: PersonroomService,
    private fb: FormBuilder,
    private profileService: ProfileService,
    private router: Router,
    private storageService: LocalStorageWrapper,
    private contentService: ContentService,
    private navService: SidebarNavigationService,
    private cd: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object,
    private tagService: TagService,
    private injector: Injector,
    private gameService: GameService,
    private cartService: CartService,
    private notification: NotificationService,

  ) {
    this.contentService.getMenus().subscribe(menus => {
      this.menus = menus;
      this.menus.forEach(element => {
        element.subMenus.forEach(sb => {
          sb.categories.sort(compare);
        });
      });
    });

    this.onChange = debounce(this.onChange, 1000);
    this.searchIndex = 'products';
    this.searchField = new FormControl();
    this.headerSearchForm = this.fb.group({ search: this.searchField });
    // this.headerSearchFormMobile = this.fb.group({search: this.searchFieldMobile});
  }
  @ViewChild(Sidebar, { static: false }) private sidebarInfoComponent: Sidebar;
  tagDectionary: any = {};
  @ViewChild('headerMessageElement', { static: false })
  headerMessageElement: ElementRef;
  @ViewChild('headerBarElement', { static: false })
  headerBarElement: ElementRef;
  @ViewChild('headerUpperElement', { static: false })
  headerUpperElement: ElementRef;

  @ViewChild('storeDrop', { static: false }) storeDrop: ElementRef;
  @ViewChild('moodroomDrop', { static: false }) moodroomDrop: ElementRef;
  @ViewChild('chatHistoryElement', { static: false })
  chatHistoryElement: ElementRef;

  timeoutId: number;
  headerMessage: DisplayError;
  searchField: FormControl;
  searchFieldMobile: FormControl;
  // searchField: FormControl;
  searchIndex: string;
  headerSearchForm: FormGroup;
  // headerSearchFormMobile: FormGroup;
  searchItemList: string[];
  selectedTag: string;
  cookieConsent = 'no';
  isBusy = false;
  cartSize: number;
  currentModule: string;
  // searchText: string;
  autoCompleteRequest: Subscription;
  autoCompleteSearchOn = false;
  isBrowser: boolean;
  myProfile: Profile ={
    _id: '',
    uid: '',
    name: '',
    username: '',
    city: '',
    country: '',
    postcode: '',
    telephone: '',
    address: '',
    gender: '',
    pictureURL: '/assets/header/user.png',
    paymentId: '',
  };
  showProfileMenu = false;
  mobileMenuOpened = false;
  mobileSearchOpened = false;
  chatSessionId: string;
  chatInput = '';
  currentPage = 1;
  moreProductsRemaining: boolean;
  loadMoreLoader = false;
  parentNames: any = {};
  // roomTags: Map<string, Tag> = new Map;
  roomTags: Map<string, Tag> = new Map();
  styleTags: Map<string, Tag> = new Map();
  categoryTags: Map<string, Tag> = new Map();
  brandTags: Map<string, Tag> = new Map();

  storeMenu: HeaderMenu;
  moodroomMenu: HeaderMenu;
  showChatBot = <'hide' | 'minimise' | 'show'>'hide';
  chatHistory = <Chat[]>[];
  _ngxZendeskWebwidgetService: ngxZendeskWebwidgetService;
  showSearchResult = false;
  selectedFilterType = null;
  isSuggestionLoader = false;
  filterProductsLoader = false;
  subscription = null;
  isShowCloseButton = false;
  isShowCategory = true;
  filterNameListSubscription = null;
  selectedFiltersNames: any = [];
  suggestedProducts: Array<any> = [];
  suggestions: Array<any> = [];
  catTags: any;
  allTags: any;
  selectedFilters: any;
  tags: any = {};
  isHeaderShow = false;
  totalProducts: any;
  selectedTags = FILTER_TYPE;
  Object = Object;
  isComponentMount = true;


  removedForcefully: boolean;
  isDevelopment = false;

  selectedStyle: any[] = [];
  blogs: any[] = [
      { id: 'st-rustic', name: 'rustic', quantity: 0, url: `/rustic-artisanal/blog/d/80a8ed91562f4889baf7e7e608bd89be` },
      { id: 'st-artdeco', name: 'artdeco', quantity: 0, url: `/art-deco/blog/d/34526288abec4b0daaacfa2e0eb3a286` },
      { id: 'st-baroque', name: 'baroque', quantity: 0, url: `/neo-baroque/blog/d/f1c6a42130f9482f8a168d5b92e09243` },
      { id: 'st-industrial', name: 'industrial', quantity: 0, url: `/boho-industrial/blog/d/d47717b67cac47faa926aa121bbcfb69` },
      { id: 'st-classic', name: 'classic', quantity: 0, url: `/classic-antique/blog/d/74f8102e101b45b38582e95b0469f5a0` },
      { id: 'st-designer', name: 'designer', quantity: 0, url: `/designer-minimalism/blog/d/4f9f17126b1743dfae94f3eaf5efabf4` },
      { id: 'st-mainstream', name: 'mainstream', quantity: 0, url: `/mainstream-modern/blog/d/e8418df47ab54c858e61c4dc9732556c` },
      { id: 'st-midcentury', name: 'midcentury', quantity: 0, url: `/mid-century-modern/blog/d/88e82e01349f40b6aaa8861d3614a99a` },
      { id: 'st-ethnic', name: 'ethnic', quantity: 0, url: `/modern-ethnic/blog/d/ed8665d665a14f5e869ee9949696b4f3` },
      { id: 'st-playful', name: 'playful', quantity: 0, url: `/playful-maximalism/blog/d/31ed8114ef7c40158d8ff923e0db708b` },
      { id: 'st-shabby', name: 'shabby', quantity: 0, url: `/shabby-chic/blog/d/68b079c22f9a442e` },
      { id: 'st-zen', name: 'zen', quantity: 0, url: `/zen-minimalism/blog/d/431087c1ab4341ebab490c90a981fbd0` },
    ]




  // @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
  //   if (isPlatformBrowser(this.platformId)) {
  //     if (
  //       window.pageYOffset >= this.headerBarElement.nativeElement.clientHeight
  //     ) {
  //       this.headerMessageElement.nativeElement.classList.add('sticky');
  //       this.headerUpperElement.nativeElement.classList.add('sticky');
  //     } else {
  //       this.headerMessageElement.nativeElement.classList.remove('sticky');
  //       this.headerUpperElement.nativeElement.classList.remove('sticky');
  //     }
  //   }
  // }
  // Order by descending property key
  keyDescOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return a.value > b.value ? -1 : (b.value > a.value ? 1 : 0);
  }

  ngOnInit() {
    if(window.location.host.includes('decoraitrade.com')) this.isDevelopment = true;
    this.tagService._getTags()
      .subscribe(
        (allTags: any) => {

          this.catTags = allTags.filter(t => t.type == 'category');
          this.allTags = allTags;
          this.setSelectedStyle()

          allTags.forEach(tag => {
            this.tagDectionary[tag.id] = tag;
          });
          this.cd.detectChanges();

          this.route.fragment.subscribe((fragment: string) => {
            if (fragment) {
              const value = fragment.split('/');
              const searchValue = value[2];
              const val = searchValue.toLowerCase().split('-').join(' ');
              this.headerSearchForm.patchValue({ search: val });
              value.splice(0, 3);
              let selectedFiltersNames: any = this.productService.getTagNameListForSelectedFilters();
              let colors = [],
                brands = [],
                materials = [],
                styles = [],
                rooms = [],
                moodclips = [],
                categories = [];
              value.forEach(f => {
                const strArr = f.split('-');
                const type = strArr[0];
                strArr.shift();
                if (type == 'color') {
                  colors = strArr.map(s => s);
                } else if (type == 'brand') {
                  brands = strArr.map(s => s);
                }
                if (type == 'material') {
                  materials = strArr.map(s => s);
                } else if (type == 'style') {
                  styles = strArr.map(s => s);
                }
                if (type == 'room') {
                  rooms = strArr.map(s => s);
                } else if (type == 'moodclip') {
                  moodclips = strArr.map(s => s);
                }
                if (type == 'category') {
                  if (strArr.length == 1) {
                    categories = [strArr[0]];
                  } else if (strArr.length > 1) {
                    categories = [strArr[1]];
                  }
                }
              });
              const colourFilter = this.allTags.filter(f => colors.includes(f.urlSlug)).filter(m => m.type == 'colour') || [];
              const brandFilter = this.allTags.filter(f => brands.includes(f.urlSlug)).filter(m => m.type == 'brand') || [];
              const materialFilter = this.allTags.filter(f => materials.includes(f.urlSlug)).filter(m => m.type == 'material') || [];
              const styleFilter = this.allTags.filter(f => styles.includes(f.urlSlug)).filter(m => m.type == 'style') || [];
              const roomFilter = this.allTags.filter(f => rooms.includes(f.urlSlug)).filter(m => m.type == 'room') || [];
              const moodclipFilter = this.allTags.filter(f => moodclips.includes(f.urlSlug)).filter(m => m.type == 'filter') || [];
              const categoryFilter = this.allTags.filter(f => categories.includes(f.urlSlug)).filter(m => m.type == 'category') || [];
              const filters = categoryFilter.concat(colourFilter).concat(brandFilter).concat(materialFilter).concat(styleFilter).concat(roomFilter).concat(moodclipFilter);
              selectedFiltersNames = filters.map(f => ({
                tagId: f.tagId,
                urlSlug: f.urlSlug,
                name: f.name,
                type: f.type,
                parent: f.parent || null
              }));

              this.handleProductListByFilters(selectedFiltersNames, true);
              this.productService.setTagNameListForSelectedFilters(selectedFiltersNames);

              if (this.selectedFilterType && this.selectedFilterType.searchQuery) {

              } else {
                if (filters.length) {
                  this.currentPage = 1;
                  this.getFilteredProducts(searchValue);
                } else {

                  const event = {
                    target: {
                      value: val
                    }
                  };
                  if (this.isComponentMount) {
                    this.onChange(event);
                  }
                }
              }

            }
            this.manageSubscription();
          });

        }
      );

    this.isBrowser = isPlatformBrowser(this.platformId);
    if (this.isBrowser) {
      this.constructDropDownMenus();
      this._ngxZendeskWebwidgetService = this.injector.get(
        ngxZendeskWebwidgetService
      );
    }

    let lastScrollTop = 0;
    $(window).scroll(function (event) {
      const st = $(this).scrollTop();
      if (st > lastScrollTop) {
        // âíèç
        $('.header-top').addClass('scrolling_down');
        $('.header-top').removeClass('scrolling_up');
      } else {
        // ââåðõ
        $('.header-top').addClass('scrolling_up');
        $('.header-top').removeClass('scrolling_down');
      }
      lastScrollTop = st;
    });

    $(document).ready(function () {

      $('.searchArea, .SearchNewIocn').click(function () {
        $('body').addClass('fullsearchbar');
      });

      $('.clear-btn, .clear-btn').click(function () {
        $('body').removeClass('fullsearchbar');
      });

      $('.fa-bars').click(function () {
        $('.new-menu').addClass('collapsed-menu');
      });
      $('.close-btn').click(function () {
        $('.new-menu').removeClass('collapsed-menu');
      });

      $('.dropColIn .dropHead').click(function () {
        $(this).toggleClass('active');
      });

      $('.fa-search').click(function () {
        $('.desktop-search').addClass('active');
      });

      $('.search_cancel').click(function () {
        $('.desktop-search').removeClass('active');
        $('.header-main').removeClass('search-block');
        $('body').removeClass('fullsearchbar');

      });

      $('input').click(function () {
        $('.search-stickty-for-mobile').addClass('fixedsearch');
      });

      $('.search_cancel').click(function () {
        $('.search-stickty-for-mobile').removeClass('fixedsearch');
      });

      $('input').click(function () {
        $('#main-container').addClass('main-container');
      });

      $('.search_cancel').click(function () {
        $('#main-container').removeClass('main-container');
      });

      $('.subdownarrow').click(function () {
        $(this).toggleClass('activearrow');
      });
     
      $('.SearchNewIocn').click(function () {
        $('.newdesign').addClass('ShowNewDesign');
        $('.desktoprow').addClass('removemargin');

      });
      $('.clear-btn').click(function () {
        $('.newdesign').removeClass('ShowNewDesign');
        $('.desktoprow').removeClass('removemargin');
      });

      $('.new-menu ul li').mouseenter(function () {
        $('.overlayformenu').show();
      });
      $('.new-menu ul li').mouseleave(function () {
        $('.overlayformenu').hide();
      });

      $(function(){
        $('.new-menu ul li').on("click", function () {
        $('.new-menu ul li').addClass("hideMenuOnClieck");
        setTimeout(RemoveClass, 1000);
        });
        function RemoveClass() {
        $('.new-menu ul li').removeClass("hideMenuOnClieck");
        }
        }); 

      
       

      


      // $('.clear-btn"').click(function () {
      //   $('.search-stickty-for-mobile').removeClass('fixedsearch');
      // });
      // $('.filter-open').click(function () {
      //   $('.mobile-select-filter').addClass('active');
      // });

      $(window).scroll(function () {
        if ($(document).scrollTop() > 100) {
          $('.header-main').addClass('show-search');
        } else {
          $('.header-main').removeClass('show-search');
        }
      });

      $(window).scroll(function () {
        if ($(document).scrollTop() > 200) {
          $('.header-main').addClass('sticky-header-desktop');
        } else {
          $('.header-main').removeClass('sticky-header-desktop');
        }
      });


      // $('.mobile-nav').click(function () {
      //     $(this).siblings().removeClass('mobile-nav-active');
      //     $(this).toggleClass('mobile-nav-active');
      // });

    });

    this.gameService.updateStyle.subscribe((flag) => {
      if (flag) {
        this.setSelectedStyle();
      }
    })

    for (const singleMenu of this.menus) {
      if (singleMenu.subMenus && singleMenu.subMenus.length == 0) this.getDynamicMenu(singleMenu);
    }

  }

  back(e) {
    e.stopPropagation();
    // e.preventDefault();
    this.currentModule = '';
  }

  hideSidebar() {
    $('.header-bottom').removeClass('collapsed-menu');
  }

  ngAfterViewInit() {
    setInterval(() => {
      const isCartPage = window.location.pathname;
      const cartBtn = this.cartButton.nativeElement;
      if (isCartPage.includes('/purchase/cart-new')) {
        if (cartBtn) {
          cartBtn.classList.add('cart-disabled');
          const TOGGOLEBUTTON: any = this.cartDetailsHolder.nativeElement;
          TOGGOLEBUTTON.classList.remove('cart-details-popup-show');
        }
      }
      else {
        if (cartBtn) {
          cartBtn.classList.remove('cart-disabled');
        }
      }
    }, 3000);
    if (window.localStorage.getItem('removedForcefully') == 'true') {
      this.removedForcefully = true;
    }
    if (this.isBrowser) {
      // this.onSearchChange();
      this.route.queryParams.subscribe((params) => {
        if (params['prefix']) {
          // this.searchText = params['prefix'].replace('+', ' ');
          this.searchField.setValue(params['prefix'].replace('+', ' '));
          this.cd.detectChanges();
        }
      });
      this.errorService.getErrorSubscription().subscribe(
        (err: DisplayError) => {
          this.displayError(err);
          this.cd.detectChanges();
        },
        (subError) => { }
      );
      this.errorService.getBusySubscription().subscribe(
        (busy: number) => {
          this.isBusy = busy > 0;
          this.cd.detectChanges();
        },
        (subError) => { }
      );
      this.errorService.getCartSubscription().subscribe(
        (cartSize: number) => {
          this.updateCartSize();
          this.cd.detectChanges();
        },
        (subError) => { }
      );
      this.errorService
        .getLoginSubscription()
        .subscribe((loggedIn: boolean) => {
          if (loggedIn) {
            this.profileService.getCachedOwnProfile().subscribe((result) => {
              this.myProfile = result.data;
              this.cd.detectChanges();
            });
          }
          this.cd.detectChanges();
        });

      this.errorService.getModuleSubscription().subscribe(
        (currentModule: string) => {
          this.currentModule = currentModule;
          if (this.currentModule === 'products') {
            this.searchIndex = 'products';
          } else if (this.currentModule === 'personrooms') {
            this.searchIndex = 'personrooms';
          } else if (this.currentModule === 'blog') {
            this.searchIndex = 'posts';
          }
          this.cd.detectChanges();
        },
        (subError) => { }
      );
      this.updateCartSize();
      this.cookieConsent = this.storageService.getConsentCookie();
      this.errorService.reportLogin(this.profileService.isLoggedIn());
      this.router.events.subscribe((evt) => {
        // if (evt instanceof NavigationStart && evt['url']) {
        //   window.scrollTo(0, 0);
        // }
      });
      if (this.isLoggedIn() && this.myProfile) {
        this._ngxZendeskWebwidgetService.identify({
          name: this.myProfile.name,
          email: this.myProfile.username,
        });
      }
      this._ngxZendeskWebwidgetService.setSettings({
        webWidget: {
          color: { theme: '#ebd55c' },
        },
      });
    }

    this.productService.getBrandWiseProducts().subscribe((res: any[]) => {
      if (res) {
        const brandMenuIndex = this.menus.findIndex((item) => item.name == 'Brands');
        if (brandMenuIndex >= 0) {
          for (let index = 0; index < this.menus[brandMenuIndex]['subMenus'].length; index++) {
            const brand = this.menus[brandMenuIndex]['subMenus'][index];
          }
          this.cd.detectChanges();
        }
      }
    })
  }

  agreeCookie(answer: string) {
    if (answer === 'yes') {
      this.storageService.allowPersonalData();
    } else if (answer === 'no') {
      this.storageService.disallowPersonalData();
    } else {
      this.removedForcefully = true;
      window.localStorage.setItem('removedForcefully', 'true');
    }
    this.cookieConsent = answer;
  }

  headerNavigate(option: string) {
    this.showProfileMenu = false;
    switch (option) {
      case 'cart':
        this.navigationService.navigateToCart();
        break;
      case 'cart-new':
        this.navigationService.navigateToNewCart();
        break;
      case 'profile-edit':
        this.navigationService.navigateToProfileEdit();
        break;
      case 'orders-list-new':
        this.navigationService.navigateToMyOrdersList();
        break;
      case 'orders-list':
        this.navigationService.navigateToOrdersList();
        break;
      case 'payment-methods':
        this.navigationService.navigateToPaymentMethods();
        break;
      case 'favourites':
        this.navigationService.navigateToFavourites();
        break;
      case 'change-password':
        this.navigationService.navigateToChangePassword();
        break;
      case 'product-request':
        this.navigationService.navigateToProductRequest();
        break;
      case 'store':
        this.productService.clearSearchParameters();
        // this.goToProductList();
        // this.navigationService.navigateGeneralStore({categoryId: null});
        // if (this.storageService.getLastVerdict()) {
        //     this.navigationService.navigatePersonalisedStore(null);
        // } else {
        // }
        break;
      case 'moodrooms':
        this.personroomService.clearSearchParameters();
        this.navigationService.navigateGeneralMoodroomList();
        this.currentModule = 'personrooms';
        // if (this.storageService.getLastVerdict()) {
        //     this.navigationService.navigatePersonalisedMoodrooms(null);
        // } else {
        //     this.navigationService.navigateGeneralMoodroomList();
        // }

        break;
      case 'blog':
        this.postService.clearSearchParameters();
        this.navigationService.navigateGeneralBlog();
        // if (this.storageService.getLastVerdict()) {
        //     this.navigationService.navigatePersonalisedBlog(null);
        // } else {
        // }

        break;
      case 'start-game':
        this.navigationService.navigateStartGame();
        break;
      case 'find-about-cookies':
        this.navigationService.navigateInfoPage('privacy-policy');
        break;

      default:
    }
    this.hideSidebar();
  }
  getSafeJsonLd(key: string) {
    const jsonld = {
      navigation: {
        '@context': 'http://schema.org',
        '@graph': [
          {
            '@type': 'SiteNavigationElement',
            position: 1,
            '@id': '#main-menu',
            name: 'Store',
            description:
              'Browse and purchase Furniture, Lighting, Homeart and other interior design products',
            url:
              environment.baseHref + this.navigationService.urlGeneralStore(),
          },
          {
            '@type': 'SiteNavigationElement',
            position: 2,
            '@id': '#main-menu',
            name: 'Moodmaestro Game',
            description:
              'Our proprietary game to discover your taste and thereby personalise your interior design experience',
            url: environment.baseHref + this.navigationService.urlStartGame(),
          },
          {
            '@type': 'SiteNavigationElement',
            position: 3,
            '@id': '#main-menu',
            name: 'Moodrooms',
            description:
              'Moodrooms are tailor-made collections of products which match your style and complement each other',
            url:
              environment.baseHref +
              this.navigationService.urlGeneralMoodroomList(),
          },
          {
            '@type': 'SiteNavigationElement',
            position: 4,
            '@id': '#main-menu',
            name: 'Blog',
            description:
              'Creative blog about interior design and styles contributed by our experts',
            url: environment.baseHref + this.navigationService.urlGeneralBlog(),
          },
        ],
      },
      website: {
        '@context': 'http://schema.org',
        '@type': 'Website',
        name: 'Moodmaestro - interior design to your taste',
        description:
          'Moodmaestro is your digital interior designer with which you style your home, your way',
        publisher: 'Moodmaestro Limited',
        url: 'https://www.moodmaestro.co.uk',
        potentialAction: {
          '@type': 'SearchAction',
          target:
            'https://www.moodmaestro.co.uk/products/t?prefix={search_term}',
          'query-input': 'required name=search_term',
        },
      },
    };

    return jsonld[key];
  }

  onChange(event) {

    if (event.code === 'Enter') {
      this.isHeaderShow = false;
      this.navService.setShowNav(false);
      this.suggestions = [];
      this.suggestedProducts = [];
    } else {
      this.isHeaderShow = true;
      this.navService.setShowNav(true);
      this.isSuggestionLoader = true;
      const term = event.target.value;
      if (this.sidebarInfoComponent) {
        this.sidebarInfoComponent.clearAll();
      }
      this.currentPage = 1;

      if (this.router.url.includes('#/search')) {
        const isSearch = this.router.url.split('#/search') || [];
        if (isSearch.length > 1) {
          this.router.navigateByUrl(`${isSearch[0]}#/search/${this.createUrlSlug(term)}`);
        } else {
          this.router.navigateByUrl(`${this.router.url}#/search/${this.createUrlSlug(term)}`);
        }
      } else {
        this.router.navigateByUrl(`${this.router.url}#/search/${this.createUrlSlug(term)}`);
      }
      this.searchService.autocomplete(this.searchIndex, term, null, this.currentPage, 0, 0, null).subscribe(result => {
        this.showSearchResult = true;
        this.isSuggestionLoader = false;
        this.totalProducts = result.totalCount;
        this.suggestions = result.suggestions ? result.suggestions : [];
        this.suggestedProducts = result.suggestedProducts ? result.suggestedProducts : [];
        for (const product of this.suggestedProducts) {
          const findValue = this.findPropertyValue(product.variant, 'inventoryStatus');
          product['priceShow'] = true;
          if (findValue === 'order_by_email') {
            product['priceShow'] = false;
          }
        }
        if (result.suggestedProducts && result.suggestedProducts.length) {
          this.moreProductsRemaining = true;
          this.modifyTagList(result.tags);
        } else {
          this.isSuggestionLoader = false;
          this.moreProductsRemaining = false;
          this.cd.detectChanges();
        }

        this.isComponentMount = false;


        this.cd.detectChanges();
      }, (error) => {
        console.log('error', error.error.message);
      });
    }
  }

  // currently this fun is not using in this component before we used
  // onSearchChange() {
  //   this.currentPage = 1;
  //   this.autoCompleteRequest = this.searchField.valueChanges
  //     .pipe(
  //       debounceTime(400),
  //       switchMap((term: any) => {
  //         if (this.router.url.includes('#/search')) {
  //           const isSearch = this.router.url.split('#/search') || [];
  //           if (isSearch.length > 1) {
  //             this.router.navigateByUrl(`${isSearch[0]}#/search/${this.createUrlSlug(term)}`);
  //           } else {
  //             this.router.navigateByUrl(`${this.router.url}#/search/${this.createUrlSlug(term)}`);
  //           }
  //         } else {
  //           if (this.router.url == '/') { // For dashboard
  //             this.router.navigateByUrl(`${this.router.url}/df#/search/${this.createUrlSlug(term)}`);
  //           } else {
  //             this.router.navigateByUrl(`${this.router.url}#/search/${this.createUrlSlug(term)}`);
  //           }
  //         }
  //         return this.searchService.autocomplete(this.searchIndex, term, null, this.currentPage, 0, 0, null);
  //       }
  //       )
  //     )
  //     .subscribe(
  //       (result) => {
  //         this.showSearchResult = true;
  //         this.isSuggestionLoader = false;
  //         // this.searchItemList = result;
  //         this.navService.setShowNav(true);
  //         this.suggestions = result.suggestions ? result.suggestions : [];
  //         this.suggestedProducts = result.suggestedProducts ? result.suggestedProducts : [];
  //         if (result.suggestedProducts && result.suggestedProducts.length) {
  //           this.moreProductsRemaining = true;
  //           this.modifyTagList(result.tags);
  //         } else {
  //           // this.isSuggestionLoader = false
  //           this.moreProductsRemaining = false;
  //           this.cd.detectChanges();
  //         }
  //         // if (
  //         //   result &&
  //         //   result['data'] &&
  //         //   result['data'][0] &&
  //         //   result['data'][0].options
  //         // ) {
  //         //   this.searchItemList = result['data'][0].options.map((x) => x.text);
  //         //   this.searchItemList = this.searchItemList.filter(function (
  //         //     item,
  //         //     pos,
  //         //     list
  //         //   ) {
  //         //     return list.indexOf(item) === pos;
  //         //   });
  //         // } else {
  //         //   this.searchItemList = [];
  //         // }
  //         this.cd.detectChanges();
  //       },
  //       (err) => { }
  //     );
  // }

  createUrlSlug(url: string) {
    return url.toLowerCase().replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
  }

  onSuggestion(event) {
    this.headerSearchForm.patchValue({ search: event });
    this.currentPage = 1;
    this.isSuggestionLoader = true;
    this.searchService.autocomplete(this.searchIndex, event, this.selectedFilterType, this.currentPage, 0, 0, null).subscribe(result => {
      this.isSuggestionLoader = false;
      this.isHeaderShow = true;
      this.totalProducts = result.totalCount;
      this.suggestions = result.suggestions ? result.suggestions : [];
      this.suggestedProducts = result.suggestedProducts ? result.suggestedProducts : [];
      for (const product of this.suggestedProducts) {
        const findValue = this.findPropertyValue(product.variant, 'inventoryStatus');
        product['priceShow'] = true;
        if (findValue === 'order_by_email') {
          product['priceShow'] = false;
        }
      }
      if (result.suggestedProducts && result.suggestedProducts.length) {
        this.moreProductsRemaining = true;
        this.modifyTagList(result.tags);
      } else {
        this.isSuggestionLoader = false;
        this.moreProductsRemaining = false;
      }
      this.cd.detectChanges();
    });
  }

  moreProducts() {
    this.currentPage = this.currentPage + 1;
    this.loadMoreLoader = true;
    this.searchService.autocomplete(this.searchIndex, this.searchField.value, this.selectedFilterType, this.currentPage, 0, 0, null).subscribe(result => {
      this.loadMoreLoader = false;
      this.isHeaderShow = true;
      this.totalProducts = result.totalCount;
      if (result.suggestedProducts.length) {
        this.moreProductsRemaining = true;
        const record = [...this.suggestedProducts, ...result.suggestedProducts];
        this.suggestedProducts = record;
        for (const product of this.suggestedProducts) {
          const findValue = this.findPropertyValue(product.variant, 'inventoryStatus');
          product['priceShow'] = true;
          if (findValue === 'order_by_email') {
            product['priceShow'] = false;
          }
        }
        this.cd.detectChanges();
      } else {
        this.moreProductsRemaining = false;
        this.cd.detectChanges();
      }
    });
  }

  manageSubscription() {
    this.subscription = this.productService.filters.subscribe((filters: any) => {
      if (filters.val && filters.type == 'header') {
        this.handleFilterChangedSubscription(filters.val);
      }
    });

    this.filterNameListSubscription = this.productService.selectedTagNameList.subscribe((tagNameList: any) => {
      this.selectedFiltersNames = tagNameList;
    });

  }
  // This function will set filters in the sidebar, in the product list and hit API call as well
  handleProductListByFilters(selectedFiltersNames, subscribe?: boolean) {
    if (selectedFiltersNames && selectedFiltersNames.length) {
      const tags: any = {
        brand: {},
        category: {},
        room: {},
        colour: {},
        material: {},
        filter: {},
        style: {}
      };
      selectedFiltersNames.forEach((val) => {
        tags[val.type][val.urlSlug] = true;
      });
      this.selectedFiltersNames = selectedFiltersNames;

      this.cd.detectChanges();
      this.tags = tags;
      this.productService.setSelectedFilters(tags);
      if (subscribe) {
        this.selectedFilterType = {
          filter: {
            brandsUrlSlug: this.manipulateFiltersData(tags.brand),
            categoriesUrlSlug: this.manipulateFiltersData(tags.category),
            roomsUrlSlug: this.manipulateFiltersData(tags.room),
            colorsUrlSlug: this.manipulateFiltersData(tags.colour),
            materialsUrlSlug: this.manipulateFiltersData(tags.material),
            moodclipsUrlSlug: this.manipulateFiltersData(tags.filter),
            stylesUrlSlug: this.manipulateFiltersData(tags.style)
          }
        };
        this.selectedFilters = tags;
      }
    }
  }

  getFilteredProducts(searchValue) {
    this.currentPage = 1;
    this.filterProductsLoader = true;
    this.searchService
      .autocomplete(this.searchIndex, searchValue, this.selectedFilterType, this.currentPage, 0, 0, null)
      .subscribe((result: any) => {
        this.filterProductsLoader = false;
        this.navService.setShowNav(true);
        this.totalProducts = result.totalCount;
        this.suggestions = result.suggestions;
        this.suggestedProducts = result.suggestedProducts;
        for (const product of this.suggestedProducts) {
          const findValue = this.findPropertyValue(product.variant, 'inventoryStatus');
          product['priceShow'] = true;
          if (findValue === 'order_by_email') {
            product['priceShow'] = false;
          }
        }
        if (result.suggestedProducts && result.suggestedProducts.length) {
          this.moreProductsRemaining = true;
          this.modifyTagList(result.tags);
          this.cd.detectChanges();
        } else {
          this.moreProductsRemaining = false;
          this.cd.detectChanges();
        }
      });
    this.cd.detectChanges();
  }

  manipulateFiltersData(val: any) {
    const a = [];
    Object.keys(val).forEach((key: any) => {
      if (val[key]) {
        a.push(key);
      }
    });
    return a;
  }

  modifyTagList(tags: Array<any>) {
    this.tags = {};
    const tagsForSidebar = {};
    const a = this.productService.getTagNameListForSelectedFilters();

    // For product list tags
    tags.forEach(tag => {
      if (a.findIndex(val => val.tagId == tag.tagId) == -1) {
        if (this.tags[tag.type]) {
          this.tags[tag.type].push(tag);
        } else {
          this.tags[tag.type] = [tag];
        }
      }
    });

    // For sidebar tags
    tags.forEach(tag => {
      if (tagsForSidebar[tag.type]) {
        tagsForSidebar[tag.type].push(tag);
      } else {
        tagsForSidebar[tag.type] = [tag];
      }
    });
    const colourGroup = {};
    if (tagsForSidebar['colour']) {
      tagsForSidebar['colour'].forEach(element => {
        if (colourGroup[element.parent]) {
          colourGroup[element.parent].push(element);
        } else {
          colourGroup[element.parent] = [];
          colourGroup[element.parent].push(element);
        }
        if (element.parentName) {
          this.parentNames[element.parent] = element.parentName;
        }
      });
      tagsForSidebar['colour'] = Object.keys(colourGroup).map(key => {
        return {
          group: key == 'undefined' ? 'other' : key,
          colours: colourGroup[key]
        };
      });
    }
    const moodclipGroup = {};
    if (tagsForSidebar['filter']) {
      tagsForSidebar['filter'].forEach(element => {
        if (moodclipGroup[element.parent]) {
          moodclipGroup[element.parent].push(element);
        } else {
          moodclipGroup[element.parent] = [];
          moodclipGroup[element.parent].push(element);
        }
        if (element.parentName) {
          this.parentNames[element.parent] = element.parentName;
        }
      });
      tagsForSidebar['filter'] = Object.keys(moodclipGroup).map(key => {
        return {
          group: key == 'undefined' ? 'other' : key,
          filters: moodclipGroup[key]
        };
      });
    }
    const materialGroup = {};
    if (tagsForSidebar['material']) {
      tagsForSidebar['material'].forEach(element => {
        if (materialGroup[element.parent]) {
          materialGroup[element.parent].push(element);
        } else {
          materialGroup[element.parent] = [];
          materialGroup[element.parent].push(element);
        }
        if (element.parentName) {
          this.parentNames[element.parent] = element.parentName;
        }
      });
      tagsForSidebar['material'] = Object.keys(materialGroup).map(key => {
        return {
          group: key == 'undefined' ? 'other' : key,
          materials: materialGroup[key]
        };
      });
    }

    this.productService.setParentNames(this.parentNames);
    this.productService.setTags(tagsForSidebar);
  }

  onFilter(tag: string) {
    this.selectedTag = tag;
    this.navService.setShowNav(true);
  }

  onSidebarClose(close: boolean) {
    if (close) {
      this.selectedTag = undefined;
    }
  }

  onDeleteFilter(tagInfo) {
    if (tagInfo) {
      const pluralName = this.productService.getPluralNameOfCategoryFilter(tagInfo.type);
      const filterName = this.selectedFilterType.filter[pluralName];
      filterName.splice(filterName.findIndex(a => a == tagInfo.urlSlug), 1);

      // To update in the sidebar
      const selectedFilters = this.productService.getSelectedFilters();
      if (selectedFilters) {
        selectedFilters[tagInfo.type][tagInfo.urlSlug] = false;
      }
      this.productService.setSelectedFilters(selectedFilters);

      // To update chips
      const selectedFiltersNames: any = this.productService.getTagNameListForSelectedFilters();
      selectedFiltersNames.splice(selectedFiltersNames.findIndex(tag => tag.tagId == tagInfo.tagId), 1);
      this.productService.setTagNameListForSelectedFilters(selectedFiltersNames);
      this.handleFilterChangedSubscription(selectedFilters);

    }
  }

  handleFilterChangedSubscription(filters: any) {
    this.selectedFilterType = {
      filter: {
        brandsUrlSlug: this.manipulateFiltersData(filters.brand),
        categoriesUrlSlug: this.manipulateFiltersData(filters.category),
        roomsUrlSlug: this.manipulateFiltersData(filters.room),
        colorsUrlSlug: this.manipulateFiltersData(filters.colour),
        materialsUrlSlug: this.manipulateFiltersData(filters.material),
        moodclipsUrlSlug: this.manipulateFiltersData(filters.filter),
        stylesUrlSlug: this.manipulateFiltersData(filters.style)
      }
    };
    const endPoints: Array<string> = [];
    let endPoint = '';
    if (this.selectedFilterType.filter.categoriesUrlSlug.length) {
      endPoint = endPoint.concat('category');
      endPoint = endPoint.concat('-');
      const category = this.catTags.find(c => c.urlSlug == this.selectedFilterType.filter.categoriesUrlSlug[0]);
      if (category.parent) {
        endPoint = endPoint.concat(this.tagDectionary[category.parent].urlSlug);
        endPoint = endPoint.concat('-');
      }
      this.selectedFilterType.filter.categoriesUrlSlug.map((category: string, i: number) => {
        endPoint = endPoint.concat(category);
        if (i < this.selectedFilterType.filter.categoriesUrlSlug.length - 1) {
          endPoint = endPoint.concat('-');
        }
      });
      endPoints.push(endPoint);
    }
    Object.keys(this.selectedFilterType.filter).forEach(filter => {
      let endPoint = '';
      switch (filter) {
        case 'categoriesUrlSlug':

          break;
        case 'brandsUrlSlug':

          if (this.selectedFilterType.filter.brandsUrlSlug.length) {
            endPoint = endPoint.concat('brand');
            endPoint = endPoint.concat('-');

            this.selectedFilterType.filter.brandsUrlSlug.map((brand: string, i: number) => {
              endPoint = endPoint.concat(brand);
              if (i < this.selectedFilterType.filter.brandsUrlSlug.length - 1) {
                endPoint = endPoint.concat('-');
              }
            });
            endPoints.push(endPoint);
          }
          break;
        case 'roomsUrlSlug':

          if (this.selectedFilterType.filter.roomsUrlSlug.length) {
            endPoint = endPoint.concat('room');
            endPoint = endPoint.concat('-');

            this.selectedFilterType.filter.roomsUrlSlug.map((room: string, i: number) => {
              endPoint = endPoint.concat(room);
              if (i < this.selectedFilterType.filter.roomsUrlSlug.length - 1) {
                endPoint = endPoint.concat('-');
              }
            });
            endPoints.push(endPoint);
          }
          break;
        case 'colorsUrlSlug':

          if (this.selectedFilterType.filter.colorsUrlSlug.length) {
            endPoint = endPoint.concat('color');
            endPoint = endPoint.concat('-');
            this.selectedFilterType.filter.colorsUrlSlug.map((colour: string, i: number) => {
              endPoint = endPoint.concat(colour);
              if (i < this.selectedFilterType.filter.colorsUrlSlug.length - 1) {
                endPoint = endPoint.concat('-');
              }
            });
            endPoints.push(endPoint);
          }
          break;
        case 'materialsUrlSlug':

          if (this.selectedFilterType.filter.materialsUrlSlug.length) {
            endPoint = endPoint.concat('material');
            endPoint = endPoint.concat('-');

            this.selectedFilterType.filter.materialsUrlSlug.map((material: string, i: number) => {
              endPoint = endPoint.concat(material);
              if (i < this.selectedFilterType.filter.materialsUrlSlug.length - 1) {
                endPoint = endPoint.concat('-');
              }
            });
            endPoints.push(endPoint);
          }
          break;
        case 'moodclipsUrlSlug':

          if (this.selectedFilterType.filter.moodclipsUrlSlug.length) {
            endPoint = endPoint.concat('moodclip');
            endPoint = endPoint.concat('-');

            this.selectedFilterType.filter.moodclipsUrlSlug.map((moodclip: string, i: number) => {
              endPoint = endPoint.concat(moodclip);
              if (i < this.selectedFilterType.filter.moodclipsUrlSlug.length - 1) {
                endPoint = endPoint.concat('-');
              }
            });
            endPoints.push(endPoint);
          }
          break;

        case 'stylesUrlSlug':

          if (this.selectedFilterType.filter.stylesUrlSlug.length) {
            endPoint = endPoint.concat('style');
            endPoint = endPoint.concat('-');

            this.selectedFilterType.filter.stylesUrlSlug.map((style: string, i: number) => {
              endPoint = endPoint.concat(style);
              if (i < this.selectedFilterType.filter.stylesUrlSlug.length - 1) {
                endPoint = endPoint.concat('-');
              }
            });
            endPoints.push(endPoint);
          }
          break;
      }
    });
    if (this.router.url.includes('#/search')) {
      const isSearch = this.router.url.split('#/search') || [];
      if (isSearch.length > 1) {
        this.isComponentMount = true;
        this.router.navigateByUrl(`${isSearch[0]}#/search/${this.createUrlSlug(this.searchField.value)}/${endPoints.join('/')}`);
      } else {
        this.router.navigate([`${this.router.url}/#/search/`, ...endPoints]);
      }
    } else {
      console.log('else');
    }
  }

  updateCartSize() {
    this.purchaseService.getCachedCart().subscribe({
      next: (result: CartResponse) => {
        // this.cartSize = result.data.length;
        let size = 0;
        if (result.data && result.data.length) {
          result.data.forEach(item => {
            size += item.quantity;
          });
        }
        this.cartSize = size;
        this.cd.detectChanges();
      },
      error: (err) => { },
    });
  }

  setAllowPersonalCookies(answer: boolean) {
    if (answer) {
      this.storageService.allowPersonalData();
    } else {
      this.storageService.disallowPersonalData();
    }
  }

  loginRedirect() {
    this.auth.gotoLogin();
  }

  isLoggedIn(): boolean {
    return this.auth.isLoggedIn();
  }

  logout() {
    this.profileService.signOut();
  }

  gotoUrl(url: string) {
    this.router.navigate([url]);
  }

  deFocusSearch() {
    setTimeout(() => {
      // this.searchItemList = [];
      this.showSearchResult = false;
      this.cd.detectChanges();
    }, 100);
  }

  focusIn() {
    this.showSearchResult = true;
    this.cd.detectChanges();
  }

  // searchClicked(){
  //     this.performSearch(this.searchField)
  // }
  // searchMobileClicked() {
  //     this.performSearch(this.searchFieldMobile)

  // }
  // searchSetClicked(txt){

  // }

  // performSearch(field: FormControl, term?: any) {
  //   if (!term && !term.suggestion) {
  //     term = field.value;
  //   }

  //   if (term.suggestion.length < 1) {
  //     return;
  //   }
  //   this.searchItemList = [];
  //   // this.productService.updateProductSearch('prefix', term, true);
  //   // this.errorService.reportSearch(term);
  //   if (this.autoCompleteRequest) {
  //     this.autoCompleteRequest.unsubscribe();
  //     setTimeout(() => {
  //       this.onSearchChange();
  //     }, 200);
  //   }

  //   if (this.searchIndex === 'products') {
  //     if (this.router.url !== this.navigationService.urlGeneralStore()) {
  //       this.navigationService.navigateGeneralStore(<Params>{ prefix: term });
  //     } else {
  //       this.router.navigate([], {
  //         queryParams: <Params>{ prefix: term },
  //         queryParamsHandling: 'merge',
  //       });
  //     }
  //   } else if (this.searchIndex === 'personrooms') {
  //     if (
  //       this.router.url !== this.navigationService.urlGeneralMoodroomList('t')
  //     ) {
  //       this.navigationService.navigateGeneralMoodroomList();
  //     } else {
  //       this.router.navigate([], {
  //         queryParams: <Params>{ prefix: term },
  //         queryParamsHandling: 'merge',
  //       });
  //     }
  //   } else if (this.searchIndex === 'posts') {
  //     if (this.router.url !== this.navigationService.urlGeneralBlog()) {
  //       this.navigationService.navigateGeneralBlog();
  //     } else {
  //       this.router.navigate([], {
  //         queryParams: <Params>{ prefix: term },
  //         queryParamsHandling: 'merge',
  //       });
  //     }
  //   }
  // }

  performSearch(field: FormControl, term?: any) {
    // if (!term && !term.suggestion) {
    //   term = field.value;
    // }

    const queryParams: any = {};
    queryParams.searchQuery = field.value;
    // queryParams.filters = JSON.stringify({
    //   'searchQuery': field.value
    // });

    const navigationExtras: NavigationExtras = {
      queryParams
    };

    this.headerSearchForm.patchValue({ search: field.value });
    this.router.navigate(['products'], navigationExtras);
    // this.clearSearch();
  }

  redirectToListPage(val: string) {
    if (this.headerSearchForm.value.search) {
      this.performSearch(null, { suggestion: this.headerSearchForm.value.search });
    }
  }

  redirectToDetailPage(product) {
    if (product.variant && product.product) {
      const val = _.clone(product.product);
      val['productId'] = val.id;
      const selectedVariant = product.product.variants && product.product.variants[0];
      this.navigationService.navigateNewProductDetail(val, selectedVariant);
    }
  }

  displayError = (err: DisplayError) => {
    if (typeof err.message !== 'string') {
      err.message = 'Server error';
    }
    this.headerMessage = err;
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    // let timeoutId = setTimeout(() => {
    //     this.headerMessage = null;
    //     timeoutId = null;
    // }, 8000);
  }

  constructDropDownMenus() {
    const headerMenus = this.storageService.getHeaderMenus();

    if (headerMenus) {
      this.storeMenu = headerMenus['store'];
      this.moodroomMenu = headerMenus['moodroom'];
    } else {
      forkJoin([
        this.tagService.cachedTagsList('room').pipe(first()),
        this.tagService.cachedTagsList('category').pipe(first()),
        this.tagService.cachedTagsList('style').pipe(first()),
      ]).subscribe((result_arr) => {
        this.roomTags = result_arr[0];
        this.categoryTags = result_arr[1];
        this.styleTags = result_arr[2];
        this.storeMenu = HeaderMenus.store;
        this.moodroomMenu = HeaderMenus.moodroom;
        const populateHeaderLink = (
          x: HeaderLink,
          type: 'store' | 'moodrooms'
        ): HeaderLink => {
          if (!x.type) {
            return x;
          }
          switch (x.type) {
            case 'room':
              if (!x.displayName) {
                x.displayName = this.roomTags[x.id].name;
              }
              if (!x.url) {
                if (type === 'store') {
                  x.url = this.navigationService.getStoreUrl(
                    this.roomTags[x.id],
                    'room'
                  );
                }
                if (type === 'moodrooms') {
                  x.url = this.navigationService.getMoodroomsListUrl(
                    this.roomTags[x.id],
                    'room'
                  );
                }
              }
              x.mainThumbnail = this.roomTags[x.id].mainThumbnail;
              // x.urlSlug = this.tagDectionary[x.id] && this.tagDectionary[x.id].urlSlug;

              break;
            case 'cat':
              if (!x.displayName) {
                x.displayName = this.categoryTags[x.id]
                  ? this.categoryTags[x.id].name
                  : x.id;
              }
              if (!x.url) {
                x.url = this.navigationService.getStoreUrl(
                  this.categoryTags[x.id],
                  'cat'
                );
              }
              x.mainThumbnail = this.categoryTags[x.id].mainThumbnail;
              // x.urlSlug = this.tagDectionary[x.id] && this.tagDectionary[x.id].urlSlug;
              break;
            case 'style':
              if (!x.displayName) {
                x.displayName = this.styleTags[x.id].name;
              }
              if (!x.url) {
                if (type === 'store') {
                  x.url = this.navigationService.getStoreUrl(
                    this.styleTags[x.id],
                    'style'
                  );
                }
                if (type === 'moodrooms') {
                  x.url = this.navigationService.getMoodroomsListUrl(
                    this.styleTags[x.id],
                    'style'
                  );
                }
                x.mainThumbnail = this.styleTags[x.id].mainThumbnail;
                // x.urlSlug = this.tagDectionary[x.id] && this.tagDectionary[x.id].urlSlug;
              }

              break;
            default:
              break;
          }
          return x;
        };
        this.storeMenu.forEach((headerColumn) => {
          headerColumn.forEach((headerGroup) => {
            headerGroup.title = populateHeaderLink(headerGroup.title, 'store');
            headerGroup.items.forEach((item) => {
              item = populateHeaderLink(item, 'store');
            });
          });
        });
        this.moodroomMenu.forEach((headerColumn) => {
          headerColumn.forEach((headerGroup) => {
            headerGroup.title = populateHeaderLink(
              headerGroup.title,
              'moodrooms'
            );
            headerGroup.items.forEach((item) => {
              item = populateHeaderLink(item, 'moodrooms');
            });
          });
        });

        this.storageService.setHeaderMenus({
          store: this.storeMenu,
          moodroom: this.moodroomMenu,
        });
        this.cd.detectChanges();
      });
    }
  }

  openStore(type: 'room' | 'style' | 'brand' | 'cat', tag: Tag) {
    return this.navigationService.getStoreUrl(tag, type);
  }

  openMoodrooms(type: 'room' | 'style', tag: Tag) {
    return this.navigationService.getMoodroomsListUrl(tag, type);
  }

  closeSubMenu() {
    this.storeDrop.nativeElement.classList.remove('open');
    this.moodroomDrop.nativeElement.classList.remove('open');
    setTimeout(() => {
      this.storeDrop.nativeElement.classList.add('open');
      this.moodroomDrop.nativeElement.classList.add('open');
    }, 500);
  }

  enterChat() {
    const chatInput = <ChatInput>{
      botName: environment.chatBot.botName,
      botAlias: environment.chatBot.botAlias,
      userId: this.chatSessionId,
      inputText: this.chatInput,
    };
    this.chatHistory.push(<Chat>{
      sent: true,
      message: `<span class="chatbot-timestamp">Me&nbsp;(${new Date()
        .toISOString()
        .substring(11, 19)})</span><br>${this.chatInput}`,
    });
    this.chatInput = '';
    this.cd.detectChanges();

    this.chatHistoryElement.nativeElement.scrollTop = this.chatHistoryElement.nativeElement.scrollHeight;
    this.contentService.chatBot(chatInput).subscribe(
      (result) => {
        result.data.message = `<span class="chatbot-timestamp">Moodmaestro&nbsp;(${new Date()
          .toISOString()
          .substring(11, 19)})</span><br>${result.data.message}`;
        this.chatHistory.push(result.data);
        this.cd.detectChanges();
        this.chatHistoryElement.nativeElement.scrollTop = this.chatHistoryElement.nativeElement.scrollHeight;
      },
      (err) => {
        if (err.error && err.error.message) {
          this.errorService.reportError(
            new DisplayError('error', err.error.message, '', this.cd)
          );
        } else {
          this.errorService.reportError(
            new DisplayError('error', 'Server error', '', this.cd)
          );
        }
      }
    );
  }

  closeChat(evt: MouseEvent) {
    this.chatHistory = [];
    this.showChatBot = 'hide';
    this.chatSessionId = null;
    evt.stopPropagation();
    this.cd.detectChanges();
  }

  toggleMinimiseChatBot() {
    if (this.showChatBot === 'show') {
      this.showChatBot = 'minimise';
    } else if (this.showChatBot === 'minimise') {
      this.showChatBot = 'show';
    }
    this.cd.detectChanges();
  }

  initiateChatBot() {
    if (this.showChatBot !== 'hide' || this.chatSessionId) {
      return;
    }
    this.chatSessionId = 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(
      /x/g,
      function (c) {
        return Math.floor(Math.random() * 16).toString(16);
      }
    );
    this.showChatBot = 'show';
    this.cd.detectChanges();
  }

  processButton(buttonValue: string) {
    if (/^\(Input\)/g.test(buttonValue)) {
      this.chatInput = buttonValue.substring(7, buttonValue.length);
      this.enterChat();
    } else if (/^\(Link\)/g.test(buttonValue)) {
      const url = buttonValue.substring(6, buttonValue.length);
      this.router.navigateByUrl(url);
    }
  }

  goToProductList(val?: any, parentCategory?: any) {
    this.currentModule = 'products';
    $('.header-bottom').removeClass('collapsed-menu');
    // this.productService.clearFilters();
    let endPoint;
    if (val && parentCategory && parentCategory.id) {
      if (val.type != parentCategory.type) {
        endPoint = `/${parentCategory.type}-${this.tagDectionary[parentCategory.id].urlSlug}`;
      }
    }
    if (val && val.type == 'cat') {
      if (this.tagDectionary[val.id] && this.tagDectionary[val.id].parent) {
        this.router.navigateByUrl(`/products/category-${this.tagDectionary[this.tagDectionary[val.id].parent].urlSlug}-${this.tagDectionary[val.id].urlSlug}${endPoint ? endPoint : ''}`);
      } else {
        this.router.navigateByUrl(`/products/category-${this.tagDectionary[val.id].urlSlug}${endPoint ? endPoint : ''}`);
      }

      // this.router.navigate(['products'], { queryParams: { tagId: val.id, tagType: val.type } });
    } else
      if (val && val.type) {
        if (this.tagDectionary[val.id].parent) {
          this.router.navigateByUrl(`/products/${val.type}-${this.tagDectionary[this.tagDectionary[val.id].parent].urlSlug}-${this.tagDectionary[val.id].urlSlug}${endPoint ? endPoint : ''}`);
        } else {
          this.router.navigateByUrl(`/products/${val.type}-${this.tagDectionary[val.id].urlSlug}${endPoint ? endPoint : ''}`);
        }
        this.router.navigate([`/products`, `${val.type}-${this.tagDectionary[val.id].urlSlug}${endPoint ? endPoint : ''}`]);
      } else if (val && val.url) {
        this.router.navigate([val.url]);
      } else {
        this.router.navigate(['products']);
      }
  }

  redirectToBrandORCategoryList(e: any, val, parentCategory?: any) {
    if (e) {
      e.stopPropagation();
    }
    this.goToProductList(val, parentCategory);
    this.hideSidebar();
  }

  goToParentCategoryList(e: any, val, parentCategory?: any) {
    if (e) {
      e.stopPropagation();
    }
    if (val && val.title.type) {
      $('.header-bottom').removeClass('collapsed-menu');
      // this.router.navigate(['products'], { queryParams: { tagId: val.title.id, tagType: val.title.type } })
      this.goToProductList(val.title, parentCategory);
    } else if (val && val.title.displayName == 'More' || val.title.displayName == 'Styles') {
      this.router.navigate(['products']);
    } else {
    }
  }

  clearSearch() {
    this.headerSearchForm.reset();
    this.suggestedProducts = [];
    this.suggestions = [];
    this.selectedFiltersNames = [];
    this.isHeaderShow = false;
    const isSearch = this.router.url.split('#/search') || [];
    if (isSearch[0] == '/df') {
      this.router.navigateByUrl(`/`);
    } else {
      this.router.navigateByUrl(`${isSearch[0]}`);
    }
  }

  toggleClass(menu: HTMLElement) {
    menu.classList.toggle('active-nav');
  }
  toggleSubMenu(menu: HTMLElement) {
    menu.classList.toggle('mobile-nav-active');
  }
  // togglesubClass(menu: HTMLElement) {
  //   menu.classList.toggle('active-nav-sub');
  // }

  addClass(filter: HTMLElement) {
    filter.classList.add('filter-open');
  }

  removeClass(filter: HTMLElement) {
    filter.classList.remove('filter-open');
  }

  setSelectedStyle() {
    this.gameService.getStyleSelectorInfo().subscribe((res) => {
      const style1 = this.allTags.find((item) => item.id == res.style1);
      style1['styleScore'] = res.style1Score
      const style2 = this.allTags.find((item) => item.id == res.style2);
      style2['styleScore'] = res.style2Score
      this.selectedStyle = [style1, style2];
      this.cd.detectChanges();
    })
  }

  findPropertyValue(obj, key) {
    if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        const result = this.findPropertyValue(obj[i], key);
        if (result !== undefined) {
          return result;
        }
      }
    } else if (typeof obj === 'object') {
      for (const prop in obj) {
        if (prop === key) {
          return obj[prop];
        } else if (typeof obj[prop] === 'object') {
          const result = this.findPropertyValue(obj[prop], key);
          if (result !== undefined) {
            return result;
          }
        }
      }
    }
  }

  getDynamicMenu(menu: any) {
    this.contentService.getDynamicMenu(menu.apiname).subscribe((res:any[]) => {
      if (res) {
        
        if(menu.apiname == 'BLOGS'){
          for (const sm of res[0].subMenus) {
            const blogIndex = this.blogs.findIndex(item=>item.id == sm.tagId)
            if(blogIndex != -1){
              sm['url'] = this.blogs[blogIndex].url;
            }
          }
        }
        
        menu.subMenus = res.sort((a, b) => {
          let nameA = a.name.toLowerCase(); // Convert names to lowercase for case-insensitive comparison
          let nameB = b.name.toLowerCase();
          if (nameA < nameB) {
              return -1;
          }
          if (nameA > nameB) {
              return 1;
          }
          return 0; // Names are equal
      });;
        this.cd.detectChanges();
      }
    })
  }

  toggoleCartDetaisl() {
    const TOGGOLEBUTTON: any = this.cartDetailsHolder.nativeElement;
    if (TOGGOLEBUTTON) {
      TOGGOLEBUTTON.classList.toggle('cart-details-popup-show');
      this.getCartProducts()
    }
  }

  getCartProducts() {
    let api: any;

    this.cartList = [];
    this.cd.detectChanges();

    if (this.profileService.isLoggedIn()) {
      api = this.cartService.getCart();
      api.subscribe(
        (res) => {
          this.cartList = res;
          this.getTotal();
          this.cd.detectChanges()
        },
        (err) => {
          this.notification.openNotification('Error', 'Error retrieving the cart', 'error');
          this.cd.detectChanges()
        }
      );
    }
  }

  getTotal() {
    const total = this.cartList.length && this.cartList.reduce((prev, item) => {
      return prev + (item.quantity * item.rrp);
    }, 0);

    const qty = this.cartList.length && this.cartList.reduce((prev, item) => {
      return prev + item.quantity;
    }, 0);

    this.subTotal = total;
    this.totalItems = qty;
  }

  onRemoveProduct(product) {
    if (this.auth.isLoggedIn()) {
      this.cd.detectChanges();
      this.cartService.removeFromCart(product.id).subscribe(
        (res) => {
          const successMsg = res.message || 'Product has been removed from your cart!!';
          this.notification.openNotification('Success', successMsg, 'success');
          this.getCartProducts();
          this.cd.detectChanges();
        },
        (err) => {
          const error = err.error.error || 'something went wrong!!';
          this.cd.detectChanges();
          this.notification.openNotification('Error', error, 'error');
        }
      );
    } 
    else {
      this.notification.openNotification('Error', 'You are not logged in', 'error');
    }

  }
}
