import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddReviewComponent } from './add-review/add-review.component';
import { MiniVerdictComponent } from './mini-verdict/mini-verdict.component';
import { ProductSnippetSmallComponent } from './product-snippet-small/product-snippet-small.component';
import { ReviewsSnippetComponent } from './reviews-snippet/reviews-snippet.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LazyLoadImageModule} from 'ng-lazyload-image';
import { SocialShareSnippetComponent } from './social-share-snippet/social-share-snippet.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        LazyLoadImageModule,
        ReactiveFormsModule,
        NgSelectModule,
        InfiniteScrollModule
    ],
    declarations: [
        AddReviewComponent,
        MiniVerdictComponent,
        ProductSnippetSmallComponent,
        ReviewsSnippetComponent,
        SocialShareSnippetComponent,

    ],
    exports: [
        CommonModule,
        ReactiveFormsModule,
        AddReviewComponent,
        MiniVerdictComponent,
        ProductSnippetSmallComponent,
        ReviewsSnippetComponent,
        SocialShareSnippetComponent,
        LazyLoadImageModule,
        NgSelectModule,
        InfiniteScrollModule
    ],
})
export class SharedModule { }
