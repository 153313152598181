
import {
  Component, OnInit,
  ViewEncapsulation, Input, OnDestroy, Output, EventEmitter
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { SideNavDirection } from './side-nav-direction';
import { ProductService } from '../../services/product.service';
import { SidebarNavigationService } from '../../services/SidebarNavigationService';
import { TagsFilterComponent } from '../tags-filter/tags-filter.component';
declare let $: any;
const FILTER_TYPE = {
  brand: {},
  category: {},
  room: {},
  colour: {},
  material: {},
  filter: {},
  style: {}
};
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Sidebar implements OnInit, OnDestroy {

  showSideNav: Observable<boolean>;
  selectedTags = FILTER_TYPE;
  subscription: any = null;
  isFetchingTags = false;
  tags: any = [];
  parentNames: any = {};
  myForm: FormGroup;
  isShowButton = true;
  @Input() isShowCategory: any;
  @Input() sidenavTemplateRef: any;
  @Input() duration = 0.25;
  @Input() navWidth: number = window.innerWidth;
  @Input() direction: SideNavDirection = SideNavDirection.Right;
  @Input() type: any;
  @Input() selectedTag: any;
  @Input() isShowCloseButton: any;
  @Input() isCollapse: any;
  @Input() set selectedFilters(selectedFilters: any) {
    if (selectedFilters) {
      this.selectedTags = selectedFilters;
    }
  }
  @Output() sidebarClose: EventEmitter<boolean> = new EventEmitter();
  @Output() menuSelected: EventEmitter<boolean> = new EventEmitter();
  constructor(private navService: SidebarNavigationService,
    private productService: ProductService
  ) {

  }

  ngOnInit(): void {
    if (this.isShowCloseButton == false) {
      this.isShowButton = this.isShowCloseButton;
    }
    this.selectedTags = this.productService.getSelectedFilters();
    this.tags = this.productService.getTags();
    this.showSideNav = this.navService.getShowNav();
    this.subscription = this.productService.updatedTags.subscribe((response: any) => {
      this.tags = response.tags;
      this.parentNames = response.parentNames;

      this.isFetchingTags = false;
    });
  }

  getControlsForOption1() {
    return (<FormArray>this.myForm.controls.option1).controls;
  }

  test() {
    setTimeout(() => {
      const offset = $('#tagsList ul li').first().position().top;
      $('#tagsList').scrollTop(
        $(`#${this.selectedTag}`).parent().position().top - offset
      );
    }, 2150);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  clearAll() {
    this.selectedTags = null;
    this.selectedTags = {
      brand: {},
      category: {},
      room: {},
      colour: {},
      material: {},
      filter: {},
      style: {}
    };
    this.productService.setSelectedFilters(this.selectedTags);
    this.productService.setTagNameListForSelectedFilters([]);
    this.productService.setFilters(this.selectedTags, this.type);
  }

  onSidebarClose() {
    this.navService.setShowNav(false);
    this.sidebarClose.emit(true);
  }

  getSideNavBarStyle(showNav: boolean) {
    const navBarStyle: any = {};

    navBarStyle.transition = this.direction + ' ' + this.duration + 's, visibility ' + this.duration + 's';
    navBarStyle.width = this.navWidth + 'px';
    navBarStyle[this.direction] = (showNav ? 0 : (this.navWidth * -1)) + 'px';

    return navBarStyle;
  }

  onChangeSelection(item: any) {
    this.isFetchingTags = true;
    // this.navService.setShowNav(true);
    this.menuSelected.emit(true)
    setTimeout(() => {
      this.onView(item);
    }, 300);
  }

  onView(tagInfo: any) {
    // sidebar will hide on click product and dashboard selected filters
    if (this.type !== 'header') {
      this.navService.setShowNav(false);
    }
    // It means, this particular tag has parent tag
    if (tagInfo.parent) {
      this.selectedTags.category[tagInfo.parent] = false;
    }
    this.productService.setSelectedFilters(this.selectedTags);
    this.productService.setFilters(this.selectedTags, this.type);

    // To show chips of product name which is selected
    let selectedFiltersNames: any = this.productService.getTagNameListForSelectedFilters();

    if (!selectedFiltersNames.length && tagInfo.parent) {
      selectedFiltersNames.push({
        tagId: tagInfo.tagId,
        name: tagInfo.name,
        type: tagInfo.type,
        urlSlug: tagInfo.urlSlug,
        parent: tagInfo.parent || null
      });
    } else if (selectedFiltersNames.findIndex(tag => tag.tagId == tagInfo.tagId) == -1) {
      if (tagInfo.type == 'category' && tagInfo.parent) { // Remove parent category if tag has parent category
        selectedFiltersNames = selectedFiltersNames.filter((val) => {
          return val.parent != null;
        });
      }
      selectedFiltersNames.push({
        tagId: tagInfo.tagId,
        name: tagInfo.name,
        type: tagInfo.type,
        urlSlug: tagInfo.urlSlug,
        parent: tagInfo.parent || null
      });
    } else if (selectedFiltersNames.length) {
      selectedFiltersNames.splice(selectedFiltersNames.findIndex(tag => tag.tagId == tagInfo.tagId), 1);
    }
    this.productService.setTagNameListForSelectedFilters(selectedFiltersNames);
    this.sidebarClose.emit(true);
  }
}
