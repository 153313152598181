import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable, throwError } from 'rxjs';
import {AuthService} from './auth.service';
import { Review, ReviewListResponse, StringResponse } from '../classes/review';
// import { ErrorObservable } from 'rxjs/observable/ErrorObservable';

@Injectable()
export class ReviewsService {

    constructor(
        private http: HttpService,
        private auth: AuthService

    ) { }

    getReviews(itemType: string, itemId: string, limit: number, skip: number): Observable<ReviewListResponse> {

        let url = '/v1/api/userreviews/postreviewslist?';
        if (limit) { url += `&limit=${limit}`; }
        if (skip) { url += `&skip=${skip}`; }
        return this.http.postHttpJson(url, {
            type: itemType,
            elementID: itemId,
        });

    }
    getMyReviews(itemType: string, itemId: string): Observable<ReviewListResponse> {

        if (!this.auth.isLoggedIn()) {
            return throwError({error: {message: 'You must be logged in'}});
        }
        const url = '/v1/api/userreviews/postreviewslist?';


        return this.http.postHttpJson(url, {
            type: itemType,
            elementID: itemId,
            uid: this.auth.getLastUser(),
        });

    }

    deleteReview (reviewId: string): Observable<StringResponse> {

        if (!this.auth.isLoggedIn()) {
            return throwError({error: {message: 'You must be logged in'}});
        }
        return this.http.getHttpJson(`/v1/api/userreviews/getdeletereview?reviewID=${reviewId}`, true);

    }

    addReview(reviewObj: Review): Observable<StringResponse> {
        if (!this.auth.isLoggedIn()) {
            return throwError({error: {message: 'You must be logged in'}});
        }
        return this.http.postHttpJson('/v1/api/userreviews/postaddreview', reviewObj);
    }

}
