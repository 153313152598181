import { Component, OnInit, Output, EventEmitter, Input, } from '@angular/core';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-filter-option',
  templateUrl: './filter-option-list.component.html',
  styleUrls: ['../products/products.component.scss']
})
export class FilterOptionList implements OnInit {
  @Input() tags: any;
  @Output() onFilter = new EventEmitter();
  constructor(private productService: ProductService) { }
  ngOnInit(): void {
  }
  openFiltersPan(e: any, id: string) {
    this.onFilter.emit(id);
  }
}
