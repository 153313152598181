import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { HttpService } from './http.service';
import { StringResponse } from '../classes/profile';
import { Cart, CartResponse, OrderResponse, ShipmentListResponse, PromocodeResponse, OrderRequest } from '../classes/purchase';
import { switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { LocalStorageWrapper } from './utils.service';
// import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { DisplayErrorService } from './display-error.service';

@Injectable()
export class PurchaseService {

    constructor(
        private http: HttpService,
        private storageService: LocalStorageWrapper,
        private auth: AuthService,
        private errorService: DisplayErrorService,

    ) { }

    listCards (force?: boolean): Observable<any> {
        if (!this.auth.isLoggedIn()) {
            return throwError({error: {message: 'You must be logged in'}});
        }

        return this.http.getHttpJson(`/v1/api/orders/getlistcards`, true);
    }

    removeCard (cardId: string): Observable<StringResponse> {
        if (!this.auth.isLoggedIn()) {
            return throwError({error: {message: 'You must be logged in'}});
        }

        return this.http.getHttpJson(`/v1/api/orders/getremovecard?cardID=${cardId}`, true);
    }
    addCard (token: string): Observable<any> {
        if (!this.auth.isLoggedIn()) {
            return throwError({error: {message: 'You must be logged in'}});
        }
        return this.http.getHttpJson(`/v1/api/orders/getaddcard?token=${token}`, true);
    }

    addToCart (productId: string, variantId: string, sellerId: string, quantity: number): Observable<CartResponse> {

        if (!this.auth.isLoggedIn()) {
            return throwError({error: {message: 'You must be logged in'}});
        }
        const cartItem: Cart = new Cart();

        const a = {
            productID: productId,
            variantID: variantId,
            sellerID: sellerId,
            quantity: quantity
        };

        return this.http.postHttpJson(`/v1/api/orders/addcart`, a)
        .pipe(switchMap(result => {
            this.storageService.setCart(result.data);
            this.errorService.reportCart(result.data.length);
            return of(result);
        }));
    }

    addToCartMany (cartArray: Cart[]): Observable<StringResponse> {

        if (!this.auth.isLoggedIn()) {
            return throwError({error: {message: 'You must be logged in'}});
        }
        return this.http.postHttpJson(`/v1/api/orders/postaddcartmany`, {
            cart: cartArray
        }).pipe(switchMap(result => {
            this.storageService.setCart(result.data);
            this.errorService.reportCart(result.data.length);
            return of(result);
        }));


    }

    updateCart (variantId: string, sellerId: string, quantity: number): Observable<CartResponse> {
        if (!this.auth.isLoggedIn()) {
            return throwError({error: {message: 'You must be logged in'}});
        }
        const url = `/v1/api/orders/updatecartv2?variantID=${variantId}&sellerID=${sellerId}&quantity=${quantity}`;
        return this.http.getHttpJson(url, true)
        .pipe(switchMap(result => {

            this.storageService.setCart(result.data);
            this.errorService.reportCart(result.data.length);
            return of(result);
        }));

    }

    removeFromCart (cartItem: Cart): Observable<StringResponse> {
        if (!this.auth.isLoggedIn()) {
            return throwError({error: {message: 'You must be logged in'}});
        }
        return this.http.postHttpJson(`/v1/api/orders/removecart`, cartItem)
        .pipe(switchMap(result => {
            this.storageService.setCart(result.data);
            this.errorService.reportCart(result.data.length);
            return of(result);
        }));


    }

    getCachedCart(): Observable<CartResponse> {
        const result = this.storageService.getCart();
        return of({message: '', responseStatus: 1, data: result || []});
        // if (result || !this.auth.isLoggedIn()) {
        //     return of({message: '', responseStatus: 1, data: result || []});
        // } else {
        //     return this.getCart();
        // }

    }

    getCart (): Observable<CartResponse> {

        if (!this.auth.isLoggedIn()) {
            return throwError({error: {message: 'You must be logged in'}});
        }
        return this.http.getHttpJson(`/v1/api/orders/getcart`, true)
        .pipe(switchMap(result => {
            this.storageService.setCart(result.data);
            this.errorService.reportCart(result.data.length);
            return of(result);
        }));
    }

    emptyCart(): Observable<StringResponse> {
        if (!this.auth.isLoggedIn()) {
            return throwError({error: {message: 'You must be logged in'}});
        }
        const url = `/v1/api/orders/emptycart`;
        return this.http.getHttpJson(url, true)
        .pipe(switchMap(result => {
            this.storageService.setCart(null);
            this.errorService.reportCart(0);
            return of(result);
        }));


    }

    saveOrder(order: OrderRequest): Observable<any> {
        if (!this.auth.isLoggedIn()) {
            return throwError({error: {message: 'You must be logged in'}});
        }
        const url = `/v1/api/orders/postsaveorder`;
        return this.http.postHttpJson(url, order);

    }
    processOrder(orderId: string, intentId: string): Observable<any> {
        if (!this.auth.isLoggedIn()) {
            return throwError({error: {message: 'You must be logged in'}});
        }
        const url = `/v1/api/orders/getprocessorder?orderID=${orderId}&intentID=${intentId}`;
        return this.http.getHttpJson(url, true);

    }

    orderDetails(orderId: string): Observable<OrderResponse> {
        if (!this.auth.isLoggedIn()) {
            return throwError({error: {message: 'You must be logged in'}});
        }
        const url = `/v1/api/orders/getorderdetails?orderID=${orderId}`;
        return this.http.getHttpJson(url);
    }

    getShipments(searchJson: any, limit: number, skip: number, sort: string): Observable<ShipmentListResponse> {
        if (!this.auth.isLoggedIn()) {
            return throwError({error: {message: 'You must be logged in'}});
        }
        const url = `/v1/api/shipments/postshipmentlist`;
        const data = {
            search: searchJson,
            limit: limit,
            skip: skip,
            sort: sort
        };
        return this.http.postHttpJson(url, data);
    }
    returnShipment(shipmentId: string, reasonReturn: string): Observable<StringResponse> {
        if (!this.auth.isLoggedIn()) {
            return throwError({error: {message: 'You must be logged in'}});
        }
        const url = `/v1/api/shipments/postreturnshipment?shipmentID=${shipmentId}`;
        return this.http.postHttpJson(url, {reasonReturn: reasonReturn});
    }

    getPromocode(promocode: string): Observable<PromocodeResponse> {
        if (!this.auth.isLoggedIn()) {
            return throwError({error: {message: 'You must be logged in'}});
        }
        const url = `/v1/api/promocode/getpromocodedetails?promocodeID=${promocode}`;
        return this.http.getHttpJson(url);
    }




}
