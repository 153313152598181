import { Component, OnInit, HostListener, Inject, PLATFORM_ID, ViewChild, ElementRef,
    ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { DisplayErrorService, DisplayError } from '../../services/display-error.service';
import { ProductService } from '../../services/product.service';
import { Product, ProductListResponse } from '../../classes/product';
import { Tag } from '../../classes/tag';
import { TagService } from '../../services/tag.service';
import { NavigationService } from '../../services/navigation.service';
import { Verdict } from '../../classes/game';
import { GameService } from '../../services/game.service';
import { Aggregation } from '../../classes/product';
import { combineLatest, Observable, of, throwError, Subscription } from 'rxjs';
import { isPlatformBrowser, DOCUMENT, Location } from '@angular/common';
import { TransferState, makeStateKey, Meta, Title } from '@angular/platform-browser';
import { ProfileService } from '../../services/profile.service';
import { environment } from '../../../environments/environment';

import { debounce } from '../../services/utils.service';
import { LocalStorageWrapper } from '../../services/utils.service';
import { switchMap, first } from 'rxjs/operators';
import { GTMEcommerceData, GTMProduct } from '../../classes/ecommerce';

@Component({
    selector: 'app-product-list',
    templateUrl: './product-list.component.html',
    styleUrls: ['./product-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductListComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('selectedVerdictElement', {static: false}) selectedVerdictElement: ElementRef;


    Object = Object;
    productsList: Product[];
    roomTags: Map<string, Tag> = new Map;
    colourTags: Map<string, Tag> = new Map;
    materialTags: Map<string, Tag> = new Map;
    styleTags: Map<string, Tag> = new Map;
    filterTags: Map<string, Tag> = new Map;
    storeType: 'general'|'personal'|'brand'|'room'|'category'|'style' = 'general';
    storeTypeId: string;
    // searchSubscription: Subscription;

    brandTags: Map<string, Tag> = new Map;
    categoryTags: Map<string, Tag> = new Map;
    aggregations: Map<string, Map<string, number>> = new Map;
    sortList = '';
    perPage = 24;
    verdictList: Verdict[];
    sellerId: string;
    categoryShowingMore: boolean;
    moreProductsRemaining: boolean;
    roomId: string[] = [];
    colourId: string[] = [];
    materialId: string[] = [];
    categoryId: string[] = [];
    brandId: string[] = [];
    styleId: string[] = [];
    maxPrice: number;
    searchSeed: string;
    prefix: string ;
    searchJson: any = {};
    storeTag: Tag;
    showPersonalisedFlag: boolean;
    // storeId: 't'|'p';
    selectedVerdict: Verdict;
    selectedVerdictId: string;
    isBrowser: boolean;
    isScrolledDown: boolean;
    productsListKey = makeStateKey('products-list');
    isLoggedIn = false;
    maxFiltersPerProduct = 7;
    mobileFiltersOpened = false;
    showStylePopup = false;
    seo_description = 'Marketplace to browse and purchase Furniture, Lighting, Homeart and other interior design products';
    seo_title = 'Store';
    verdictSelectorOpened = false;
    params: Params;
    subHeading: string;
    jsonLdStore = {};
    jsonLdBreadcrumbs = {};


    @HostListener('window:scroll', [])
    @debounce(environment.throttleTime)
    onScroll(): void {
        if (this.isBrowser) {
            // if (this.moreProductsRemaining && (window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            //     this.getProductList(true);
            // }
            if (window.scrollY > 200) {
                this.isScrolledDown = true;
            } else {
                this.isScrolledDown = false;
            }
        }


    }

    constructor(
        private route: ActivatedRoute,
        private productService: ProductService,
        private tagService: TagService,
        private gameService: GameService,
        private profileService: ProfileService,
        private navigationService: NavigationService,
        // private utilService: UtilsService,
        private errorService: DisplayErrorService,
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(DOCUMENT) private document: any,
        private xstate: TransferState,
        private storageService: LocalStorageWrapper,
        private titleService: Title,
        private metaService: Meta,
        private router: Router,
        private location: Location,
        private localStorage: LocalStorageWrapper,
        private cd: ChangeDetectorRef,

    ) {

     }

     ngOnDestroy() {


     }

    ngOnInit() {
        this.isBrowser = isPlatformBrowser(this.platformId);

        this.restoreProductSearch();

        combineLatest([
            this.route.params,
            this.route.queryParams,
        ])
        .subscribe(res => {

            this.params = res[0];
            const queryParams = res[1];
            if (queryParams['prefix'] ) {
                this.prefix = queryParams['prefix'].replace('+', ' ');
            }
            if (queryParams['verdict'] ) {
                this.selectedVerdictId = queryParams['verdict'];
            }
            if (this.params ) {
                // if (this.params['storeId']) {
                //     this.storeId = this.params['storeId'];
                //     if (this.storeId === 'p') {
                //         this.showPersonalisedFlag = true;
                //     }
                // }
                if (this.params['roomId']) {
                    this.clearSearchParameters();
                    this.storeType = 'room';
                    this.storeTypeId = this.params['roomId'];
                    this.roomId = [this.storeTypeId];
                    if (this.params['categoryId']) {
                        this.updateCategory(this.params['categoryId'], 0, true);
                    }
                    this.updateSearch('room', this.storeTypeId, true);
                } else if (this.params['categoryId']) {
                    this.clearSearchParameters();
                    this.storeType = 'category';
                    this.storeTypeId = this.params['categoryId'];
                    this.updateCategory(this.storeTypeId);
                } else if (this.params['brandId']) {
                    this.clearSearchParameters();
                    this.storeType = 'brand';
                    this.storeTypeId = this.params['brandId'];
                    this.brandId = [this.storeTypeId];
                    this.updateSearch('brand', this.storeTypeId, true);
                } else if (this.params['styleId']) {
                    this.clearSearchParameters();
                    this.storeType = 'style';
                    this.storeTypeId = this.params['styleId'];
                    this.styleId = [this.storeTypeId];
                    this.updateSearch('style', this.storeTypeId, true);

                } else {
                    this.getProductList(false);
                }
                this.addSeoTags();

            } else {
                this.getProductList(false);
            }
            this.updateTags();
            // this.cd.detectChanges();

        });

        if (this.isBrowser) {
            this.errorService.reportModule('products');
            this.isLoggedIn = this.profileService.isLoggedIn();
        }


    }

    updateTags() {
        if (this.isBrowser) {
            this.storeTag = null;


            this.tagService.cachedTagsList('room').pipe(first()).subscribe(result => {
                this.roomTags = result;
                if (this.params['roomId'] && this.roomTags[this.params['roomId']]) {
                    this.storeTag = this.roomTags[this.params['roomId']];
                    this.subHeading = `${this.roomTags[this.params['roomId']].name}`;
                    this.seo_title = this.roomTags[this.params['roomId']].name ;
                    this.addSeoTags();
                }
                // if (this.roomId && this.roomId[0] && this.roomTags[this.roomId[0]]) {
                // }
                // this.cd.detectChanges();
            });
            this.tagService.cachedTagsList('colour').pipe(first()).subscribe(result => {
                this.colourTags = result;
                // this.cd.detectChanges();

            });
            this.tagService.cachedTagsList('material').pipe(first()).subscribe(result => {
                this.materialTags = result;
                // this.cd.detectChanges();

            });
            this.tagService.cachedTagsList('brand').pipe(first()).subscribe(result => {
                this.brandTags = result;
                if (this.params['brandId'] && this.brandTags[this.params['brandId']]) {
                    this.storeTag = this.brandTags[this.params['brandId']];
                    this.seo_title = this.brandTags[this.params['brandId']].name ;
                    this.subHeading = `${this.brandTags[this.params['brandId']].name}`;
                }
                // this.cd.detectChanges();
            });
            this.tagService.cachedTagsList('filter').pipe(first()).subscribe(result => {
                this.filterTags = result;
                // this.cd.detectChanges();
            });

            this.tagService.cachedTagsList('style').pipe(first()).subscribe(result => {
                this.styleTags = result;
                if (this.params['styleId'] && this.styleTags[this.params['styleId']]) {
                    this.storeTag = this.styleTags[this.params['styleId']];
                    this.seo_title = this.styleTags[this.params['styleId']].name ;
                    this.subHeading = `${this.styleTags[this.params['styleId']].name}`;
                }

                // this.cd.detectChanges();
            });
            this.tagService.cachedTagsList('category').pipe(first()).subscribe(result => {
                this.categoryTags = result;

                if (this.params['categoryId'] && this.categoryTags[this.params['categoryId']]) {
                    this.storeTag = this.categoryTags[this.params['categoryId']];
                    this.storeType = 'category';
                    this.seo_title = this.categoryTags[this.params['categoryId']].name ;
                    this.addSeoTags();

                }

                // if (this.categoryId && this.categoryId[0] && this.categoryTags[this.categoryId[0]]) {
                    // this.storeTag = this.categoryTags[this.categoryId[0]];
                // }

                this.cd.detectChanges();

            });

        } else  {

            if (this.params['roomId']) {
                this.storeTypeId = this.params['roomId'];
                this.storeType = 'room';
            } else if (this.params['categoryId']) {
                this.storeTypeId = this.params['categoryId'];
                this.storeType = 'category';
            } else if (this.params['brandId']) {
                this.storeTypeId = this.params['brandId'];
                this.storeType = 'brand';
            } else if (this.params['styleId']) {
                this.storeTypeId = this.params['styleId'];
                this.storeType = 'style';
            }
            if (this.storeTypeId) {
                const searchjson = {};
                searchjson[this.storeType] = this.storeTypeId;
                combineLatest([
                    this.tagService.getTagDetail(this.storeTypeId),
                    this.productService.elasticProductsList(searchjson, '', 12, 0, '', ''),
                ])
                .subscribe(result => {
                    this.storeTag = result[0].data;
                    this.productsList = result[1].data.list;
                    this.seo_title = this.storeTag.name ;
                    this.addSeoTags();
                });
            }



        }
    }

    ngDestroy() {
        // this.searchSubscription.unsubscribe();
    }
    ngAfterViewInit() {
        this.cd.detectChanges();
    }
    trackByProductId(index, product: Product) {
        return product._id;
    }
    openStore(tag: Tag, type: 'room'|'brand'|'style'|'cat') {
        this.resetLocalParams();
        this.router.navigateByUrl(this.navigationService.getStoreUrl(tag, type));
        this.cd.detectChanges();
    }


    openProductDetails(product: Product) {
        this.saveProductSearch();
        this.addDataLayer('productClick', [product], () => {
            this.navigationService.navigateProductDetail(product);

        });

        // if (this.isBrowser) {
        //     const gtmProduct2 = this.productService.getGTMProduct(product, <GTMP>{});

        //     window['dataLayer'] = window['dataLayer'] || [];

        //     window['dataLayer'].push(<GTMEcommerceData>{
        //         event: 'productClick',
        //         ecommerce: {
        //             click: {
        //                 actionField: {list: this.seo_title},
        //                 products: [gtmProduct2],
        //             }
        //         },
        //         eventCallback: () => {
        //             this.navigationService.navigateProductDetail(product);
        //         }
        //     });
        // }
    }

    resetLocalParams() {
        this.roomId = [];
        this.colourId = [];
        this.materialId = [];
        this.brandId = [];
        this.styleId = [];
        this.categoryId = [];
        this.prefix = '';
        this.searchJson = {};
        delete this.searchJson['actualPrice'];
        this.maxPrice = null;
    }

    clearSearchParameters() {
        this.resetLocalParams();
        this.productService.clearSearchParameters();
    }
    addSeoTags() {
        this.titleService.setTitle(`${this.seo_title} | Moodmaestro`);
        this.metaService.removeTag('name="description"');
        const tag = { name: 'description', content: this.seo_description };
        this.metaService.addTag(tag, false);
        this.jsonLdStore = this.getSafeJsonLd('store');
        this.jsonLdBreadcrumbs = this.getSafeJsonLd('breadcrumbs');
        this.cd.detectChanges();
    }
    getSafeJsonLd(key: 'store'|'breadcrumbs'): any {
        const jsonld = {
            'store': {
                '@context': 'http://schema.org',
                '@type': 'ItemList',
                'url': environment.baseHref + this.router.url,
            }, 'breadcrumbs': {
                '@context': 'http://schema.org',
                '@type': 'BreadcrumbList',

            }
        };
        if (key === 'store' && this.productsList && this.productsList.length > 0) {
            jsonld['store']['itemListElement'] = this.productsList.map((x, i) => {
                return {
                    '@type': 'ListItem',
                    'position': i,
                    'name': x.name,
                    'url': environment.baseHref + this.navigationService.urlProductDetail(x),
                };
            });
        } else if (key === 'breadcrumbs' && this.categoryId && this.categoryId.length > 0) {
            jsonld['breadcrumbs']['itemListElement'] = this.categoryId.map((x, i) => {
                return {
                    '@type': 'ListItem',
                    'position': i,
                    'item': {
                    '@id': 'store-breadcrumbs',
                    'name': this.categoryTags[x] ? this.categoryTags[x].name : x,
                    'url': environment.baseHref + this.navigationService.urlCategoryStore(x),
                    }
                };
            });
        }
        return jsonld[key];

    }
    scrollTopPage() {
        if (this.isBrowser) {
            this.document.body.scrollTop = 0; // For Safari
            this.document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }
    }

    saveProductSearch() {
        const obj = {
            roomId: this.roomId,
            colourId: this.colourId,
            materialId: this.materialId,
            categoryId: this.categoryId,
            brandId: this.brandId,
            maxPrice: this.maxPrice,
            searchSeed: this.searchSeed,
            prefix: this.prefix,
            // showPersonalisedFlag: this.showPersonalisedFlag,
            // storeId: this.storeId,
            selectedVerdict: this.selectedVerdict,
            selectedVerdictId: this.selectedVerdictId,
            searchJson: this.searchJson,
            sortList: this.sortList,
        };
        this.productService.saveProductSearch(obj);
    }
    restoreProductSearch() {
        const obj = this.productService.restoreProductSearch();
        if (obj) {
            this.roomId = obj.roomId || [];
            this.colourId = obj.colourId || [];
            this.materialId = obj.materialId || [];
            this.categoryId = obj.categoryId || [];
            this.brandId = obj.brandId || [];
            this.maxPrice = obj.maxPrice;
            this.searchSeed = obj.searchSeed;
            this.prefix = obj.prefix;
            // if (obj.prefix){
            //     this.router.navigate([],{
            //         queryParams: <Params> { prefix: obj.prefix },
            //         queryParamsHandling: "merge",
            //     });
            // }
            // // this.showPersonalisedFlag = obj.showPersonalisedFlag;
            // this.storeId = obj.storeId;
            this.selectVerdict(obj.selectedVerdict);
            this.selectedVerdictId = obj.selectedVerdictId;
            this.searchJson = obj.searchJson;
            this.sortList = obj.sortList;

        }
        // category, brand, room, actualPrice
        if (!this.searchJson) { this.searchJson = {}; }
        if (this.categoryId && this.categoryId.length > 0) {
            this.searchJson['category'] = this.categoryId[this.categoryId.length - 1];
            this.location.go(this.navigationService.urlCategoryStore(this.categoryId[this.categoryId.length - 1]));
        }
        if (this.roomId && this.roomId.length > 0 && !this.searchJson['room']) {
            this.searchJson['room'] = {$in: this.roomId};
        }
        if (this.colourId && this.colourId.length > 0 && !this.searchJson['colour']) {
            this.searchJson['colour'] = this.colourId[this.colourId.length - 1];
        }
        if (this.materialId && this.materialId.length > 0 && !this.searchJson['material']) {
            this.searchJson['material'] = this.materialId[this.materialId.length - 1];
        }
        if (this.brandId && this.brandId.length > 0 && !this.searchJson['brand']) {
            this.searchJson['brand'] = {$in: this.brandId};
        }

    }
    saveAggregations(aggregations: Map<string, Aggregation>, more: boolean) {
        this.aggregations = new Map;
        Object.keys(aggregations).forEach(field => {
            aggregations[field].buckets.forEach(element => {
                if (!this.aggregations[field]) { this.aggregations[field] = new Map; }
                this.aggregations[field][element.key] = element.doc_count;
            });
        });
        this.cd.detectChanges();
        // if (more) {
        // }
    }

    changeVerdict() {
        this.verdictList.forEach(element => {
            if (element._id === this.selectedVerdictId) {
                this.selectVerdict(element);
            }
        });
        this.getProductList(false);
        this.verdictSelectorOpened = false;
    }
    // openVerdictSelector(){
    //     this.verdictSelectorOpened = true;
    //     this.selectedVerdictElement.nativeElement.dispatchEvent(new Event('mousedown'));

    // }

    // changeStoreType(storeId?: string, noLoadProducts?: boolean) {
    //     if (storeId === this.storeId || !storeId) { return; }

    //     this.showPersonalisedFlag = storeId === 'p';
    //     const previousStoreId = this.storeId;

    //     if (this.showPersonalisedFlag) {
    //         this.storeId = 'p';
    //     } else {
    //         this.storeId = 't';
    //     }
    //     const newUrl = this.router.url.replace(`/products/${previousStoreId}`, `/products/${this.storeId}`);
    //     this.router.navigateByUrl(newUrl);
    //     if (!noLoadProducts) {
    //         this.getProductList(false);
    //     }
    //     // this.errorService.reportSearch('');

    // }
    mathRound(x: number) {
        return Math.round(x);
    }
    getVerdicts(): Observable<Verdict> {
        const lastVerdict = this.storageService.getLastVerdict();

        if (this.verdictList) {
            if (this.selectedVerdict) {
                return of(this.selectedVerdict);
            } else if (this.selectedVerdictId) {
                this.verdictList.forEach(element => {
                    if (element._id === this.selectedVerdictId) {
                        this.selectVerdict(element);
                        return of(element);
                    }
                });
            } else {
                this.selectedVerdictId = this.verdictList[0]._id;

                this.selectVerdict(this.verdictList[0]);
                return of(this.verdictList[0]);
            }
        }

        if (lastVerdict) {
            this.verdictList = [<Verdict>{
                _id: 'last-verdict',
                verdict: lastVerdict,
            }];
        }

        if (!this.profileService.isLoggedIn()) {
            if (this.verdictList && this.verdictList[0]) {
                this.selectVerdict(this.verdictList[0]);
                return of(this.verdictList[0]);
            } else {
                // this.changeStoreType('t');
                return throwError({error: {message: 'Please play the Moodmaestro game first to discover your style'}});
            }
        }

        return this.gameService.getVerdicts().pipe(
            switchMap(result => {
                const jsonFields = result.data.jsonFields;

                result.data.list.forEach(element => {
                    Object.keys(element).forEach(key => {
                        if (jsonFields.indexOf(key) >= 0) {
                            try {
                                element[key] = JSON.parse(element[key]);
                            } catch (e) {

                            }
                        }
                    });
                });
                if (result.data.list[0]) {
                    result.data.list[0].default = true;
                }
                if (!this.verdictList) { this.verdictList = []; }
                this.verdictList = this.verdictList.concat(result.data.list);

                if (this.selectedVerdict && this.selectedVerdictId) {

                    return of(this.selectedVerdict);
                } else {
                    let verdict: Verdict = null;
                    if (this.selectedVerdictId) {
                        this.verdictList.forEach(element => {
                            if (this.selectedVerdictId === element._id) {
                                verdict = element;
                            }
                        });

                        // return of(this.verdictList[0]);
                    }

                    if (!verdict) {
                        verdict = this.verdictList[0];

                    }
                    this.selectVerdict(verdict);
                    return of(verdict);
                }


            })

        );

    }

    getProductList(more: boolean) {
        // if (!more && this.xstate.hasKey(this.productsListKey)) {
        //     const result = this.xstate.get(this.productsListKey, null as any);
        //     this.handleResponse(result, more);
        //     this.xstate.remove(this.productsListKey);
        // } else {
            const searchJson = this.searchJson;
            const prefix = this.prefix;
            const skip = more ? this.productsList.length : 0;
            if (!more) {
                this.searchSeed = String(Date.now());
            }
            // let getListFunction ;

            const scoreJson = this.localStorage.getLastSearch('products') || {};
            // if (this.storeId === 'p') {


            //     // getListFunction = this.getVerdicts().pipe(switchMap(verdict => this.productService.elasticPersonalisedProductsList(
            //     //     verdict._id, searchJson, prefix, this.perPage, skip, this.sortList, this.searchSeed
            //     getListFunction = this.getVerdicts().pipe(switchMap(verdict => this.productService.elasticPersonalisedStore(
            //         verdict, {query: searchJson, score: scoreJson}, prefix, this.perPage, skip, this.sortList, this.searchSeed
            //     )));
            // } else {
            //     getListFunction = this.productService.elasticProductsList(
            //         {query: searchJson, score: scoreJson}, prefix, this.perPage, skip, this.sortList, this.searchSeed
            //     );
            // }

            this.errorService.reportBusy(1);
            this.productService.elasticProductsList(
                {query: searchJson, score: scoreJson}, prefix, this.perPage, skip, this.sortList, this.searchSeed
            ).subscribe(result1 => {
                this.errorService.reportBusy(-1);

                if (!this.isBrowser) {
                    // this.xstate.set(this.productsListKey, result1);
                } else {
                    this.saveLastFewTags(searchJson);
                }

                this.handleResponse(result1, more);
            }, err => {
                this.errorService.reportBusy(-1);
                if (err.error && err.error.message) {
                    this.errorService.reportError(new DisplayError('error', err.error.message, '', this.cd));
                } else {
                    this.errorService.reportError(new DisplayError('error', 'Error searching for products', '', this.cd));
                }

                // if (this.storeId === 'p') {
                //     this.changeStoreType('t', true);
                // }
            });
        // }
    }
    selectVerdict(verdict: Verdict) {
        this.selectedVerdict = verdict;

        if (this.selectedVerdict && this.selectedVerdict.verdict) {
            this.selectedVerdictId = verdict._id;
            this.selectedVerdict.verdict.sortedFilters = Object.keys(this.selectedVerdict.verdict.filterScore).sort((a, b) => {
                return parseFloat(this.selectedVerdict.verdict.filterScore[b]) - parseFloat(this.selectedVerdict.verdict.filterScore[a]);
            });
        }
    }

    saveLastFewTags(searchJson: any) {
        let obj = this.localStorage.getLastSearch('products');
        if (!obj) { obj = {}; }
        Object.keys(searchJson).forEach(k => {
            switch (k) {
                case 'category':
                case 'colour':
                case 'material':
                    if (!obj[k] ) { obj[k] = []; }
                    obj[k].unshift(searchJson[k]);
                    obj[k] = obj[k].filter(function(item, pos) {
                        return obj[k].indexOf(item) === pos;
                    });
                    obj[k] = obj[k].slice(0, 10);

                    break;
                case 'room':
                case 'brand':
                    if (!obj[k] ) { obj[k] = []; }
                    obj[k] = (searchJson[k].$in || []).concat(obj[k]);
                    obj[k] = obj[k].filter(function(item, pos) {
                        return obj[k].indexOf(item) === pos;
                    });
                    obj[k] = obj[k].slice(0, 10);
                    break;
                default:
                    break;
            }

        });
        this.localStorage.setLastSearch('products', obj);

    }

    handleResponse(result: ProductListResponse, more: boolean) {
        if (result.data.list.length >= this.perPage) {
            this.moreProductsRemaining = true;
        } else {
            this.moreProductsRemaining = false;
        }

        // if (this.storeId === 'p' && this.selectedVerdict && this.selectedVerdict.verdict) {
        //     result.data.list.forEach(element => {
        //         if (element.filter && Array.isArray(element.filter)) {
        //             element.filter = this.selectedVerdict.verdict.sortedFilters.filter(x => element.filter.indexOf(x) >= 0)
        //             .slice(0, this.maxFiltersPerProduct);

        //         }
        //     });

        // }

        this.addDataLayer('impressions', result.data.list);


        if (more) {
            this.productsList = this.productsList.concat(result.data.list);
        } else {
            this.productsList = result.data.list;
        }
        this.cd.detectChanges();


        if (result.data.aggregations) {
            this.saveAggregations(result.data.aggregations, more);
        }

    }

    updateCategory(catId: string, breakloop?: number, dontSearch?: boolean) {

        if (!this.categoryTags || Object.keys(this.categoryTags).length === 0) {
            setTimeout(() => {
                if ((breakloop || 0) < 10) { this.updateCategory(catId, (breakloop || 0) + 1); }
            }, 100);
            return;
        }
        if (catId && !this.categoryTags.hasOwnProperty(catId)) {
            this.errorService.reportError(new DisplayError('error', 'Invalid category', '', this.cd));
            return;
        } else {
            if (!catId) {
                this.categoryId = [];
            } else if (this.categoryTags.hasOwnProperty(catId)) {
                this.categoryId = [catId];
                let c = catId ;
                while (c && this.categoryTags[c].parent && this.categoryTags[c].parent !== '') {
                    this.categoryId.unshift(this.categoryTags[c].parent);
                    c = this.categoryTags[c].parent;
                }

            }
            this.updateSearch('category', catId, false, dontSearch);
            // this.updateSearch('category', catId, false);
            // this.location.go(this.navigationService.urlCategoryStore(this.storeId, catId));
        }

    }
    updateColour(colId: string, breakloop?: number) {


        if (!this.colourTags || Object.keys(this.colourTags).length === 0) {
            setTimeout(() => {
                if ((breakloop || 0) < 10) { this.updateColour(colId, (breakloop || 0) + 1); }
            }, 100);
            return;
        }

        if (!colId) {
            this.colourId = [];
        } else if (this.colourTags.hasOwnProperty(colId)) {
            this.colourId = [colId];
            let c = colId ;
            while (c && this.colourTags[c].parent && this.colourTags[c].parent !== '') {
                this.colourId.unshift(this.colourTags[c].parent);
                c = this.colourTags[c].parent;
            }

        }
        this.updateSearch('colour', colId, false);
    }
    updateMaterial(matId: string, breakloop?: number) {


        if (!this.materialTags || Object.keys(this.materialTags).length === 0) {
            setTimeout(() => {
                if ((breakloop || 0) < 10) { this.updateMaterial(matId, (breakloop || 0) + 1); }
            }, 100);
            return;
        }

        if (!matId) {
            this.materialId = [];
        } else if (this.materialTags.hasOwnProperty(matId)) {
            this.materialId = [matId];
            let c = matId ;
            while (c && this.materialTags[c].parent && this.materialTags[c].parent !== '') {
                this.materialId.unshift(this.materialTags[c].parent);
                c = this.materialTags[c].parent;
            }

        }
        this.updateSearch('material', matId, false);
    }

    updateBrand(brandId: string, evt?: MouseEvent) {
        const index = this.brandId.indexOf(brandId);
        if (!brandId) {
            this.brandId = [];
        } else if (index >= 0) {
            this.brandId.splice(index, 1);
        } else {
            this.brandId.push(brandId);
        }

        this.updateSearch('brand', {$in: this.brandId}, false);
        if (evt) { evt.stopPropagation(); }

    }
    updateStyle(styleId: string, evt?: MouseEvent) {
        const index = this.styleId.indexOf(styleId);
        if (!styleId) {
            this.styleId = [];
        } else if (index >= 0) {
            this.styleId.splice(index, 1);
        } else {
            this.styleId.push(styleId);
        }

        this.updateSearch('style', {$in: this.styleId}, false);
        if (evt) { evt.stopPropagation(); }

    }

    showAlert(str: string) {
        alert(str);
    }
    updateRoom(roomId: string, evt?: MouseEvent) {

        const index = this.roomId.indexOf(roomId);
        if (!roomId) {
            this.roomId = [];
        } else if (index >= 0) {
            this.roomId.splice(index, 1);
        } else {
            this.roomId.push(roomId);
        }

        this.updateSearch('room', {$in: this.roomId}, false);
        if (evt) { evt.stopPropagation(); }

    }
    // updateColour(colourId: string, evt?: MouseEvent) {

    //     const index = this.colourId.indexOf(colourId);
    //     if (!colourId) {
    //         this.colourId = [];
    //     } else if (index >= 0) {
    //         this.colourId.splice(index, 1);
    //     } else {
    //         this.colourId.push(colourId);
    //     }
    //     this.updateSearch('colour', {$in: this.colourId}, false);
    //     if (evt) { evt.stopPropagation(); }

    // }
    // updateMaterial(materialId: string, evt?: MouseEvent) {

    //     const index = this.materialId.indexOf(materialId);
    //     if (!materialId) {
    //         this.materialId = [];
    //     } else if (index >= 0) {
    //         this.materialId.splice(index, 1);
    //     } else {
    //         this.materialId.push(materialId);
    //     }
    //     this.updateSearch('material', {$in: this.materialId}, false);
    //     if (evt) { evt.stopPropagation(); }

    // }
    resetSearch() {
        // this.roomId = [];
        // this.colourId = [];
        // this.materialId = [];
        // this.brandId = [];
        // this.categoryId = [];
        // this.prefix = '';
        // this.searchJson = {};
        // delete this.searchJson['actualPrice'];
        // this.maxPrice = null;
        this.resetLocalParams();
        this.storeType = 'general';
        this.storeTypeId = '';
        this.location.go(this.navigationService.urlCategoryStore(null));

        this.getProductList(false);

    }


    updateSearch(searchType: string, searchFilter: any, clear: boolean, dontSearch?: boolean) {
        this.subHeading = null;

        if (searchFilter !== '_all') {
            if (clear) {
                this.searchJson = {};
            }
            if (searchFilter) {
                this.searchJson[searchType] = searchFilter;
            } else {
                delete this.searchJson[searchType];
            }
        } else {
            delete this.searchJson[searchType];
        }
        if (!dontSearch) {
            this.getProductList(false);
        }

    }

    updateSearchPrice(price: number) {
        this.maxPrice = price;
        if (!price) {
            delete this.searchJson['actualPrice'];
        } else {
            this.searchJson['actualPrice'] = {$lt: price};

        }
        this.getProductList(false);
    }
    updatePrefix(search: string) {
        this.router.navigate([], {
            queryParams: <Params> { prefix: search },
            queryParamsHandling: 'merge',
        });
        this.prefix = search;
        this.getProductList(false);
    }
    stopPropagation(evt: MouseEvent) {
        evt.stopPropagation();
    }

    addDataLayer(type: 'impressions'|'productClick', list: Product[], callback?: () => void) {
        if (this.isBrowser) {
            window['dataLayer'] = window['dataLayer'] || [];

            switch (type) {
                case 'impressions':
                    const gtmProducts = list.map((x, i) => {
                        return this.productService.getGTMProduct(x, <GTMProduct>{position: (i + 1)});
                    });
                    window['dataLayer'].push(<GTMEcommerceData>{
                        event: 'productImpression',
                        ecommerce: Object.assign(this.productService.getNewDataLayer().ecommerce, {
                            currencyCode: 'GBP',
                            impressions: gtmProducts,
                        })
                    });
                    break;
                case 'productClick':
                    window['dataLayer'].push(<GTMEcommerceData>{
                        event: 'promotionClick',
                        ecommerce: Object.assign(this.productService.getNewDataLayer().ecommerce, {
                            promoClick: {
                                promotions: [{
                                'id': 'product-detail-recommendations',
                                }]
                            }
                        })
                    });
                    window['dataLayer'] = window['dataLayer'] || [];
                    window['dataLayer'].push(<GTMEcommerceData>{
                        event: 'productClick',
                        ecommerce: Object.assign(this.productService.getNewDataLayer().ecommerce, {
                            click: {
                                products: gtmProducts,
                            }
                        }),
                        eventCallback: callback
                    });
                    break;

                default:
                    break;
            }


        }
    }

}
