import {
    Component,
    OnInit,
    ViewChild,
    Inject,
    PLATFORM_ID,
    HostListener,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    ElementRef,
    NgZone,
    AfterViewInit,
    OnDestroy,
} from '@angular/core';
import {
    ProductListResponse,
    Product,
    ProductResponse,
    Variant,
    ProductEvent,
    Media,
} from '../../classes/product';
import { ProductService } from '../../services/product.service';
import {
    DisplayErrorService,
    DisplayError,
} from '../../services/display-error.service';
import { Inventory } from '../../classes/inventory';
import { Tag } from '../../classes/tag';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';
// import { TagService } from '../../services/tag.service';
// import { ReviewsService } from '../../services/reviews.service';
// import { ReviewListResponse, Review } from '../../classes/review';
import { ProfileService } from '../../services/profile.service';
import { StringResponse, FavouritesResponse } from '../../classes/profile';
import { LocalStorage, UtilsService } from '../../services/utils.service';
import { PurchaseService } from '../../services/purchase.service';
import { CartResponse } from '../../classes/purchase';
import { LocalStorageWrapper } from '../../services/utils.service';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { forkJoin } from 'rxjs';

// import { FacebookService, InitParams } from 'ngx-facebook';
import { environment } from '../../../environments/environment';
import { GTMEcommerceData, GTMProduct } from '../../classes/ecommerce';
// import { first } from 'rxjs/operators';

import * as THREE from 'three/build/three.module.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { Review } from '../../classes/review';
import { CartService } from '../../purchase/cart/cart.service';
import { NotificationService } from '../../services/notification.service';
import { TagService } from '../../services/tag.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
// import { TransferHttpResponse } from '@nguniversal/common/src/transfer_http';
declare let $: any;
declare var jQuery: any;


const ProductResponsive = [
    {
        breakpoint: 992,
        settings: {
            dots: true,
            focusOnSelect: true,
        },
    },
];
@Component({
    selector: 'app-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-pk.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductDetailComponent
    implements OnInit, AfterViewInit, OnDestroy {
    selectedIndex = 0;
    mainFilter: string;
    mainRoom: string;
    productMedias: Media[] = [];
    tags: Tag[];
    tagDictionary: any = {};
    emailEnqueryForm: FormGroup = new FormGroup({});
    constructor(
        private productService: ProductService,
        private utilService: UtilsService,
        // private tagService: TagService,
        private errorService: DisplayErrorService,
        private route: ActivatedRoute,
        private profileService: ProfileService,
        private purchaseService: PurchaseService,
        // private reviewService: ReviewsService,
        private navigationService: NavigationService,
        @Inject(DOCUMENT) _document: any,
        private storageService: LocalStorageWrapper,
        private localStorage: LocalStorage,
        private state: TransferState,
        @Inject(PLATFORM_ID) private platformId: Object,
        // private urlEncoder: HttpUrlEncodingCodec,
        private titleService: Title,
        private metaService: Meta,
        // private sanitizer: DomSanitizer,
        private router: Router,
        // private fb: FacebookService,
        private cdr: ChangeDetectorRef,
        public cartService: CartService,
        private _location: Location, // private ngZone: NgZone,
        private notification: NotificationService,
        private tagService: TagService,
        private auth: AuthService,
    ) {
        this.document = _document;
    }

    product: Product;
    inventoryList: Inventory[];
    productId: string;
    variantId: string;
    actionError: DisplayError;
    selectedDisplayImage: string;
    selectedDisplayImageIndex = 0;
    show3D = false;
    allTags: Map<string, Tag> = new Map();
    reviews: Review[];
    recommendations: Product[];
    associatedProducts: Product[];
    selectedVariant: Variant;
    selectedInventory: Inventory;
    zoomedImage: string;
    productShareUrl: string;
    isBrowser: boolean;
    isMobile = false;
    blowUpFactorX: number;
    blowUpFactorY: number;
    mainCategory: string;
    mainStyle: string;
    mainColour: string;
    mainMaterial: string;
    descriptionShowingMore = false;
    isTagIdQueryParams = false;
    document: any;
    jsonLd = {};
    baseHref = environment.baseHref;

    recommendationsPerSlide = 6;
    screenWidth: number;
    screenHeight: number;
    maxRecommendations = 24;
    slideIndex: number[];
    slideIndexAssoc: number[];
    qty = 1;
    mixer: THREE.Mixer;
    action: THREE.Action;
    err3D: string;
    showLoader = false;
    showEmailInfoLoader:boolean = false;
    showEmailInfo = false;
    showAddAddress = false;

    thumbnailConfig = {
        slidesToShow: 20,
        slidesToScroll: 1,
        asNavFor: '.product-detail-img',
        dots: false,
        centerMode: false,
        autoplay: false,
        infinite: false,
        arrows: false,
        vertical: false,
        focusOnSelect: true,
    };
    productConfig = {
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.product-img-list',
        dots: false,
        autoplay: false,
        infinite: true,
        arrows: false,
        fade: true,
        centerMode: false,
        responsive: ProductResponsive
    };

    public roles: any[] = [
        { name: "Client", value: "DECORAIT_PRO_CLIENT" },
        { name: "Retailer", value: "DECORAIT_TRADE" },
        { name: "Interior Designer", value: "DECORAIT_PRO" }
    ];
    public addressSuggestion: any[] = [];
    public addedAddress: any[] = [];

    // @ViewChild('zoomedImageElement', {static: false}) zoomedImageElement;
    @ViewChild('originalImageElement', { static: false }) originalImageElement;
    // @ViewChild('renderCanvas', { static: true }) renderCanvas: ElementRef<HTMLCanvasElement>;

    private renderCanvas: ElementRef<HTMLCanvasElement>;
    @ViewChild('renderCanvas', { static: false }) set assetInput(
        elRef: ElementRef
    ) {
        this.renderCanvas = elRef;
    }

    controls: OrbitControls;
    // container;
    private renderer: THREE.WebGLRenderer;
    private camera: THREE.PerspectiveCamera;
    private scene: THREE.Scene;
    private light: THREE.AmbientLight;
    private clock = new THREE.Clock();

    private frameId: number = null;
    mouseX = 0;
    mouseY = 0;
    // windowHalfX = window.innerWidth / 2;
    // windowHalfY = window.innerHeight / 2;

    productBrand:any;
    showSidebar = true;
    status = false;
    copyProdcutMedias: any = null;
    allBookmarks: any = [];
    isBookmarked = false;
    bookmarkAPIRunning = false;
    addToCardAPIRunning = false;
    breadCrumArr: Array<any> = [];
    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.isBrowser = isPlatformBrowser(this.platformId);
        if (this.isBrowser) {
            this.screenHeight = window.innerHeight;
            this.screenWidth = window.innerWidth;

            if (this.screenWidth < 576) {
                this.recommendationsPerSlide = 2;
            } else if (this.screenWidth < 992) {
                this.recommendationsPerSlide = 4;
            } else {
                this.recommendationsPerSlide = 6;
            }
            this.slideIndex = [0, 1, 2, 3, 4, 5, 6].slice(
                0,
                Math.ceil(this.maxRecommendations / this.recommendationsPerSlide)
            );
        }
    }

    ngOnInit() {
        this.tagService._getTags().subscribe((tags: any[]) => {
            this.tags = tags;
            tags.forEach(tag => {
                this.tagDictionary[tag.id] = tag;
            });
        });
        this.isBrowser = isPlatformBrowser(this.platformId);
        if (this.isBrowser) {
            this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
                window.navigator.userAgent
            );
        }
        this.showLoader = true;
        this.isTagIdQueryParams = false;
        this.detectChanges();
        this.route.paramMap.subscribe((params) => {
            if (params['params']) {
                // this.productId = params['params']['productId'];
                this.variantId = params['params']['variantId'];
                this.actionError = null;
                this.fetchProductDetail(this.variantId);
            }
            if (this.isBrowser) {
                this.productShareUrl = this.document.location.href;
            }
        });
        this.copyProdcutMedias = this.product == null ? null : this.product.medias;

        // this.metaService.addTag({name:'description',content: 'how about this'},true)

        if (this.isBrowser) {
            // this.productShareUrl = this.document.location.href;
            // this.tagService.cachedTagsList('brand').pipe(first()).subscribe(result => {
            //     this.allTags = result;
            //     this.cdr.detectChanges();
            // });
            // this.tagService.cachedTagsList('room').pipe(first()).subscribe(result => {
            //     this.roomTags = result;
            //     this.cdr.detectChanges();
            // });
            // this.tagService.cachedTagsList('category').pipe(first()).subscribe(result => {
            //     this.allTags = result;
            //     this.cdr.detectChanges();
            // });
            // this.tagService.cachedTagsList('style').pipe(first()).subscribe(result => {
            //     this.styleTags = result;
            //     this.cdr.detectChanges();
            // });

            // this.tagService.cachedTagsList('material').pipe(first()).subscribe(result => {
            //     this.allTags = result;
            //     this.cdr.detectChanges();
            // });
            // this.tagService.cachedTagsList('colour').pipe(first()).subscribe(result => {
            //     this.allTags = result;
            //     this.cdr.detectChanges();
            // });
            this.errorService.reportModule('products');
            this.onResize();
            // setTimeout(() => {
            //     this.applyZoom();
            // }, 1000);
        }
        setTimeout(() => {
            $(document).ready(function () {
                $('.read_more').click(function () {
                    $('.summary').removeClass('readmore_content');
                });
            });
        }, 2000);

        this.getUserAddedAddress();
    }

    ngAfterViewInit() {
        window.scrollTo(0, 0);
        $('.carousel').carousel();
        // before login we can add item in cart then after login we can see in carts.
        const data = JSON.parse(localStorage.getItem('addToCartData'));
        if (data && this.profileService.isLoggedIn()) {
            this.selectedInventory = data.selectedInventory;
            this.selectedVariant = data.selectedVariant;
            this.addToCart(data.quantity, data.buyNow);
        } else {
            localStorage.removeItem('addToCartData');
        }
    }
    onClickBreadCrumb(val) {
        if (val.tagId) {
            if (this.isTagIdQueryParams) {
                this.router.navigate(['products']);
                setTimeout(() => {
                    this.router.navigate(['products'], { queryParams: { tagId: val.tagId } });
                });
            } else {
                this.router.navigate(['products'], { queryParams: { tagId: val.tagId } });
            }
        } else {
            this.router.navigate(['products']);
        }
    }
    applyZoom() {
        $(document).ready(function () {
            $('.xzoom, .xzoom-gallery').xzoom({
                zoomWidth: 400,
                title: true,
                tint: '#333',
                Xoffset: 15,
            });
        });
    }

    // parseFb() {
    //     const initParams: InitParams = {
    //         appId: environment.facebookAppID,
    //         xfbml: true,
    //         version: 'v2.8'
    //       };

    //       this.fb.init(initParams);
    // }

    // share(url: string) {

    //     let params: UIParams = {
    //       href: url,
    //       method: 'share'
    //     };

    //     this.fb.ui(params)
    //       .then((res: UIResponse) => console.log(res))
    //       .catch((e: any) => console.error(e));

    //   }

    changeVariant(variant: Variant, firstTimeNotLoading?: boolean) {
        if (!variant) {
            this.selectedVariant = this.product.variants[0];
            this.product.variants.forEach((element) => {
                if (element.id === this.product.defaultVariant) {
                    this.selectedVariant = element;
                }
            });
        } else {
            this.selectedVariant = variant;
            if (this.breadCrumArr.length && this.breadCrumArr.length !== 3) {
                this.breadCrumArr.pop();
            }
            this.breadCrumArr.push({
                name: variant.seoTitle,
                tagId: null
            });
            const urlSlug = this.selectedVariant.urlSlug ? this.selectedVariant.urlSlug : this.product.urlSlug;
            const url = ['', this.navigationService.normaliseSlug(urlSlug), 'products', 'd', variant.id].join('/');
            // const url = ['products', 'd', variant.id].join('/');
            this._location.replaceState(url);
        }
        if (this.selectedVariant.sellerInventories && this.selectedVariant.sellerInventories[0].variant.options) {
            this.selectedVariant.selectedOption = this.selectedVariant.sellerInventories[0].variant.options[0];
            if (this.selectedVariant.selectedOption && this.selectedVariant.selectedOption.swatches) {
                this.selectedVariant.selectedSwatch = this.selectedVariant.selectedOption.swatches[0];
            }
        } else if (this.selectedVariant.sellerInventories && this.selectedVariant.sellerInventories[0].variant.swatches) {
            this.selectedVariant.selectedSwatch = this.selectedVariant.sellerInventories[0].variant.swatches[0];
        }
        if (firstTimeNotLoading) {
            this.productMedias = [];
            this.cdr.detectChanges();
        }
        this.productMedias = [
            ...(this.selectedVariant.medias || []),
            ...(this.product.medias || []),
        ];
        if (this.selectedVariant && this.selectedVariant.inventoryReference) {
            this.product.inventoryReference = this.selectedVariant.inventoryReference;
        }
        if (this.selectedVariant.tags) {
            this.selectedVariant.tags.forEach((tag) => {
                this.allTags[tag.tagId] = tag;
                if (this.selectedVariant[tag.type]) {
                    const tags = this.selectedVariant[tag.type] as Array<Tag>;
                    if (tags.findIndex(t => t.tagId == tag.tagId) === -1) {
                        this.selectedVariant[tag.type].push(tag);
                    }
                } else {
                    this.selectedVariant[tag.type] = [];
                    this.selectedVariant[tag.type].push(tag);
                }
            });
            this.selectedVariant.variantSwatchTags = {};
            this.selectedVariant.variantSwatchTags['modified_filter'] = this.selectedVariant.filter ? [...this.selectedVariant.filter] : [];
            this.selectedVariant.variantSwatchTags['modified_style'] = this.selectedVariant.style ? [...this.selectedVariant.style] : [];
            this.selectedVariant.variantSwatchTags['modified_material'] = this.selectedVariant.material ? [...this.selectedVariant.material] : [];
            this.selectedVariant.variantSwatchTags['modified_colour'] = this.selectedVariant.colour ? [...this.selectedVariant.colour] : [];
        }
        this.mainColour = this.getMainProductTag('colour', this.selectedVariant);
        this.mainStyle = this.getMainProductTag('style', this.selectedVariant);
        this.mainMaterial = this.getMainProductTag(
            'material',
            this.selectedVariant
        );
        this.mainFilter = this.getMainProductTag('filter', this.selectedVariant);
        this.product.rrp =
            (this.product.price + this.selectedVariant.surcharge) *
            this.qty *
            this.product.markUp *
            (this.product.vatTax + 1);
        this.product.currentPrice =
            this.product.rrp - this.product.rrp * this.product.promotionalDiscount;
        this.inventoryList = this.selectedVariant.sellerInventories;
        if (this.selectedVariant.sellerInventories) {
            this.selectedInventory = this.selectedVariant.sellerInventories[0];
        }
        // this.changeInventory(this.selectedVariant.sellerInventories[0].sellerId);
        // if (!this.copyProdcutMedias) {
        //     this.copyProdcutMedias = this.product == null ? null : this.product.medias;
        // }
        // if (this.selectedVariant.medias && this.selectedVariant.medias.length) {
        //     this.product.medias = (this.selectedVariant.medias).concat(this.copyProdcutMedias);
        // } else {
        //     this.product.medias = this.copyProdcutMedias;
        // }
        if (this.selectedVariant.selectedSwatch) {
            this.addSwatchTags(this.selectedVariant, this.selectedVariant.selectedSwatch);
        }
        this.addSeoTags(this.product);
        this.updatePricing();
        this.cdr.detectChanges();
    }

    updatePricing() {
        if (this.selectedVariant) {
            this.selectedVariant.rrp = null;
            this.selectedVariant.rrpWithoutDiscount = null;
            console.log(this.selectedVariant.sellerInventories[0])
            if (this.selectedVariant.selectedSwatch || this.selectedVariant.selectedOption) {
                const inventory = this.selectedVariant.sellerInventories[0].variant;
                if (this.selectedVariant.selectedSwatch && this.selectedVariant.selectedOption) {
                    // Both
                    const option = this.findInArray(inventory.options, 'id', this.selectedVariant.selectedOption.id);
                    const swatch = this.findInArray(option.swatches, 'id', this.selectedVariant.selectedSwatch.id);
                    this.selectedVariant.rrp = swatch.rrp;
                    this.selectedVariant.rrpWithoutDiscount = swatch.rrpWithoutDiscount;
                } else if (this.selectedVariant.selectedOption) {
                    // Only Option
                    const option = this.findInArray(inventory.options, 'id', this.selectedVariant.selectedOption.id);
                    this.selectedVariant.rrp = option.rrp;
                    this.selectedVariant.rrpWithoutDiscount = option.rrpWithoutDiscount;
                } else {
                    // Only Swatches
                    const swatch = this.findInArray(inventory.swatches, 'id', this.selectedVariant.selectedSwatch.id);
                    this.selectedVariant.rrp = swatch.rrp;
                    this.selectedVariant.rrpWithoutDiscount = swatch.rrpWithoutDiscount;
                }
            }
            else if (this.selectedVariant.sellerInventories && this.selectedVariant.sellerInventories[0].variant && this.selectedVariant.sellerInventories[0].variant.rrp) {
                this.selectedVariant.rrp = this.selectedVariant.sellerInventories[0].variant.rrp;
                this.selectedVariant.rrpWithoutDiscount = this.selectedVariant.sellerInventories[0].variant.rrpWithoutDiscount;
            }
            console.log(this.selectedVariant)
        }
    }

    private findInArray(arr: any[], prop, value) {
        const index = this.findIndexInArray(arr, prop, value);
        return index == -1 ? null : arr[index];
    }

    private findIndexInArray(arr: any[], prop, value) {
        if (arr && arr.length > 0) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i][prop] == value) {
                    return i;
                }
            }
        }
        return -1;
    }

    changeSwatch(swatch) {
        this.selectedVariant.selectedSwatch = swatch;
        this.addSwatchTags(this.selectedVariant, this.selectedVariant.selectedSwatch);
        this.updatePricing();
    }

    changeOption(option) {
        this.selectedVariant.selectedOption = option;
        this.selectedVariant.selectedOption && (this.selectedVariant.selectedOption.swatches = this.selectedVariant.selectedOption.swatches);
        if (this.selectedVariant.selectedOption.swatches) {
            this.selectedVariant.selectedSwatch = this.selectedVariant.selectedOption.swatches[0];
            this.addSwatchTags(this.selectedVariant, this.selectedVariant.selectedSwatch);
        }
        this.updatePricing();
    }

    addSwatchTags(selectedVariant, swatch) {
        selectedVariant.variantSwatchTags = {};
        const swatchComponentIds = this.product.swatchComponents ? this.product.swatchComponents.map(sc => sc.id) : [];
        if (selectedVariant.filter) {
            selectedVariant.variantSwatchTags['modified_filter'] = [...(selectedVariant.filter.filter(f => {
                const variantTagComponentIds = f.variantTagComponents ? f.variantTagComponents.map(vtc => vtc.componentId) : [];
                return (variantTagComponentIds.length && swatchComponentIds.length) ? !variantTagComponentIds.some(c => swatchComponentIds.includes(c)) : true;
            }) || [])];
        } else {
            selectedVariant.variantSwatchTags['modified_filter'] = [];
        }
        if (selectedVariant.style) {
            selectedVariant.variantSwatchTags['modified_style'] = [...(selectedVariant.style.filter(f => {
                const variantTagComponentIds = f.variantTagComponents ? f.variantTagComponents.map(vtc => vtc.componentId) : [];
                return (variantTagComponentIds.length && swatchComponentIds.length) ? !variantTagComponentIds.some(c => swatchComponentIds.includes(c)) : true;
            }) || [])];
        } else {

        }
        if (selectedVariant.material) {
            selectedVariant.variantSwatchTags['modified_material'] = [...(selectedVariant.material.filter(f => {
                const variantTagComponentIds = f.variantTagComponents ? f.variantTagComponents.map(vtc => vtc.componentId) : [];
                return (variantTagComponentIds.length && swatchComponentIds.length) ? !variantTagComponentIds.some(c => swatchComponentIds.includes(c)) : true;
            }) || [])];
        } else {
            selectedVariant.variantSwatchTags['modified_material'] = [];
        }
        if (selectedVariant.colour) {
            selectedVariant.variantSwatchTags['modified_colour'] = [...(selectedVariant.colour.filter(f => {
                const variantTagComponentIds = f.variantTagComponents ? f.variantTagComponents.map(vtc => vtc.componentId) : [];
                return (variantTagComponentIds.length && swatchComponentIds.length) ? !variantTagComponentIds.some(c => swatchComponentIds.includes(c)) : true;
            }) || [])];
        } else {
            selectedVariant.variantSwatchTags['modified_colour'] = [];
        }
        if (swatch && swatch.tags && swatch.tags.length) {
            swatch.tags.forEach((tag) => {
                switch (tag.tagType.toLowerCase()) {
                    case 'brand':
                        selectedVariant.variantSwatchTags['modified_brands'].push(tag);
                        break;
                    case 'category':
                        selectedVariant.variantSwatchTags['modified_category'].push(tag);
                        break;
                    case 'room':
                        selectedVariant.variantSwatchTags['modified_room'].push(tag);
                        break;
                    case 'filter':
                        selectedVariant.variantSwatchTags['modified_filter'].push(tag);
                        break;
                    case 'style':
                        selectedVariant.variantSwatchTags['modified_style'].push(tag);
                        break;
                    case 'material':
                        selectedVariant.variantSwatchTags['modified_material'].push(tag);
                        break;
                    case 'colour':
                        selectedVariant.variantSwatchTags['modified_colour'].push(tag);
                        break;
                }
            });
            selectedVariant.variantSwatchTags.modified_filter = selectedVariant.variantSwatchTags.modified_filter.filter((t) => t.tagId.includes('pattern')) || [];
        }
    }

    removeUnderScore(status: string) {
        return status.split('_').join(' ');
    }

    goBack(searchType: string, searchValue: string) {
        // this.productService.clearSearchParameters();
        const obj = this.productService.restoreProductSearch();
        if (searchType && searchValue) {
            const newObj = {
                // storeId: obj['storeId'],
            };
            newObj[searchType] = searchValue;
            this.productService.saveProductSearch(newObj);
            // this.productService.updateProductSearch(searchType, searchValue, true);
        }
        // if (obj && obj.storeId === 'p') {
        //     this.navigationService.navigatePersonalisedStore(null);
        // } else {
        //     this.navigationService.navigateGeneralStore();
        // }
        this.navigationService.navigateGeneralStore();
    }
    getSafeJsonLd(product: Product) {
        if (!product) {
            return {};
        }

        const breadcrumb = {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
                {
                    '@type': 'ListItem',
                    position: 1,
                    item: {
                        '@id':
                            environment.baseHref + this.navigationService.urlGeneralStore(),
                        name: 'Store',
                    },
                },
            ],
        };
        if (product.category) {
            product.category.forEach((catId, i) => {
                if (this.allTags[catId]) {
                    breadcrumb.itemListElement.push({
                        '@type': 'ListItem',
                        position: i + 1,
                        item: {
                            '@id':
                                environment.baseHref +
                                this.navigationService.urlCategoryStore(catId),
                            name: this.allTags[catId].name,
                        },
                    });
                }
            });
        }
        breadcrumb.itemListElement.push({
            '@type': 'ListItem',
            position: breadcrumb.itemListElement.length,
            item: {
                '@id': environment.baseHref + this.router.url,
                name: this.product.seo_title || this.product.name,
            },
        });

        const jsonld = {
            '@context': 'http://schema.org',
            '@type': 'Product',
            // url: this.document.location.href,
            url: this.baseHref + this.router.url,
            name: product.seo_title || product.name,

            brand:
                product.brand && product.brand[0] && this.allTags[product.brand]
                    ? this.allTags[product.brand].name
                    : '',
            material:
                product.material &&
                    product.material[0] &&
                    this.allTags[product.material[0]]
                    ? this.allTags[product.material[0]].name
                    : '',
            color:
                product.colour && product.colour[0] && this.allTags[product.colour[0]]
                    ? this.allTags[product.colour[0]].name
                    : '',
            height: product.heightDim,
            width: product.widthDim,
            image: product.mainPicture,
            productID: product.inventoryReference,
            description:
                product.seo_description ||
                this.utilService.htmlToPlaintext(product.productSummary),
            category:
                product.category &&
                    product.category[0] &&
                    this.allTags[product.category[0]]
                    ? this.allTags[product.category[0]].name
                    : '',
        };
        if (product.numberReviews > 0) {
            jsonld['aggregateRating'] = {
                '@type': 'aggregateRating',
                ratingValue: product.averageReview,
                reviewCount: product.numberReviews,
                bestRating: 5,
                worstRating: 0,
            };
        }
        if (this.inventoryList && this.selectedInventory) {
            jsonld['offers'] = {
                '@type': 'Offer',
                priceCurrency: 'GBP',
                price: this.selectedInventory.actualPrice,
                availability: 'http://schema.org/InStock',
                priceValidUntil: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
                url: environment.baseHref + this.router.url,

                seller: {
                    '@type': 'Organization',
                    name: this.selectedInventory.sellerName,
                },
            };
        }
        if (this.reviews && this.reviews.length > 1) {
            jsonld['review'] = this.reviews.map((x) => {
                return {
                    '@type': 'Review',
                    reviewBody: x.comment,
                    reviewRating: {
                        '@type': 'Rating',
                        ratingValue: x.rating,
                        bestRating: 5,
                        worstRating: 0,
                    },
                };
            });
        }
        return [jsonld, breadcrumb];
    }
    getMainProductTag(type: string, product: any): string {
        // if (['category', 'colour', 'material', 'style'].indexOf(type) < 0) { return null; }
        let cat: string = null;
        // if (!product[type] || product[type].length < 1) { return null; }
        // product[type].forEach(catId => {
        //     if (!cat || catId.indexOf(cat) >= 0) { cat = catId; }
        // });
        for (let i = 0; i < product.tags.length; i++) {
            const tag = product.tags[i];
            if (tag.type === type) {
                cat = tag.tagId;
                break;
            }
        }
        return cat || '';
    }

    getMainMultipleTags(type: string, product: Variant): string[] {
        if (
            ['category', 'colour', 'material', 'style', 'filter'].indexOf(type) < 0
        ) {
            return [];
        }
        if (!this.selectedVariant[type] || this.selectedVariant[type].length < 1) {
            return [];
        }

        // return this.selectedVariant[type].filter((catId, i, list) => {

        //     const p = list.find((x, j) => x.indexOf(catId) >= 0 && i !== j);
        //     return !p;
        // });
        return this.selectedVariant[type].map((e) => e.tagId);
    }

    changeInventory(sellerId: string) {
        this.selectedInventory = null;
        this.inventoryList.forEach((element) => {
            if (
                this.selectedVariant &&
                element.variantId === this.selectedVariant.id &&
                (!sellerId || element.sellerId === sellerId) &&
                (!this.selectedInventory ||
                    element.actualPrice + element.deliveryCharges <
                    this.selectedInventory.actualPrice +
                    this.selectedInventory.deliveryCharges)
            ) {
                this.selectedInventory = element;
            }
        });
    }

    // adjustZoomedImage(event: MouseEvent) {
    //     // if (zoomedImage) this.zoomedImage = zoomedImage;
    //     if (!this.zoomedImageElement) {
    //         return;
    //     } else if (!this.blowUpFactorX || !this.blowUpFactorY) {
    //         this.blowUpFactorX =
    //             this.zoomedImageElement.nativeElement.clientWidth /
    //                  this.originalImageElement.nativeElement.clientWidth * this.zoomFactor ;
    //         this.blowUpFactorY =
    //             this.zoomedImageElement.nativeElement.clientHeight /
    // this.originalImageElement.nativeElement.clientHeight * this.zoomFactor;
    //     }

    //     this.zoomedImageX = ((this.zoomedImageElement.nativeElement.clientWidth / 2) - (event.offsetX * this.blowUpFactorX  )) + 'px' ;
    //     this.zoomedImageY = ((this.zoomedImageElement.nativeElement.clientHeight / 2) - (event.offsetY * this.blowUpFactorY )) + 'px' ;

    // }

    addToCart(quantity: number, buyNow: boolean) {
        if (!this.profileService.isLoggedIn()) {
            this.profileService.gotoLogin();
            const data = {
                quantity: quantity,
                buyNow: buyNow,
                selectedVariant: this.selectedVariant,
                selectedInventory: this.selectedInventory
            };
            localStorage.setItem('addToCartData', JSON.stringify(data));
        } else {
            if (!this.selectedVariant || !this.selectedInventory) {
                this.actionError = new DisplayError(
                    'error',
                    'Item not available for sale',
                    '',
                    this.cdr
                );
                this.cdr.detectChanges();
                return;
            }

            // if (this.isBrowser) {
            //     const gtmProduct = this.productService.getGTMProduct(this.product);
            //     gtmProduct.quantity = quantity;
            //     let data = <GTMEcommerceData>{
            //         event: 'addToCart',
            //         ecommerce: {
            //           currencyCode: 'GBP',
            //           add: {
            //             products: [gtmProduct]
            //           }
            //         }
            //     }
            //     this.utilService.addDataLayer(data);
            // }

            const variantId = this.selectedVariant.id;
            const sellerId = this.selectedInventory.sellerId;
            const optionId = (this.selectedVariant.selectedOption) ? this.selectedVariant.selectedOption.id : undefined;
            const swatchId = (this.selectedVariant.selectedSwatch) ? this.selectedVariant.selectedSwatch.id : undefined;
            if (
                this.selectedInventory.maxOrderSize &&
                quantity > this.selectedInventory.maxOrderSize
            ) {
                this.actionError = new DisplayError(
                    'error',
                    'Quantity exceeds maximum allowed order size of ' +
                    this.selectedInventory.maxOrderSize,
                    '',
                    this.cdr
                );
                this.cdr.detectChanges();
                return;
            }
            // if (!this.selectedInventory.activeFlag) {
            //     this.actionError = new DisplayError('error', 'Sorry, this item is marked as temporarily unavailable for sale', '', this.cdr);
            //     this.cdr.detectChanges();
            //     return;
            // }

            this.errorService.reportBusy(1);
            this.addToCardAPIRunning = true;
            this.cdr.detectChanges();
            this.cartService
                .addToCart(this.productId, variantId, sellerId, quantity, optionId, swatchId)
                .subscribe({
                    next: (result: CartResponse) => {
                        this.errorService.reportBusy(-1);
                        this.addToCardAPIRunning = false;
                        const data = JSON.parse(localStorage.getItem('addToCartData'));
                        if (data) {
                            localStorage.removeItem('addToCartData');
                        }
                        this.actionError = new DisplayError(
                            'success',
                            'Added to cart',
                            '',
                            this.cdr
                        );
                        this.cdr.detectChanges();


                        this.addDataLayer(
                            'addToCart',
                            [this.product],
                            <GTMProduct>{ quantity: quantity },
                            () => {
                                if (buyNow) {
                                    // location.assign('/purchase/cart');
                                    this.navigationService.navigateToCart();
                                }
                                let msg;
                                if (result.message) {
                                    msg = new DisplayError('success', result.message, '', this.cdr);
                                } else {
                                    msg = new DisplayError(
                                        'success',
                                        'Added to cart',
                                        '',
                                        this.cdr
                                    );
                                }

                                // this.actionError = msg;
                                this.cdr.detectChanges();
                            }
                        );


                    },
                    error: (err) => {
                        let msg = '';
                        if (err.error && err.error.message) {
                            msg = err.error.message;
                        } else {
                            msg = 'Something went wrong!!';
                        }
                        this.actionError = {
                            type: 'error',
                            title: '',
                            message: msg,
                            setAliveTime: 5000
                        };
                        this.addToCardAPIRunning = false;
                        this.cdr.detectChanges();
                    },
                });
        }
    }
    addSeoTags(product: Product) {
        this.titleService.setTitle(
            (this.selectedVariant.metaTitle || product.name) + ' | Moodmaestro'
        );
        this.metaService.addTags(
            [
                { property: 'og:url', content: this.baseHref + this.router.url },
                { property: 'og:type', content: 'variant' },
                { property: 'og:title', content: (this.selectedVariant.metaTitle || this.product.name) },
                { property: 'og:image', content: this.selectedVariant.mainThumbnail || this.product.mainThumbnail },
                // {
                //     property: 'og:description',
                //     content: this.selectedVariant.description || this.product.seo_description || this.product.name,
                // },
                // {
                //     name: 'description',
                //     content: this.selectedVariant.seoDescription || this.product.seo_description || this.product.name,
                // },
            ],
            true
        );
        if (this.selectedVariant.seoDescription) {
            this.metaService.updateTag({ name: 'description', content: this.selectedVariant.seoDescription });
        }
        this.addDataLayer('detail', [product]);
        this.cdr.detectChanges();
    }

    // getUrlEncodedDescription(product: Product): string {
    //     return encodeURIComponent(`${product.name}${product.seo_description ? ': ' + product.seo_description : ''} | Moodmaestro`);
    // }

    fetchProductDetail(productId: string) {
        console.log(productId);
        const productDetailKey = makeStateKey(`detail:${this.variantId}`);

        const result = this.state.get(productDetailKey, null as any);

        if (!result) {
            this.errorService.reportBusy(1);
            const arr = [
                this.productService.getProductsDetails(this.variantId)
            ];
            this.showLoader = true;
            this.detectChanges();
            forkJoin(arr).subscribe(
                (res: any) => {
                    this.errorService.reportBusy(-1);
                    const productResponse = res[0];

                    this.allBookmarks = this.profileService.isLoggedIn() && res[1] && res[1].data;

                    if (this.allBookmarks && this.allBookmarks.length) {
                        this.allBookmarks.forEach((bookmark) => {
                            if (bookmark && bookmark.products == this.productId) {
                                this.isBookmarked = true;
                                return true;
                            }
                        });
                    }

                    this.state.set(productDetailKey, productResponse);
                    this.handleResponse(productResponse);
                    this.showLoader = false;
                    this.detectChanges();
                },
                (err) => {
                    this.showLoader = false;
                    this.detectChanges();
                    this.errorService.reportBusy(-1);
                    if (err.error && err.error.message) {
                        this.errorService.reportError(
                            new DisplayError('error', err.error.message, '', this.cdr)
                        );
                    } else {
                        this.errorService.reportError(
                            new DisplayError('error', 'Server error', '', this.cdr)
                        );
                    }
                }
            );
        } else {
            this.handleResponse(result);
        }
    }

    async handleResponse(result: Product) {
        this.product = JSON.parse(JSON.stringify(result));
        const findValue = this.findPropertyValue(this.product.variants, 'inventoryStatus');
        this.product['priceShow'] = true;
        if (findValue === 'order_by_email') {
            this.product['priceShow'] = false;
        }
        this.productId = this.product.productId;
        if (this.product.tags) {
            const breadCrumArr = [];
            const category = this.product.tags.find(f => f.type == 'category');
            breadCrumArr.push({
                name: 'All Products',
                tagId: null
            });
            if (category.parent && category.parentName) {
                breadCrumArr.push({
                    tagId: category.parent,
                    name: category.parentName
                });
            }
            breadCrumArr.push({
                tagId: category.tagId,
                name: category.name
            });

            this.breadCrumArr = breadCrumArr;
        }
        if (this.product.tags) {
            this.product.tags.forEach((tag) => {
                this.allTags[tag.tagId] = tag;
                if(tag.type == 'brand' && this.product.brand == tag.tagId){
                    this.productBrand = tag;
                }
            });
        }
        if (this.product.reviews) {
            this.reviews = (this.product.reviews && this.product.reviews.list) || [];
        }
        const filterSelectedVariant = this.product.variants.find(v => v.id == this.variantId);
        // this.inventoryList = this.product.inventory && this.product.inventory.list || [];
        this.changeVariant(filterSelectedVariant);

        this.jsonLd = this.getSafeJsonLd(this.product);
        // this.fetchRecommendations();
        // this.fetchAssociatedProducts();

        if (this.isBrowser) {
            this.storageService.recordProductEvent([new ProductEvent(this.product)]);
            this.selectedDisplayImage = this.product.mainPicture;
            // this.selectedDisplayIndex = 0;
            this.fetchProductBookmark();
            this.mainCategory = this.getMainProductTag('category', this.product);
            this.mainRoom = this.getMainProductTag('room', this.product);
            window.scrollTo(0, 0);
        }
        this.showLoader = false;
        this.cdr.detectChanges();
    }

    fetchProductBookmark() {
        const favourites = this.localStorage.getItem('favourites/products');
        if (favourites && favourites !== '') {
            try {
                const favouritesJson = JSON.parse(favourites);
                favouritesJson.forEach((element) => {
                    if (element.itemId === this.product._id) {
                        this.product.isBookmarked = true;
                    }
                });
            } catch (e) { }
        } else {
            this.profileService.getFavourites('products').subscribe({
                next: (result: FavouritesResponse) => {
                    this.localStorage.setItem(
                        'favourites/products',
                        JSON.stringify(result.data)
                    );
                    result.data.forEach((element) => {
                        if (element.itemId === this.product._id) {
                            this.product.isBookmarked = true;
                        }
                    });
                    this.cdr.detectChanges();
                },
                error: (err) => { },
            });
        }
    }

    addToBookmarks(productId: string) {
        if (!this.profileService.isLoggedIn()) {
            this.errorService.reportError(
                new DisplayError('error', 'You must login', '', this.cdr)
            );
            this.profileService.gotoLogin();
            return;
        }
        this.errorService.reportBusy(1);
        this.profileService.addFavourites('products', productId).subscribe({
            next: (result: StringResponse) => {
                this.errorService.reportBusy(-1);
                this.profileService.clearCachedFavourites('products');

                this.product.isBookmarked = true;
                this.addDataLayer('addBookmark', [this.product]);

                let msg;
                if (result.message) {
                    msg = new DisplayError('success', result.message, '', this.cdr);
                } else {
                    msg = new DisplayError('success', 'Added to bookmarks', '', this.cdr);
                }
                this.actionError = msg;
                this.cdr.detectChanges();
            },
            error: (err) => {
                this.errorService.reportBusy(-1);
                let msg;
                if (err.error && err.error.message) {
                    msg = new DisplayError('error', err.error.message, '', this.cdr);
                } else {
                    msg = new DisplayError(
                        'error',
                        'Error adding to bookmarks',
                        '',
                        this.cdr
                    );
                }
                this.actionError = msg;
                this.cdr.detectChanges();
            },
        });
    }
    removeBookmarks(productId: string) {
        if (!this.profileService.isLoggedIn()) {
            this.errorService.reportError(
                new DisplayError('error', 'You must login', '', this.cdr)
            );
            this.profileService.gotoLogin();

            return;
        }
        this.errorService.reportBusy(1);
        this.profileService.removeFavourite('products', productId).subscribe({
            next: (result: StringResponse) => {
                this.errorService.reportBusy(-1);
                // this.localStorage.setItem('bookmarks/products', '');
                this.profileService.clearCachedFavourites('products');
                this.product.isBookmarked = false;

                this.addDataLayer('removeBookmark', [this.product]);

                let msg;
                if (result.message) {
                    msg = new DisplayError('success', result.message, '', this.cdr);
                } else {
                    msg = new DisplayError(
                        'success',
                        'Removed from bookmarks',
                        '',
                        this.cdr
                    );
                }
                this.actionError = msg;
                this.cdr.detectChanges();
            },
            error: (err) => {
                this.errorService.reportBusy(-1);
                let msg;
                if (err.error && err.error.message) {
                    msg = new DisplayError('error', err.error.message, '', this.cdr);
                } else {
                    msg = new DisplayError(
                        'error',
                        'Error removing from bookmarks',
                        '',
                        this.cdr
                    );
                }
                this.actionError = msg;
                this.cdr.detectChanges();
            },
        });
    }

    // fetchProductReviews(productId: string) {
    //     this.reviewService.getReviews('products', productId, 100, 0)
    //     .subscribe({
    //         next: (result: ReviewListResponse) => {
    //             this.reviews = result.data.list;
    //             this.cdr.detectChanges();
    //         },
    //         error: err => {

    //         }
    //     });
    // }

    fetchRecommendations() {
        const productRecmKey = makeStateKey(`recm:${this.productId}`);
        const result = this.state.get(productRecmKey, null as any);

        if (!result) {
            const productEvent = new ProductEvent(this.product);
            this.errorService.reportBusy(1);

            this.productService
                .getRecommmedations([productEvent], this.maxRecommendations, 0, null)
                .subscribe(
                    (recmResult: ProductListResponse) => {
                        this.errorService.reportBusy(-1);
                        this.recommendations = recmResult.data.list;
                        if (this.isBrowser) {
                            this.addDataLayer('impressions', recmResult.data.list);
                            this.slideIndex = [0, 1, 2, 3, 4, 5, 6].slice(
                                0,
                                Math.ceil(
                                    this.recommendations.length / this.recommendationsPerSlide
                                )
                            );
                        } else {
                            this.state.set(productRecmKey, recmResult);
                        }
                        this.cdr.detectChanges();
                        this.applySliderJquerys();
                        this.quntity();
                        this.listVariants();
                        this.listMaterial();
                    },
                    (err) => {
                        this.errorService.reportBusy(-1);
                    }
                );
        } else {
            this.recommendations = result.data.list;
            if (this.isBrowser) {
                this.addDataLayer('impressions', result.data.list);
                this.slideIndex = [0, 1, 2, 3, 4, 5, 6].slice(
                    0,
                    Math.ceil(this.recommendations.length / this.recommendationsPerSlide)
                );
            } else {
                this.state.set(productRecmKey, result);
            }
            this.cdr.detectChanges();
        }
    }

    applySliderJquerys() {
        // $(document).ready(() => {
        setTimeout(() => {
            $('.product-detail-img').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                fade: true,
                asNavFor: '.product-img-list',
                dots: false,
            });

            $('.product-img-list').slick({
                slidesToShow: 12,
                slidesToScroll: 1,
                asNavFor: '.product-detail-img-main',
                dots: false,
                centerMode: false,
                focusOnSelect: true,
                arrows: false,
                centerPadding: '0',
                autoplay: true,
                swipeToSlide: true,
                vertical: true,
                infinite: false,
            });
        }, 0);

        // });
    }

    listVariants() {
        $('.variant-list-main').on('click', '.init', function () {
            $(this).closest('.variant-list-main').children('li:not(.init)').toggle();
        });

        const allOptions = $('.variant-list-main').children('li:not(.init)');
        $('.variant-list-main').on('click', 'li:not(.init)', function () {
            allOptions.removeClass('selected');
            $(this).addClass('selected');
            $('.variant-list-main').children('.init').html($(this).html());
            allOptions.toggle();
        });
    }

    listMaterial() {
        $('.material-list-main').on('click', '.init', function () {
            $(this).closest('.material-list-main').children('li:not(.init)').toggle();
        });

        const allOptions = $('.material-list-main').children('li:not(.init)');
        $('.material-list-main').on('click', 'li:not(.init)', function () {
            allOptions.removeClass('selected');
            $(this).addClass('selected');
            $('.material-list-main').children('.init').html($(this).html());
            allOptions.toggle();
        });
    }

    quntity() {
        $(document).ready(function () {
            $('.count').prop('disabled', true);
            $(document).on('click', '.plus', function () {
                $('.count').val(parseInt($('.count').val()) + 1);
            });
            $(document).on('click', '.minus', function () {
                $('.count').val(parseInt($('.count').val()) - 1);
                if ($('.count').val() == 0) {
                    $('.count').val(1);
                }
            });
        });
    }

    fetchAssociatedProducts() {
        if (
            !this.product.associatedProducts ||
            this.product.associatedProducts.length <= 0
        ) {
            return;
        }
        const searchJson = { _id: { _in_list: this.product.associatedProducts } };

        const productAssocProdKey = makeStateKey(`assocprod:${this.productId}`);
        const result = this.state.get(productAssocProdKey, null as any);

        if (!result) {
            const productEvent = new ProductEvent(this.product);
            this.errorService.reportBusy(1);

            this.productService
                .getProductsList(searchJson, this.maxRecommendations, 0, '')
                .subscribe(
                    (assocResult) => {
                        this.errorService.reportBusy(-1);
                        this.associatedProducts = assocResult.data.list;
                        if (this.isBrowser) {
                            this.slideIndexAssoc = [0, 1, 2, 3, 4, 5, 6].slice(
                                0,
                                Math.ceil(
                                    this.associatedProducts.length / this.recommendationsPerSlide
                                )
                            );
                        } else {
                            this.state.set(productAssocProdKey, assocResult);
                        }
                        this.cdr.detectChanges();
                    },
                    (err) => {
                        this.errorService.reportBusy(-1);
                    }
                );
        } else {
            this.associatedProducts = result.data.list;
            if (this.isBrowser) {
                this.addDataLayer('impressions', result.data.list);
                this.slideIndexAssoc = [0, 1, 2, 3, 4, 5, 6].slice(
                    0,
                    Math.ceil(
                        this.associatedProducts.length / this.recommendationsPerSlide
                    )
                );
            } else {
                this.state.set(productAssocProdKey, result);
            }
            this.cdr.detectChanges();
        }
    }

    orderByEmail() {
        if (!this.auth.isLoggedIn()) this.auth.gotoLogin();
        else {
            this.inItEmailEnqiryForm()
            this.showEmailInfo = true;
            this.cdr.detectChanges();
        }
    }

    openProductDetails(product: Product) {
        this.addDataLayer('productClick', [product], <GTMProduct>{}, () => {
            this.navigationService.openProductDetail(product);
        });
    }
    openSellerProfile(sellerId: string) {
        this.navigationService.openSellerProfile(sellerId);
    }

    addDataLayer(
        type:
            | 'impressions'
            | 'productClick'
            | 'addToCart'
            | 'detail'
            | 'removeBookmark'
            | 'addBookmark',
        list: Product[],
        options?: GTMProduct,
        callback?: () => void
    ) {
        if (this.isBrowser) {
            const gtmProducts = list.map((x, i) => {
                return this.productService.getGTMProduct(x, options);
            });
            window['dataLayer'] = window['dataLayer'] || [];

            switch (type) {
                case 'impressions':
                    window['dataLayer'].push(<GTMEcommerceData>{
                        event: 'productImpression',
                        ecommerce: Object.assign(
                            this.productService.getNewDataLayer().ecommerce,
                            {
                                currencyCode: 'GBP',
                                impressions: gtmProducts,
                            }
                        ),
                    });
                    break;
                case 'productClick':
                    window['dataLayer'].push(<GTMEcommerceData>{
                        event: 'promotionClick',
                        ecommerce: Object.assign(
                            this.productService.getNewDataLayer().ecommerce,
                            {
                                promoClick: {
                                    promotions: [
                                        {
                                            id: 'product-detail-recommendations',
                                        },
                                    ],
                                },
                            }
                        ),
                    });
                    window['dataLayer'] = window['dataLayer'] || [];
                    window['dataLayer'].push(<GTMEcommerceData>{
                        event: 'productClick',
                        ecommerce: Object.assign(
                            this.productService.getNewDataLayer().ecommerce,
                            {
                                click: {
                                    products: gtmProducts,
                                },
                            }
                        ),
                        eventCallback: callback,
                    });
                    break;
                case 'addToCart':
                    window['dataLayer'].push(<GTMEcommerceData>{
                        event: 'addToCart',
                        ecommerce: Object.assign(
                            this.productService.getNewDataLayer().ecommerce,
                            {
                                currencyCode: 'GBP',
                                add: {
                                    products: gtmProducts,
                                },
                            }
                        ),
                        eventCallback: callback,
                    });

                    break;
                case 'detail':
                    window['dataLayer'].push(<GTMEcommerceData>{
                        event: 'productDetail',
                        ecommerce: Object.assign(
                            this.productService.getNewDataLayer().ecommerce,
                            {
                                currencyCode: 'GBP',
                                detail: {
                                    products: gtmProducts,
                                },
                            }
                        ),
                    });
                    break;
                case 'removeBookmark':
                    window['dataLayer'].push(<GTMEcommerceData>{
                        event: 'removeBookmark',
                        ecommerce: Object.assign(
                            this.productService.getNewDataLayer().ecommerce,
                            {
                                currencyCode: 'GBP',
                                detail: {
                                    products: gtmProducts,
                                },
                                impressions: undefined,
                            }
                        ),
                        eventCallback: callback,
                    });
                    break;
                case 'addBookmark':
                    window['dataLayer'].push(<GTMEcommerceData>{
                        event: 'addBookmark',
                        ecommerce: Object.assign(
                            this.productService.getNewDataLayer().ecommerce,
                            {
                                currencyCode: 'GBP',
                                detail: {
                                    products: gtmProducts,
                                },
                                impressions: undefined,
                            }
                        ),
                        eventCallback: callback,
                    });
                    break;
                // case 'socialShare':
                //     window['dataLayer'].push(<GTMEcommerceData>{
                //         event: 'socialShare',
                //         ecommerce: Object.assign(this.productService.getNewDataLayer().ecommerce, {
                //             currencyCode: 'GBP',
                //             detail: {
                //                 products: gtmProducts,
                //             },
                //             impressions: [],
                //         }),
                //         eventCallback: callback
                //     });
                //     break;

                default:
                    break;
            }
        }
    }

    openBrandStoreUrl(brand: string) {
        this.router.navigate(['/products', `brand-${this.tagDictionary[brand].urlSlug}`]);
        // this.router.navigate(['products'], {
        //     queryParams: { tagId: brand.tagId },
        // });
    }

    openStyleStoreUrl(style: any) {
        this.router.navigate(['/products', `style-${this.tagDictionary[style.tagId].urlSlug}`]);
        // this.router.navigate(['products'], {
        //     queryParams: { tagId: style.tagId },
        // });
    }

    get3DUrl(): string {
        let fbxUrl = <string>null;
        if (this.product.media && Array.isArray(this.product.media)) {
            this.product.media.forEach((element) => {
                if (
                    element &&
                    element.url &&
                    element.url &&
                    element.url.match(/\.fbx$/i)
                ) {
                    fbxUrl = element.url;
                }
            });
        }
        return fbxUrl;
    }

    renderProduct3D() {
        const fbxUrl = this.get3DUrl();
        if (!fbxUrl) {
            return;
        }
        this.show3D = true;
        this.cdr.detectChanges();

        this.camera = new THREE.PerspectiveCamera(
            50,
            window.innerWidth / window.innerHeight,
            1,
            10000
        );

        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color(0xffffff);

        this.light = new THREE.HemisphereLight(0xffffff, 0x444444);
        this.light.position.set(0, 200, 0);
        this.scene.add(this.light);

        this.light = new THREE.DirectionalLight(0xffffff);
        this.light.position.set(0, 200, 100);
        this.light.castShadow = true;
        this.light.shadow.camera.top = 180;
        this.light.shadow.camera.bottom = -100;
        this.light.shadow.camera.left = -120;
        this.light.shadow.camera.right = 120;
        this.scene.add(this.light);

        const manager = new THREE.LoadingManager();
        manager.onStart = () => {
            this.errorService.reportBusy(1);
        };
        manager.onLoad = () => {
            this.errorService.reportBusy(-1);
        };
        manager.onError = (url, err) => {
            if (url === fbxUrl) {
                this.err3D = 'Error loading the 3D file ';
                this.cdr.detectChanges();
            }
        };

        const loader = new FBXLoader(manager);
        loader.load(fbxUrl, (object) => {
            this.errorService.reportBusy(-1);

            object.traverse((child) => {
                if (child instanceof THREE.Mesh) {
                    const mesh = child as THREE.Mesh;
                    if (mesh.material) {
                        mesh.material = new THREE.MeshPhongMaterial({
                            color: mesh.material.color,
                            // wireframe: true,
                            vertexColors: THREE.VertexColors,
                        });
                    }
                }
            });

            const boundingBox = new THREE.Box3().setFromObject(object);

            const size = new THREE.Vector3();
            boundingBox.getSize(size); // Returns Vector3

            const distance = Math.max(size.x, size.y, size.z);
            this.camera.position.set(0, distance, 2 * distance);
            object.translateY(0.5 * size.y);

            // ground
            // const ground = distance * 3;
            // const mesh = new THREE.Mesh( new THREE.PlaneBufferGeometry( ground, ground ),
            // new THREE.MeshPhongMaterial( { color: 0x999999, depthWrite: false } ) );
            // mesh.rotation.x = - Math.PI / 2;
            // mesh.receiveShadow = true;
            // this.scene.add( mesh );

            // grid
            // const grid = new THREE.GridHelper( ground, 10, 0x000000, 0x000000 );
            // grid.material.opacity = 0.2;
            // grid.material.transparent = true;
            // this.scene.add( grid );

            // animate
            const animateObj = object as any;
            if (
                animateObj.animations &&
                Array.isArray(animateObj.animations) &&
                animateObj.animations.length > 0
            ) {
                this.mixer = new THREE.AnimationMixer(animateObj);
                this.action = this.mixer.clipAction(animateObj.animations[0]);
                this.action.play();
                this.animate();
            }

            object.visible = true;
            this.scene.add(object);

            this.controls = new OrbitControls(this.camera, this.renderer.domElement);
            this.controls.target.set(0, 100, 0);
            this.controls.update();

            this.render();
            this.show3D = true;
        });

        const canvas = this.renderCanvas.nativeElement;

        this.renderer = new THREE.WebGLRenderer({
            canvas: canvas,
            //   alpha: 0.4,
        });

        // const ctx = this.renderer.getContext("2d");
        // ctx.fillStyle = "blue";
        // ctx.fillRect(0, 0, canvas.width, canvas.height);

        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.setClearColor(0xffffff, 1);

        this.renderer.shadowMap.enabled = true;

        window.addEventListener(
            'resize',
            () => {
                this.camera.aspect = window.innerWidth / window.innerHeight;
                this.camera.updateProjectionMatrix();
                this.renderer.setSize(window.innerWidth, window.innerHeight);
            },
            false
        );

        document.addEventListener(
            'mousemove',
            (event) => {
                this.mouseX = (event.clientX - window.innerWidth / 2) / 2;
                this.mouseY = (event.clientY - window.innerHeight / 2) / 2;
            },
            false
        );
    }

    onWindowResize() {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(window.innerWidth, window.innerHeight);
    }

    actionImagePopup(
        action: 'open' | 'close' | 'prev' | 'next' | 'select' | '3d',
        i?: number
    ) {
        let index = this.selectedDisplayImageIndex;
        if (index == null || index === undefined) {
            index = this.product.medias.findIndex((x) => {
                return x.url === this.selectedDisplayImage;
            });
        }

        if (i != null && i !== undefined) {
            index = i;
        }
        const openPictureUrl = (a: number): string => {
            if (Array.isArray(this.product.medias) && this.product.medias[a]) {
                const pic = this.product.medias[a];
                this.selectedDisplayImageIndex = a;
                this.show3D = false;
                return pic.url;
            }
            return null;
        };
        switch (action) {
            case '3d':
                this.renderProduct3D();
                this.zoomedImage = null;
                break;
            case 'close':
                this.zoomedImage = null;
                this.show3D = false;
                break;
            case 'prev':
                index = index - 1;
                if (index < 0) {
                    index = this.product.medias.length - 1;
                }
                this.zoomedImage = openPictureUrl(index);
                break;
            case 'next':
                index = index + 1;
                if (index >= this.product.medias.length) {
                    index = 0;
                }
                this.zoomedImage = openPictureUrl(index);
                break;
            case 'open':
                $('#myModal').modal('show');

                this.zoomedImage = openPictureUrl(index);
                break;
            case 'select':
                this.selectedDisplayImage = openPictureUrl(index);
                break;
            default:
                break;
        }
        this.cdr.detectChanges();
    }

    render() {
        this.frameId = requestAnimationFrame(this.render.bind(this));
        this.renderer.render(this.scene, this.camera);
    }

    animate() {
        requestAnimationFrame(this.animate.bind(this));
        const delta = this.clock.getDelta();
        if (this.mixer) {
            this.mixer.update(delta);
        }
        this.renderer.render(this.scene, this.camera);
    }

    clickEvent() {
        this.status = !this.status;
    }

    backClicked() {
        // this._location.back();
        this.router.navigateByUrl('products');
    }

    ngOnDestroy() {
        // this.selectedVariant = undefined;
        // this.allTags = undefined;
    }

    onClickBookmarkIcon() {
        if (this.isBookmarked) {
            this.removeBookmark();
        } else {
            this.addToBookmark();
        }
    }

    addToBookmark() {
        if (!this.profileService.isLoggedIn()) {

            this.notification.openNotification('Error', 'You must login first', 'error');
            this.profileService.gotoLogin();

            return;
        }
        this.bookmarkAPIRunning = true;
        this.detectChanges();
        this.productService.saveBookmark(this.productId).subscribe(
            (res) => {
                this.isBookmarked = true;
                this.bookmarkAPIRunning = false;
                this.detectChanges();
            },
            (err) => {
                this.bookmarkAPIRunning = false;
                this.isBookmarked = false;
                this.detectChanges();
            }
        );
    }

    removeBookmark() {
        if (!this.profileService.isLoggedIn()) {
            this.notification.openNotification('Error', 'You must login first', 'error');
            this.profileService.gotoLogin();

            return;
        }
        this.bookmarkAPIRunning = true;
        this.detectChanges();
        this.productService.removeBookmark([this.productId]).subscribe(
            (res) => {
                this.isBookmarked = false;
                this.bookmarkAPIRunning = false;
                this.detectChanges();
            },
            (err) => {
                this.bookmarkAPIRunning = false;
                this.isBookmarked = true;
                this.detectChanges();
            }
        );
    }

    detectChanges() {
        try {
            this.cdr.detectChanges();
        } catch {

        }
    }

    inItEmailEnqiryForm() {
        this.emailEnqueryForm = new FormBuilder().group({
            message: [`Could you please send me the retail price of ${this.selectedVariant.seoTitle}`, [Validators.required]],
            quantity:[this.qty, [Validators.required]],
            productId: [this.productId],
            addressId: [null],
            postcode: [null],
            add_line_1: [null, [Validators.required]],
            add_line_2: [null],
            address: [null],
            city: [null, [Validators.required]],
            country: [null, [Validators.required]],
        })
    }

    OnSubmitEnquiry() {
        if (this.emailEnqueryForm.invalid) {
            this.emailEnqueryForm.markAllAsTouched();
            this.cdr.detectChanges();
            return false
        }

        const userData = JSON.parse(localStorage.getItem('userData') || '{}');
        const reqObj = {
            "sellerId": this.selectedInventory.sellerId,
            "productId": this.emailEnqueryForm.value.productId,
            "quantity": this.emailEnqueryForm.get('quantity').value,
            "message":this.emailEnqueryForm.value.message
        }

        if(this.selectedVariant){
            if(this.selectedVariant.id)
            reqObj['variantId'] = this.selectedVariant.id;

            if(this.selectedVariant.selectedOption && this.selectedVariant.selectedOption.id)
            reqObj['optionId'] = this.selectedVariant.selectedOption.id;

            if( this.selectedVariant.selectedSwatch && this.selectedVariant.selectedSwatch.id)
            reqObj['swatchId'] = this.selectedVariant.selectedSwatch.id;
        }

        if (this.showAddAddress) {
            reqObj['address'] = {
                "streetA": this.emailEnqueryForm.value.add_line_1,
                "streetB": this.emailEnqueryForm.value.add_line_2,
                "city": this.emailEnqueryForm.value.city,
                "country": this.emailEnqueryForm.value.country,
                "postcode": this.emailEnqueryForm.value.postcode,
                "addressType": "delivery_address",
                "mobileNumber": userData.phone_number || null,
                "addressName": userData.name || null,
            }
        }

        if(this.emailEnqueryForm.value.addressId) {
            reqObj['addressId'] = this.emailEnqueryForm.value.addressId
        }

        this.showEmailInfoLoader = true;
        this.productService.getPriceByEmail(reqObj).subscribe((res) => {
            if (res) {
                this.notification.openNotification('Success', "Request Raised sucessfully", 'success');
                this.OnCancelEnquiry();
            }
            this.showEmailInfoLoader = false;
            this.cdr.detectChanges();
        },(err)=>{
            this.showEmailInfoLoader = false;
            this.cdr.detectChanges();
        })
    }

    OnCancelEnquiry() {
        this.showEmailInfo = false;
        this.emailEnqueryForm.reset();
        this.cdr.detectChanges();
    }

    onPostalCodeChange(event) {
        const stringValue: string = this.emailEnqueryForm.get('postcode').value || '';
        if (stringValue.trim().length > 0) {
            /* reset var */
            this.addressSuggestion = [];
            /* reset var */
            this.cartService.searchAddress(stringValue, false).subscribe(
                (res) => {
                    if (res) {
                        this.addressSuggestion = res.addresses || [];
                        this.emailEnqueryForm.patchValue({
                            postcode: res.postcode
                        });
                        this.detectChanges();
                    }
                },
                (err) => {
                    this.addressSuggestion = [];
                    this.detectChanges();
                }
            )
        }
    }

    selectAddress(address) {
        if (address) {
            this.emailEnqueryForm.patchValue({
                add_line_1: address.line_1,
                add_line_2: address.line_2,
                city: address.town_or_city,
                country: address.country,
            })
            this.addressSuggestion = [];
        }
    }

    findPropertyValue(obj, key) {
        if (Array.isArray(obj)) {
            for (let i = 0; i < obj.length; i++) {
                const result = this.findPropertyValue(obj[i], key);
                if (result !== undefined) {
                    return result;
                }
            }
        } else if (typeof obj === 'object') {
            for (const prop in obj) {
                if (prop === key) {
                    return obj[prop];
                } else if (typeof obj[prop] === 'object') {
                    const result = this.findPropertyValue(obj[prop], key);
                    if (result !== undefined) {
                        return result;
                    }
                }
            }
        }
    }

    getUserAddedAddress() {
        this.cartService.getAddress().subscribe(
            (res) => {
                this.addedAddress = res;
                this.detectChanges();
            },
            (err) => {
                this.addedAddress = [];
                this.detectChanges();
            });
    }

    addAddress(type: string, address: any) {
        if (type == 'new') {
            this.inItEmailEnqiryForm();
            this.showAddAddress = true;
        }
        else {
            this.showAddAddress = false;
            this.emailEnqueryForm.patchValue({
                addressId: address.id,
                add_line_1: address.streetA,
                add_line_2: address.streetB,
                city: address.city,
                country: address.country,
                postcode: address.postcode
            })
        }
    }

    verifyQuantity(quantity:any){
        if(!quantity || this.qty > quantity){
            this.notification.openNotification('Error', 'This quantity is not possible to delivery', 'error');
            this.qty = 1;
            this.detectChanges()
        }
    }

    updateQuantity(type, quantity: any) {
        switch (type) {
            case '1':
                if (this.qty > 1) {
                    this.qty -= 1
                    this.verifyQuantity(quantity);
                };
                break;
            case '2':
                this.qty += 1;
                this.verifyQuantity(quantity);
                break;

            case '3':
                if (quantity > 1) quantity -= 1
                else quantity = 1;
                this.emailEnqueryForm.patchValue({ quantity: quantity })
                break;
            case '4':
                quantity += 1;
                this.emailEnqueryForm.patchValue({ quantity: quantity })
                break;
            default:
                break;
        }
    }

    allowCondition(type:string , selectedVariant:any, propertyName:string){
        if(type == '1'){
            return selectedVariant.selectedOption && selectedVariant.selectedOption.inventoryStatus && selectedVariant.selectedOption.inventoryStatus != propertyName;  
        }

        if(type == '2'){
            return selectedVariant.selectedSwatch && selectedVariant.selectedSwatch.inventoryStatus && selectedVariant.selectedSwatch.inventoryStatus != propertyName;
        }

        if(type == '3'){
            return selectedVariant.sellerInventories[0] && selectedVariant.sellerInventories[0].variant && selectedVariant.sellerInventories[0].variant.inventoryStatus && selectedVariant.sellerInventories[0].variant.inventoryStatus != propertyName;
        }
    }
}
