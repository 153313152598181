import { Component, OnInit, PLATFORM_ID, Inject, OnDestroy, AfterViewInit, AfterViewChecked, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { TagService } from '../../services/tag.service';
import { Tag } from '../../classes/tag';
import { first } from 'rxjs/operators';
import { GTMEcommerceData, GTMProduct } from '../../classes/ecommerce';
import { Product } from '../../classes/product';
import { isPlatformBrowser } from '@angular/common';
import { NavigationService } from '../../services/navigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '../../services/profile.service';
import { SidebarNavigationService } from '../../services/SidebarNavigationService';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
declare let $: any;

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: [
    './products.component.scss',
    './../product-list/product-list.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsComponent implements OnInit, AfterViewInit, OnDestroy {
  baseHref = environment.baseHref;
  selectedFilters: any;
  tagDectionary: any = {};
  tagUrlSlugDictionary: any = {};
  selectedTag: string;
  isBrowser: boolean;
  viewFilters: boolean;
  moreProductsRemaining: boolean;
  private currentPage = 1;
  productsList: any[] = [];
  brandTags: Map<string, Tag> = new Map();
  categoryTags: any;
  allTags: any;
  tags: any = {};
  parentNames: any = {};
  object = Object;
  productTags: Array<any> = [];
  selectedFilterType = null;
  showLoader = true;
  loadMoreLoader = false;
  categoryTextTitle: any = 'All Products';
  subscription = null;
  isShowCategory = true;
  filterNameListSubscription = null;
  updatedTagsSubscription = null;
  selectedFiltersNames: any = [];
  canShowCategoryFilters = true;
  breadCrumArr = [{
    name: 'All Products',
    tagId: null
  }];

  isTagIdQueryParams = false;
  isPersonalizedView = false;

  allBookmarks = [];
  selectedTagType = '';
  selectedTagId = '';
  navContent: any;
  totalProducts: number;
  
  pageCount:number;
  pageNumber:number;
  showfilter:boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private productService: ProductService,
    private tagService: TagService,
    private navigationService: NavigationService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private profileService: ProfileService,
    private navService: SidebarNavigationService,
    private titleService: Title,
    private metaService: Meta,
  ) {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    // this.manageSubscription();
  }

  ngOnInit() {
    this.currentPage = 1;
    this.productsList = [];
    this.isTagIdQueryParams = false;
    this.manageSubscription();

    this.newOnInit();

    this.isBrowser = isPlatformBrowser(this.platformId);

    // before use this.tagService
    //   .cachedTagsList('brand')
    // after change, use this function
    this.tagService._getTags('brand').subscribe(
      (tags: any) => {
        const dictionary = new Map();
        tags.forEach(f => {
          dictionary[f.tagId] = f;
        });
        this.brandTags = dictionary;
      }, (error) => {
        console.log('error', error);
      });

    // this.tagService
    //   .cachedTagsList('brand')
    //   .pipe(first())
    //   .subscribe((result) => {
    //     this.brandTags = result;
    //   });
    
    if(localStorage.getItem('filterFlag')){
      const flag = localStorage.getItem('filterFlag');
      this.showfilter = flag == 'true' ? true : false;
    }
    else this.showfilter = true;
  }

  newOnInit() {
    const getalltagReq = this.tagService._getTags();
    const categoryTagRequest = this.tagService._getTags('category');
    forkJoin([getalltagReq, categoryTagRequest])
      .subscribe(
        ([allTags, tags]: any) => {
          this.categoryTags = tags;
          this.allTags = allTags;
          allTags.forEach(tag => {
            this.tagDectionary[tag.id] = tag;
            this.tagUrlSlugDictionary[tag.urlSlug] = tag;
          });

          this.route.queryParams.subscribe(queryParam => {
            if (queryParam.searchQuery) {
              this.currentPage = 1;
              this.productsList = [];
              this.selectedFilterType = { 'searchQuery': queryParam.searchQuery };
              this.getSearchedProducts();
            }
          });
          this.route.params.subscribe(param => {
            let selectedFiltersNames: any = this.productService.getTagNameListForSelectedFilters();
            let colors = [],
              brands = [],
              materials = [],
              styles = [],
              rooms = [],
              moodclips = [],
              categories = [];
            const paramsLength = Object.keys(param).length;
            Object.values(param).forEach(f => {

              const strArr = f.split('-');
              const type = strArr[0];
              strArr.shift();
              if (type == 'color') {
                colors = strArr.map(s => s);
              } else if (type == 'brand') {
                brands = strArr.map(s => s);
              }
              if (type == 'material') {
                materials = strArr.map(s => s);
              } else if (type == 'style') {
                styles = strArr.map(s => s);
              }
              if (type == 'room') {
                rooms = strArr.map(s => s);
              } else if (type == 'moodclip') {
                moodclips = strArr.map(s => s);
              }
              if (type == 'category') {
                if (strArr.length == 1) {
                  const category = tags.find(c => c.urlSlug == strArr[0]);
                  this.onSelectCategory(category);

                  categories = [strArr[0]];

                  this.titleService.setTitle(category.metaTitle || category.name);
                  this.metaService.addTags(
                    [
                      { property: 'og:url', content: this.baseHref + this.router.url },
                      { property: 'og:type', content: category.type },
                      { property: 'og:title', content: category.metaTitle },
                      { property: 'og:image', content: category.mainThumbnail },

                    ],
                    true
                  );

                  this.metaService.updateTag({ name: 'description', content: category.metaTitleDescription });
                  this.detectChanges();

                } else if (strArr.length > 1) {
                  const category = tags.find(c => c.urlSlug == strArr[1]);
                  this.onSelectCategory(category);
                  categories = [strArr[1]];

                  this.titleService.setTitle(category.metaTitle || category.name);
                  this.metaService.addTags(
                    [
                      { property: 'og:url', content: this.baseHref + this.router.url },
                      { property: 'og:type', content: category.type },
                      { property: 'og:title', content: category.metaTitle },
                      { property: 'og:image', content: category.mainThumbnail },

                    ],
                    true
                  );

                  this.metaService.updateTag({ name: 'description', content: category.metaTitleDescription });
                  this.detectChanges();
                }
              } else if (paramsLength == 1) {
                let tag: undefined;
                if (brands.length == 1) {
                  tag = this.tagUrlSlugDictionary[brands[0]];
                } else if (styles.length == 1) {
                  tag = this.tagUrlSlugDictionary[styles[0]];
                } else if (moodclips.length == 1) {
                  tag = this.tagUrlSlugDictionary[moodclips[0]];
                } else if (rooms.length == 1) {
                  tag = this.tagUrlSlugDictionary[rooms[0]];
                } else if (materials.length == 1) {
                  tag = this.tagUrlSlugDictionary[materials[0]];
                }
                if (tag) {
                  this.addSeoTags(tag);
                }
              }
            });

            const colourFilter = this.allTags.filter(f => colors.includes(f.urlSlug)).filter(m => m.type == 'colour') || [];
            const brandFilter = this.allTags.filter(f => brands.includes(f.urlSlug)).filter(m => m.type == 'brand') || [];
            const materialFilter = this.allTags.filter(f => materials.includes(f.urlSlug)).filter(m => m.type == 'material') || [];
            const styleFilter = this.allTags.filter(f => styles.includes(f.urlSlug)).filter(m => m.type == 'style') || [];
            const roomFilter = this.allTags.filter(f => rooms.includes(f.urlSlug)).filter(m => m.type == 'room') || [];
            const moodclipFilter = this.allTags.filter(f => moodclips.includes(f.urlSlug)).filter(m => m.type == 'filter') || [];
            const categoryFilter = this.allTags.filter(f => categories.includes(f.urlSlug)).filter(m => m.type == 'category') || [];
            const filters = categoryFilter.concat(colourFilter).concat(brandFilter).concat(materialFilter).concat(styleFilter).concat(roomFilter).concat(moodclipFilter);

            selectedFiltersNames = filters.map(f => ({
              tagId: f.tagId,
              urlSlug: f.urlSlug,
              name: f.name,
              type: f.type,
              parent: f.parent || null
            }));

            this.handleProductListByFilters(selectedFiltersNames, true);
            this.productService.setTagNameListForSelectedFilters(selectedFiltersNames);


            if (this.selectedFilterType && this.selectedFilterType.searchQuery) {

            } else {
              if (filters.length) {
                this.currentPage = 1;
                this.productsList = [];
                this.showLoader = true;
                this.getFilteredProducts();
              } else {
                this.getProductList();
              }
            }

          });
        });
  }

  addSeoTags(tag) {
    this.titleService.setTitle(tag.metaTitle || tag.name);
    this.metaService.addTags(
      [
        { property: 'og:url', content: this.baseHref + this.router.url },
        { property: 'og:type', content: tag.type },
        { property: 'og:title', content: tag.metaTitle },
        { property: 'og:image', content: tag.mainThumbnail },

      ],
      true
    );

    this.metaService.updateTag({ name: 'description', content: tag.metaTitleDescription });
    this.detectChanges();
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
    $(document).ready(function () {
      $(".left-filter").click(function () {
        
        if(localStorage.getItem('filterFlag')){
          const flag = localStorage.getItem('filterFlag');
          localStorage.setItem('filterFlag', flag == 'true' ? 'false' : 'true')
        }
        else localStorage.setItem('filterFlag', 'false')

        $(".products-main").toggleClass("showfilter");
        $(this).toggleClass("changetext");
      });


      $(document).ready(function(){
        $(".two").click(function(){
          $(".right-product").addClass("twoProduct");
          $(".right-product").removeClass("threeProduct");
          $(".right-product").removeClass("fourProduct");
          $(".right-product").removeClass("fiveProduct");
        });

        $(".three").click(function(){
          $(".right-product").addClass("threeProduct");
          $(".right-product").removeClass("twoProduct");
          $(".right-product").removeClass("fourProduct");
          $(".right-product").removeClass("fiveProduct");
        });

        $(".four").click(function(){
          $(".right-product").addClass("fourProduct");
          $(".right-product").removeClass("twoProduct");
          $(".right-product").removeClass("threeProduct");
          $(".right-product").removeClass("fiveProduct");
        });

        $(".five").click(function(){
          $(".right-product").addClass("fiveProduct");
          $(".right-product").removeClass("twoProduct");
          $(".right-product").removeClass("threeProduct");
          $(".right-product").removeClass("fourProduct");
        });
        $('.define-cols button').click(function () {
          $(this).siblings().removeClass('active');
          $(this).addClass('active'); 
      });

  $(".custum-select").click(function () {
    $(".select-ul").toggleClass('showdrop');
  });

      });

    });
  }



  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.filterNameListSubscription) {
      this.filterNameListSubscription.unsubscribe();
    }
    if (this.updatedTagsSubscription) {
      this.updatedTagsSubscription.unsubscribe();
    }
  }

  // before this function used
  // handleNgOnInit(param, res?) {
  //   if (res) {
  //     this.allBookmarks = res.data;
  //   }
  //   const selectedFiltersNames: any = this.productService.getTagNameListForSelectedFilters();
  //   if (selectedFiltersNames && selectedFiltersNames.length && this.isPersonalizedView) {
  //     this.handleProductListByFilters(selectedFiltersNames);
  //   } else {
  //     if (Object.keys(param).length != 0) {
  //       // Tag Id wise filter
  //       if (param.tagId) {
  //         this.isTagIdQueryParams = true;
  //         this.selectedTagType = param.tagType;
  //         // this.selectedTagId = param.tagId;
  //         this.resetSelectedValues();
  //         this.handleProductListByTagId(param.tagId);
  //       } else if (param.q) {
  //         this.selectedFilterType = { 'searchQuery': param.q };
  //         if (this.selectedFilterType.searchQuery) {
  //           this.getSearchedProducts();
  //         }
  //       }
  //     } else {
  //       // Get product list without any filter
  //       this.resetSelectedValues();
  //       this.router.navigate(['products']);
  //       this.getProductList();
  //     }
  //   }
  // }

  // This function will set filters in the sidebar, in the product list and hit API call as well
  handleProductListByFilters(selectedFiltersNames, subscribe?: boolean) {
    if (selectedFiltersNames && selectedFiltersNames.length) {
      const tags: any = {
        brand: {},
        category: {},
        room: {},
        colour: {},
        material: {},
        filter: {},
        style: {}
      };
      selectedFiltersNames.forEach((val) => {
        tags[val.type][val.urlSlug] = true;
      });
      this.selectedFiltersNames = selectedFiltersNames;
      this.tags = tags;
      this.productService.setSelectedFilters(tags);
      if (subscribe) {
        this.selectedFilterType = {
          filter: {
            brandsUrlSlug: this.manipulateFiltersData(tags.brand),
            categoriesUrlSlug: this.manipulateFiltersData(tags.category),
            roomsUrlSlug: this.manipulateFiltersData(tags.room),
            colorsUrlSlug: this.manipulateFiltersData(tags.colour),
            materialsUrlSlug: this.manipulateFiltersData(tags.material),
            moodclipsUrlSlug: this.manipulateFiltersData(tags.filter),
            stylesUrlSlug: this.manipulateFiltersData(tags.style)
          }
        };
        this.selectedFilters = tags;
        // this.handleFilterChangedSubscription(tags);
      }
    }
  }

  // This function will help us to set sidebar checkbox to true
  // Show green chips if tagId field present in the URL
  // Manage breadcrumb
  // handleProductListByTagId(tagId) {
  //   const newTagId = tagId;
  //   let oldTagId = null;
  //   this.getProductsByTagId(tagId);

  //   // This subscription help us to update everything when update the tags
  //   // When tag id change from the store menu then need to udpate the product list
  //   this.updatedTagsSubscription = this.productService.updatedTags.subscribe((tags) => {
  //     if (oldTagId != newTagId) {
  //       oldTagId = tagId;
  //       // this.resetSelectedValues();
  //       const category = this.productService.findCategoryInfoByTagId(tagId);

  //       const pluralName = this.productService.getPluralNameOfCategoryFilter(category.type);

  //       const selectedFilterType = this.productService.getSelectedFilters();
  //       // To update in the sidebar
  //       if (selectedFilterType) {
  //         selectedFilterType[category.type][category.tagId] = true;
  //       }
  //       this.productService.setSelectedFilters(selectedFilterType);

  //       // For green chips
  //       this.selectedFilterType = { filter: {} };
  //       this.selectedFilterType['filter'][pluralName] = [category.tagId];
  //       this.buildTagNameListArr(category);

  //       // Manage breadcrumb
  //       if (category.type == 'category') {
  //         if (category.parent) {
  //           const parentCategory = this.productService.findCategoryInfoByTagId(category.parent);
  //           if (this.selectedTagType) {
  //             let type = this.selectedTagType;
  //             // When redirect to list page from store menu and have a tagId in a url, then need to find a parent category using seperate API
  //             if (this.selectedTagType == 'cat') { type = 'category'; }
  //             this.tagService._getTags(type).subscribe((res: any) => {
  //               this.breadCrumArr.splice(1, 1, res.find(i => i.id == category.parent));
  //             });
  //           } else if (this.isTagIdQueryParams) {
  //             this.breadCrumArr.splice(1, 1, parentCategory);
  //           }
  //           this.breadCrumArr.splice(2, 1, category);
  //         } else {
  //           this.breadCrumArr.splice(1, 1, category);
  //         }
  //       } else {
  //         this.breadCrumArr.splice(1, 1, category);
  //       }
  //     }
  //   });
  // }

  manageSubscription() {
    this.subscription = this.productService.filters.subscribe((filters: any) => {
      if (filters.val && filters.type == 'product') {
        this.handleFilterChangedSubscription(filters.val);
      }
    });

    this.filterNameListSubscription = this.productService.selectedTagNameList.subscribe((tagNameList: any) => {
      this.selectedFiltersNames = tagNameList;
      let parentCategoryFound = false;
      let categoryTextTitle = 'All Products';
      this.selectedFiltersNames.forEach(filter => {
        if (filter && filter.type == 'category') {
          if (filter.parent) {
            parentCategoryFound = true;
          }

          if (!filter.parent) {
            categoryTextTitle = filter.name;
            this.breadCrumArr.splice(1, 1, filter);
          } else {
            if (filter.type == 'category') {
              this.breadCrumArr.splice(2, 1, filter);
            }
            categoryTextTitle = 'All Products';
          }
        }
      });

      if (!this.selectedFiltersNames.length) {
        this.breadCrumArr = [{
          name: 'All Products',
          tagId: null
        }];
      }

      this.categoryTextTitle = categoryTextTitle;
      this.canShowCategoryFilters = parentCategoryFound ? false : true;
    });
  }

  manipulateFiltersData(val: any) {
    const a = [];
    Object.keys(val).forEach((key: any) => {
      console.log(key);
      if (val[key]) {
        a.push(key);
      }
    });
    return a;
  }

  goToCategory(cat: any) {
    const selectedFilters = this.productService.getSelectedFilters();
    const { category, ...rest } = selectedFilters;
    if (Object.keys(rest).some(c => {
      return Object.keys(c).filter((key: string) => c[key]).length > 0;
    })) {
      if (cat.parent) {
        selectedFilters.category[this.tagDectionary[cat.parent].urlSlug] = true;
      }
      selectedFilters.category[cat.urlSlug] = true;
      this.handleFilterChangedSubscription(selectedFilters);
    } else {
      this.router.navigate(cat.parent ? ['/products', 'category-' + this.tagDectionary[cat.parent].urlSlug + '-' + cat.urlSlug] : ['/products', 'category-' + cat.urlSlug]);
    }
  }

  handleFilterChangedSubscription(filters: any) {
    this.selectedFilterType = {
      filter: {
        brandsUrlSlug: this.manipulateFiltersData(filters.brand),
        categoriesUrlSlug: this.manipulateFiltersData(filters.category),
        roomsUrlSlug: this.manipulateFiltersData(filters.room),
        colorsUrlSlug: this.manipulateFiltersData(filters.colour),
        materialsUrlSlug: this.manipulateFiltersData(filters.material),
        moodclipsUrlSlug: this.manipulateFiltersData(filters.filter),
        stylesUrlSlug: this.manipulateFiltersData(filters.style)
      }
    };
    this.currentPage = 1;
    this.showLoader = true;
    this.productsList = [];
    const endPoints: Array<string> = [];
    // const endPoint = '';
    // if (!this.selectedFilterType.filter.categoriesUrlSlug.length) {
    //   endPoints.push(endPoint);
    // }
    let endPoint = '';
    if (this.selectedFilterType.filter.categoriesUrlSlug.length) {
      endPoint = endPoint.concat('category');
      endPoint = endPoint.concat('-');
      const category = this.categoryTags.find(c => c.urlSlug == this.selectedFilterType.filter.categoriesUrlSlug[0]);
      if (category.parent) {
        endPoint = endPoint.concat(this.tagDectionary[category.parent].urlSlug);
        endPoint = endPoint.concat('-');
      }
      this.selectedFilterType.filter.categoriesUrlSlug.map((category: string, i: number) => {
        endPoint = endPoint.concat(category);
        if (i < this.selectedFilterType.filter.categoriesUrlSlug.length - 1) {
          endPoint = endPoint.concat('-');
        }
      });
      endPoints.push(endPoint);
    }
    Object.keys(this.selectedFilterType.filter).forEach(filter => {
      let endPoint = '';
      switch (filter) {
        case 'categoriesUrlSlug':

          break;
        case 'brandsUrlSlug':

          if (this.selectedFilterType.filter.brandsUrlSlug.length) {
            endPoint = endPoint.concat('brand');
            endPoint = endPoint.concat('-');

            this.selectedFilterType.filter.brandsUrlSlug.map((brand: string, i: number) => {
              endPoint = endPoint.concat(brand);
              if (i < this.selectedFilterType.filter.brandsUrlSlug.length - 1) {
                endPoint = endPoint.concat('-');
              }
            });
            endPoints.push(endPoint);
          }
          break;
        case 'roomsUrlSlug':

          if (this.selectedFilterType.filter.roomsUrlSlug.length) {
            endPoint = endPoint.concat('room');
            endPoint = endPoint.concat('-');

            this.selectedFilterType.filter.roomsUrlSlug.map((room: string, i: number) => {
              endPoint = endPoint.concat(room);
              if (i < this.selectedFilterType.filter.roomsUrlSlug.length - 1) {
                endPoint = endPoint.concat('-');
              }
            });
            endPoints.push(endPoint);
          }
          break;
        case 'colorsUrlSlug':

          if (this.selectedFilterType.filter.colorsUrlSlug.length) {
            endPoint = endPoint.concat('color');
            endPoint = endPoint.concat('-');
            this.selectedFilterType.filter.colorsUrlSlug.map((colour: string, i: number) => {
              endPoint = endPoint.concat(colour);
              if (i < this.selectedFilterType.filter.colorsUrlSlug.length - 1) {
                endPoint = endPoint.concat('-');
              }
            });
            endPoints.push(endPoint);
          }
          break;
        case 'materialsUrlSlug':

          if (this.selectedFilterType.filter.materialsUrlSlug.length) {
            endPoint = endPoint.concat('material');
            endPoint = endPoint.concat('-');

            this.selectedFilterType.filter.materialsUrlSlug.map((material: string, i: number) => {
              endPoint = endPoint.concat(material);
              if (i < this.selectedFilterType.filter.materialsUrlSlug.length - 1) {
                endPoint = endPoint.concat('-');
              }
            });
            endPoints.push(endPoint);
          }
          break;
        case 'moodclipsUrlSlug':

          if (this.selectedFilterType.filter.moodclipsUrlSlug.length) {
            endPoint = endPoint.concat('moodclip');
            endPoint = endPoint.concat('-');

            this.selectedFilterType.filter.moodclipsUrlSlug.map((moodclip: string, i: number) => {
              endPoint = endPoint.concat(moodclip);
              if (i < this.selectedFilterType.filter.moodclipsUrlSlug.length - 1) {
                endPoint = endPoint.concat('-');
              }
            });
            endPoints.push(endPoint);
          }
          break;

        case 'stylesUrlSlug':

          if (this.selectedFilterType.filter.stylesUrlSlug.length) {
            endPoint = endPoint.concat('style');
            endPoint = endPoint.concat('-');

            this.selectedFilterType.filter.stylesUrlSlug.map((style: string, i: number) => {
              endPoint = endPoint.concat(style);
              if (i < this.selectedFilterType.filter.stylesUrlSlug.length - 1) {
                endPoint = endPoint.concat('-');
              }
            });
            endPoints.push(endPoint);
          }
          break;
      }
    });

    // this.route.params.subscribe((param) => {
    // if (param.categoryId) {
    //   this.router.navigate(['products', param.categoryId, ...endPoints]);
    // } else
    // if (this.selectedFilterType.filter.categoriesUrlSlug.length) {
    //   this.router.navigate(['products', param.categoryId, ...endPoints]);
    // } else {
    //   this.router.navigate(['products', ...endPoints]);
    //   // this.getFilteredProducts()
    // }
    this.router.navigate(['products', ...endPoints]);
    // });
  }

  onSelectCategory(category: any) {
    if (category && category.tagId) {
      if (this.selectedFilterType && this.selectedFilterType.filter) {
        const pluralName = this.productService.getPluralNameOfCategoryFilter(category.type);
        if (this.selectedFilterType.filter.categoriesUrlSlug || this.selectedFilterType.filter.brands) {

          if (category.parent && this.selectedFilterType.filter[pluralName]) {
            const index = this.selectedFilterType.filter[pluralName].findIndex(cat => cat == category.parent);
            if (index != -1) {
              this.selectedFilterType.filter[pluralName].splice(index, 1);
            }
          }

          if (this.selectedFilterType.filter && (!this.selectedFilterType.filter[pluralName] || !this.selectedFilterType.filter[pluralName].length)) {
            this.selectedFilterType.filter[pluralName] = [];
          }
          // this.selectedFilterType.filter[pluralName].push(category.urlSlug);
          this.selectedFilterType.filter[pluralName] = [category.urlSlug];
        } else {
          this.selectedFilterType.filter[pluralName] = [category.urlSlug];
        }
      } else {
        this.selectedFilterType = {
          filter: {
            categoriesUrlSlug: [category.urlSlug]
          }
        };
      }
      // To update in the sidebar
      const selectedFilters = this.productService.getSelectedFilters();
      if (selectedFilters) {

        if (category.parent) { // unchecked checkbox if category has parent category
          selectedFilters.category[this.tagDectionary[category.parent].urlSlug] = false;
          selectedFilters.category[category.urlSlug] = true;
        } else {
          selectedFilters.category[category.urlSlug] = true;
        }
      }
      this.productService.setSelectedFilters(selectedFilters);

      this.buildTagNameListArr(category);

      if (category.parent) {
        this.canShowCategoryFilters = false;
      } else {
        this.canShowCategoryFilters = true;
      }

      // Update breadcrum when category changed
      if (category.parent) {
        // const parentCategory = this.productService.findCategoryInfoByTagId(category.parent);
        // console.log("category", parentCategory)
        const parentCategory = this.categoryTags.find(f => f.id == category.parent);
        this.breadCrumArr.splice(1, 1, parentCategory);
        this.breadCrumArr.splice(2, 1, category);
      } else {
        this.breadCrumArr.splice(1, 1, category);
      }

      // this.currentPage = 1;
      // this.showLoader = true;
      // this.productsList = [];
      // this.getFilteredProducts();
    }
  }

  // To show chips of product name which is selected
  buildTagNameListArr(category: any) {
    let selectedFiltersNames: any = this.productService.getTagNameListForSelectedFilters();
    if (!selectedFiltersNames.length) {
      selectedFiltersNames.push({
        tagId: category.tagId,
        urlSlug: category.urlSlug,
        name: category.name,
        type: category.type,
        parent: category.parent || null
      });
      this.productService.setTagNameListForSelectedFilters(selectedFiltersNames);
    } else if (selectedFiltersNames.findIndex(tag => tag.tagId == category.tagId) == -1) {

      if (category.parent) { // Remove parent category if tag has parent category
        selectedFiltersNames = selectedFiltersNames.filter((val) => {
          if (val.type == 'category') {
            return val.parent != null;
          } else {
            return val;
          }
        });
      }
      selectedFiltersNames.push({
        tagId: category.tagId,
        urlSlug: category.urlSlug,
        name: category.name,
        type: category.type,
        parent: category.parent || null
      });
      this.productService.setTagNameListForSelectedFilters(selectedFiltersNames);
    }
  }

  trackByProductId(index, product: Product) {
    return product._id;
  }

  modifyTagList(tags: Array<any>) {
    this.tags = {};
    const tagsForSidebar = {};
    const a = this.productService.getTagNameListForSelectedFilters();

    // For product list tags
    tags.forEach(tag => {
      // if (a.findIndex(val => val.tagId == tag.tagId) == -1) {
      if (this.tags[tag.type]) {
        this.tags[tag.type].push(tag);
      } else {
        this.tags[tag.type] = [tag];
      }
      // }
    });

    // For sidebar tags
    tags.forEach(tag => {
      if (tagsForSidebar[tag.type]) {
        tagsForSidebar[tag.type].push(tag);
      } else {
        tagsForSidebar[tag.type] = [tag];
      }
    });

    if (tagsForSidebar['colour']) {
      const colourGroup = {};
      tagsForSidebar['colour'].forEach(element => {
        if (colourGroup[element.parent]) {
          colourGroup[element.parent].push(element);
        } else {
          colourGroup[element.parent] = [];
          colourGroup[element.parent].push(element);
        }
        if (element.parentName) {
          this.parentNames[element.parent] = element.parentName;
        }
      });
      tagsForSidebar['colour'] = Object.keys(colourGroup).map(key => {
        return {
          group: key == 'undefined' ? 'other' : key,
          colours: colourGroup[key]
        };
      });
    }
    const moodclipGroup = {};
    if (tagsForSidebar['filter']) {
      tagsForSidebar['filter'].forEach(element => {
        if (moodclipGroup[element.parent]) {
          moodclipGroup[element.parent].push(element);
        } else {
          moodclipGroup[element.parent] = [];
          moodclipGroup[element.parent].push(element);
        }
        if (element.parentName) {
          this.parentNames[element.parent] = element.parentName;
        }
      });
      tagsForSidebar['filter'] = Object.keys(moodclipGroup).map(key => {
        return {
          group: key == 'undefined' ? 'other' : key,
          filters: moodclipGroup[key]
        };
      });
    }
    const materialGroup = {};
    if (tagsForSidebar['material']) {
      tagsForSidebar['material'].forEach(element => {
        if (materialGroup[element.parent]) {
          materialGroup[element.parent].push(element);
        } else {
          materialGroup[element.parent] = [];
          materialGroup[element.parent].push(element);
        }
        if (element.parentName) {
          this.parentNames[element.parent] = element.parentName;
        }
      });
      tagsForSidebar['material'] = Object.keys(materialGroup).map(key => {
        return {
          group: key == 'undefined' ? 'other' : key,
          materials: materialGroup[key]
        };
      });
    }
    this.productService.setParentNames(this.parentNames);

    this.productService.setTags(tagsForSidebar);
  }

  // Default product list when no filter applied
  getProductList() {
    this.productService
      .getProducts(this.currentPage++)
      .subscribe((res: any) => {
        this.totalProducts = res.totalCount;
        
        this.pageCount = res.pageCount;
        this.pageNumber = res.pageNumber;

        const productResponse = res.retailerProducts;
        if (productResponse && productResponse.length && this.allBookmarks && this.allBookmarks.length) {
          productResponse.forEach((product) => {
            if (product) {
              this.allBookmarks.forEach((bookmark) => {
                product.isBookmarkAPIRunning = false;
                if (bookmark.products == product.id) {
                  product.isBookmarked = true;
                }
              });
            }
          });
        }

        this.modifyTagList(res.tags);
        this.showLoader = false;
        this.loadMoreLoader = false;
        if (productResponse && productResponse.length) {
          this.moreProductsRemaining = true;
          this.productsList = [...this.productsList, ...productResponse];
          for (const product of this.productsList) {
            const findValue = this.findPropertyValue(product.variants, 'inventoryStatus');
            product['priceShow'] = true;
            if (findValue === 'order_by_email') {
              product['priceShow'] = false;
            }
          }
          this.detectChanges();
        } else {
          this.moreProductsRemaining = false;
          this.detectChanges();
        }
        this.navService.setShowNav(true);
        this.detectChanges();
      });
  }

  // When filters apply from sidebar
  getFilteredProducts() {
    this.productService
      .getFilteredProductList(this.currentPage++, this.selectedFilterType)
      .subscribe((res: any) => {
        this.totalProducts = res.totalCount
        this.pageCount = res.pageCount;
        this.pageNumber = res.pageNumber;
        const productResponse = res.retailerProducts;
        this.modifyTagList(res.tags);
        this.showLoader = false;
        this.loadMoreLoader = false;
        if (productResponse.length) {
          this.moreProductsRemaining = true;
          this.productsList = [...this.productsList, ...productResponse];
          for (const product of this.productsList) {
            const findValue = this.findPropertyValue(product.variants, 'inventoryStatus');
            product['priceShow'] = true;
            if (findValue === 'order_by_email') {
              product['priceShow'] = false;
            }
          }
          this.detectChanges();
        } else {
          this.moreProductsRemaining = false;
          this.detectChanges();
        }
        this.navService.setShowNav(true);
        this.detectChanges();
      });
  }

  // From Global Search
  getSearchedProducts() {
    this.productService
      .getProductByusingSearchString(this.currentPage++, this.selectedFilterType.searchQuery)
      .subscribe((res: any) => {
        this.totalProducts = res.totalCount
        this.pageCount = res.pageCount;
        this.pageNumber = res.pageNumber;
        const productResponse = res.products || res.retailerProducts;

        if (productResponse && productResponse.length && this.allBookmarks && this.allBookmarks.length) {
          productResponse.forEach((product) => {
            if (product) {
              this.allBookmarks.forEach((bookmark) => {
                product.isBookmarkAPIRunning = false;
                if (bookmark.products == product.id) {
                  product.isBookmarked = true;
                }
              });
            }
          });
        }

        this.modifyTagList(res.tags);
        this.showLoader = false;
        this.loadMoreLoader = false;
        if (productResponse.length) {
          this.moreProductsRemaining = true;
          this.productsList = [...productResponse];
          for (const product of this.productsList) {
            const findValue = this.findPropertyValue(product.variants, 'inventoryStatus');
            product['priceShow'] = true;
            if (findValue === 'order_by_email') {
              product['priceShow'] = false;
            }
          }
          this.detectChanges();
          // this.productsList = [...this.productsList, ...productResponse];
        } else {
          this.moreProductsRemaining = false;
          this.productsList = [];
          this.detectChanges();
        }
        this.navService.setShowNav(true);
        this.detectChanges();
      });
  }

  getProductsByTagId(tagId: string) {
    this.productService
      .getProductsByTagId(this.currentPage++, tagId)
      .subscribe((res: any) => {
        const productResponse = res.products || res.retailerProducts;

        if (productResponse && productResponse.length && this.allBookmarks && this.allBookmarks.length) {
          productResponse.forEach((product) => {
            if (product) {
              this.allBookmarks.forEach((bookmark) => {
                product.isBookmarkAPIRunning = false;
                if (bookmark.products == product.id) {
                  product.isBookmarked = true;
                }
              });
            }
          });
        }


        this.modifyTagList(res.tags);
        this.showLoader = false;
        this.loadMoreLoader = false;
        if (productResponse.length) {
          this.moreProductsRemaining = true;
          this.productsList = [...this.productsList, ...productResponse];
          for (const product of this.productsList) {
            const findValue = this.findPropertyValue(product.variants, 'inventoryStatus');
            product['priceShow'] = true;
            if (findValue === 'order_by_email') {
              product['priceShow'] = false;
            }
          }
          this.detectChanges();
        } else {
          this.moreProductsRemaining = false;
          this.detectChanges();
        }
        this.navService.setShowNav(true);
        this.detectChanges();
      });
  }

  openProductDetails(selectedVariant: any, product: Product) {
    // this.saveProductSearch();
    this.navigationService.navigateNewProductDetail(product, selectedVariant);
    // this.addDataLayer('productClick', [product], () => {
    // });

    // if (this.isBrowser) {
    //     const gtmProduct2 = this.productService.getGTMProduct(product, <GTMP>{});

    //     window['dataLayer'] = window['dataLayer'] || [];

    //     window['dataLayer'].push(<GTMEcommerceData>{
    //         event: 'productClick',
    //         ecommerce: {
    //             click: {
    //                 actionField: {list: this.seo_title},
    //                 products: [gtmProduct2],
    //             }
    //         },
    //         eventCallback: () => {
    //             this.navigationService.navigateProductDetail(product);
    //         }
    //     });
    // }
  }

  addDataLayer(
    type: 'impressions' | 'productClick',
    list: Product[],
    callback?: () => void
  ) {
    if (this.isBrowser) {
      window['dataLayer'] = window['dataLayer'] || [];

      switch (type) {
        case 'impressions':
          const gtmProducts = list.map((x, i) => {
            return this.productService.getGTMProduct(x, <GTMProduct>{
              position: i + 1,
            });
          });
          window['dataLayer'].push(<GTMEcommerceData>{
            event: 'productImpression',
            ecommerce: Object.assign(
              this.productService.getNewDataLayer().ecommerce,
              {
                currencyCode: 'GBP',
                impressions: gtmProducts,
              }
            ),
          });
          break;
        case 'productClick':
          window['dataLayer'].push(<GTMEcommerceData>{
            event: 'promotionClick',
            ecommerce: Object.assign(
              this.productService.getNewDataLayer().ecommerce,
              {
                promoClick: {
                  promotions: [
                    {
                      id: 'product-detail-recommendations',
                    },
                  ],
                },
              }
            ),
          });
          window['dataLayer'] = window['dataLayer'] || [];
          window['dataLayer'].push(<GTMEcommerceData>{
            event: 'productClick',
            ecommerce: Object.assign(
              this.productService.getNewDataLayer().ecommerce,
              {
                click: {
                  products: gtmProducts,
                },
              }
            ),
            eventCallback: callback,
          });
          break;

        default:
          break;
      }
    }
  }

  viewFiltersPan(event: boolean) {
    this.viewFilters = event;
  }

  onFilter(tag: string) {
    this.selectedTag = tag;
    this.navService.setShowNav(true);
  }
  closeSidebarIfOpen() {
    if (this.viewFilters) {
      this.viewFilters = false;
    }
  }

  onSidebarClose(close: boolean) {
    if (close) {
      this.selectedTag = undefined;
    }
  }

  openFiltersPan(e: any, tag: string) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.viewFilters = true;
    this.selectedTag = tag;
  }

  loadMore() {
    this.loadMoreLoader = true;
    this.showLoader = true;
    if (this.selectedFilterType && this.selectedFilterType.searchQuery) {
      this.getSearchedProducts();
    } else if (this.selectedFilterType) {
      this.getFilteredProducts();
    } else {
      this.getProductList();
    }
  }

  refreshPage() {
    this.viewFilters = false;
    this.selectedTag = null;
    this.router.navigate(['products']);
  }

  resetSelectedValues() {
    this.productService.setSelectedFilters({
      brand: {},
      category: {},
      room: {},
      colour: {},
      material: {},
      filter: {},
      style: {}
    });
    this.productService.setTagNameListForSelectedFilters([]);

    this.currentPage = 1;
    this.showLoader = true;
    this.productsList = [];
  }

  onClickBreadCrumb(val) {
    this.resetSelectedValues();
    if (val.tagId) {
      this.categoryTextTitle = val.name;
      // If we have tagId in the URL and if we want to move to another route for different category tagId
      if (this.isTagIdQueryParams) {
        this.router.navigate(['products']);
        setTimeout(() => {
          const category = this.categoryTags.find(c => c.id == val.tagId);
          this.router.navigateByUrl(`/products/category-${val.urlSlug}`);
          this.onSelectCategory(category);
          // this.router.navigate(['products'], { queryParams: { tagId: val.tagId } });
        });
      } else {
        const category = this.categoryTags.find(c => c.id == val.tagId);
        this.router.navigateByUrl(`/products/category-${val.urlSlug}`);
        this.onSelectCategory(category);
        // this.router.navigate(['products'], { queryParams: { tagId: val.tagId } });
      }
    } else {
      this.selectedFilterType = { filter: {} };
      this.router.navigate(['products']);
      this.getProductList();
    }
  }

  onDeleteFilter(tagInfo) {
    if (tagInfo) {
      const pluralName = this.productService.getPluralNameOfCategoryFilter(tagInfo.type);
      const filterName = this.selectedFilterType.filter[pluralName];
      filterName.splice(filterName.findIndex(a => a == tagInfo.urlSlug), 1);

      if (this.breadCrumArr.length) {
        const i = this.breadCrumArr.findIndex((a) => a.tagId == tagInfo.tagId);
        if (i != -1) {
          // Remove 2 elements from an array if removed chips has parent category
          if (tagInfo.parent) {
            this.breadCrumArr.splice(i - 1, 2);
          } else {
            this.breadCrumArr.splice(i, 1);
          }
        }
      }

      // To update in the sidebar
      const selectedFilters = this.productService.getSelectedFilters();
      if (selectedFilters) {
        selectedFilters[tagInfo.type][tagInfo.urlSlug] = false;
      }
      this.productService.setSelectedFilters(selectedFilters);

      // To update chips
      const selectedFiltersNames: any = this.productService.getTagNameListForSelectedFilters();
      selectedFiltersNames.splice(selectedFiltersNames.findIndex(tag => tag.tagId == tagInfo.tagId), 1);
      this.productService.setTagNameListForSelectedFilters(selectedFiltersNames);
      this.handleFilterChangedSubscription(selectedFilters);
      // this.selectedFiltersNames = selectedFiltersNames;

      // this.currentPage = 1;
      // this.showLoader = true;
      // this.productsList = [];
      // this.getFilteredProducts();
    }
  }
  detectChanges() {
    try {
      this.cdr.detectChanges();
    } catch {

    }
  }

  findPropertyValue(obj, key) {
    if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        const result = this.findPropertyValue(obj[i], key);
        if (result !== undefined) {
          return result;
        }
      }
    } else if (typeof obj === 'object') {
      for (const prop in obj) {
        if (prop === key) {
          return obj[prop];
        } else if (typeof obj[prop] === 'object') {
          const result = this.findPropertyValue(obj[prop], key);
          if (result !== undefined) {
            return result;
          }
        }
      }
    }
  }
}
