import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
          path: '**', component: PageNotFoundComponent
      }
    ])
  ],
  declarations: [PageNotFoundComponent],
  exports: [
    RouterModule
  ]
})
export class WildcardRoutingModule { }
