import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class HttpService {
  private apiBaseUrl = environment.apiNewUrl;
  private apiBaseNewUrl = environment.apiNewUrl;
  private styleBaseUrl = environment.styleBaseUrl;

  constructor(private http: HttpClient) { }

  private buildUrl(host, url, bypassCache?) {
    url = url.startsWith('/') ? url.substring(1) : url;
    host = host.endsWith('/') ? host.substring(0, host.length - 1) : host;
    let cache = '';
    if (bypassCache) {
      cache = (url.includes('?') ? '&' : '?') + 'now=' + Date.now();
    }
    return `${host}/${url}${cache}`;
  }

  public getAbsHttpJson(url: string): Observable<any> {
    return this.http.get<any>(url);
  }

  public getHttpJson(url: string, bypassCache?: true): Observable<any> {
    return this.http.get<any>(this.buildUrl(this.apiBaseUrl, url, bypassCache));
  }

  public postHttpJson(url: string, data: any): Observable<any> {
    return this.http.post<any>(this.buildUrl(this.apiBaseUrl, url), data);
  }

  public getHttpNewJson(url: string, bypassCache?: true): Observable<any> {
    return this.http.get<any>(this.buildUrl(this.apiBaseNewUrl, url, bypassCache));
  }

  public postHttpNewJson(url: string, data: any): Observable<any> {
    return this.http.post<any>(this.buildUrl(this.apiBaseNewUrl, url), data);
  }

  public getCustomerJsonBaseUrl(url: string): Observable<any> {
    return this.http.get<any>(this.buildUrl(this.styleBaseUrl, url));
  }

  public postCustomerJsonBaseUrl(url: string, data: any): Observable<any> {
    return this.http.post<any>(this.buildUrl(this.styleBaseUrl, url), data);
  }

  public putCustomerJsonBaseUrl(url: string, data: any): Observable<any> {
    return this.http.put<any>(this.buildUrl(this.styleBaseUrl, url), data);
  }

  public deleteCustomerJsonBaseUrl(url: string): Observable<any> {
    return this.http.delete<any>(this.buildUrl(this.styleBaseUrl, url));
  }

  public getCustomerAddressJsonBaseUrl(url: string, data: any): Observable<any> {
    return this.http.get<any>(this.buildUrl(this.styleBaseUrl, url), data);
  }
}
