// import 'rxjs/add/observable/of';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {HttpService} from './http.service';
import { PersonroomListResponse, PersonroomResponse, PersonroomEvent,
    PersonroomDesign, StringResponse, PersonroomDesignResponse } from '../classes/personroom';
import { AuthService } from './auth.service';
import { LocalStorage } from './utils.service';
import { Verdict } from '../classes/game';
// import {  } from 'rxjs/observable/ErrorObservable';

@Injectable()
export class PersonroomService {

    constructor(
       private http: HttpService,
    //    private auth: AuthService,
       private localStorage: LocalStorage,

    ) { }

    getPersonroomsList(searchJson, limit, skip, sort): Observable<PersonroomListResponse> {
        let url = '/v1/api/personrooms/postpersonroomslist?';
        if (limit) { url += `limit=${limit}&`; }
        if (skip) { url += `skip=${skip}&`; }
        if (sort) { url += `sort=${sort}&`; }
        return this.http.postHttpJson(url, searchJson);
    }
    elasticPersonroomsList(
        searchJson: any, prefix: string, limit: number, skip: number, sort: string, seed: string
    ): Observable<PersonroomListResponse> {
        let url = '/v1/api/personrooms/postelasticsearchpersonrooms?';
        if (limit) { url += `limit=${limit}&`; }
        if (skip) { url += `skip=${skip}&`; }
        if (sort) { url += `sort=${sort.replace(' ', '+')}&`; }
        if (prefix) { url += `prefix=${prefix.replace(' ', '+')}&`; }
        if (seed) { url += `seed=${seed}&`; }
        return this.http.postHttpJson(url, searchJson);
    }

    // elasticPersonalisedPersonroomsList(
    //     verdictId: string, searchJson: any, prefix: string, limit: number, skip: number, sort: string, seed: string
    // ): Observable<PersonroomListResponse> {

    //     if (!this.auth.isLoggedIn()) {
    //         return throwError({error: {message: 'You must be logged in'}});
    //     }

    //     let url = '/v1/api/personrooms/postpersonalisedsearchpersonrooms?';
    //     if (limit) { url += `limit=${limit}&`; }
    //     if (skip) { url += `skip=${skip}&`; }
    //     if (sort) { url += `sort=${sort.replace(' ', '+')}&`; }
    //     if (prefix) { url += `prefix=${prefix.replace(' ', '+')}&`; }
    //     if (verdictId) { url += `verdictID=${verdictId}&`; }
    //     if (seed) { url += `seed=${seed}&`; }
    //     return this.http.postHttpJson(url, searchJson);
    // }
    elasticPersonalisedPersonrooms( // new
        verdict: Verdict, searchJson: any, prefix: string, limit: number, skip: number, sort: string, seed: string
    ): Observable<PersonroomListResponse> {



        let url = '/v1/api/personrooms/postpersonalisedpersonrooms?';
        if (limit) { url += `limit=${limit}&`; }
        if (skip) { url += `skip=${skip}&`; }
        if (sort) { url += `sort=${sort.replace(' ', '+')}&`; }
        if (prefix) { url += `prefix=${prefix.replace(' ', '+')}&`; }
        // if (verdictId) { url += `verdictID=${verdictId}&`; }
        if (seed) { url += `seed=${seed}&`; }
        return this.http.postHttpJson(url, {search: searchJson, verdict: verdict.verdict});
    }

    getPersonroomDetails(id: string): Observable<PersonroomResponse> {
        return this.http.getHttpJson('/v1/api/personrooms/getpersonroomdetailsweb?personRoomID=' + id);
    }

    clearSearchParameters() {
        this.localStorage.setItem('personrooms/search', null);
    }
    savePersonroomSearch(obj: any) {
        this.localStorage.setItem('personrooms/search', JSON.stringify(obj));
    }
    updatePersonroomSearch(propertyKey: string, prop: any, clearOthers: boolean) {
        const searchStr = this.localStorage.getItem('personrooms/search');
        if (searchStr) {
            try {
                let obj = JSON.parse(searchStr);
                if (clearOthers) { obj = {}; }
                obj[propertyKey] = prop;
                this.localStorage.setItem('personrooms/search', JSON.stringify(obj));
            } catch (e) {
            }
        }
    }

    restorePersonroomSearch(): any {
        const searchStr = this.localStorage.getItem('personrooms/search');
        if (searchStr) {
            try {
                const obj = JSON.parse(searchStr);
                return obj;

            } catch (e) {
                return null;
            }
        } else { return null; }
    }

    getRecommmedations(list: PersonroomEvent[], limit: number, skip: number, sort: string): Observable<PersonroomListResponse> {
        let url = '/v1/api/personrooms/postrecommendations?';
        if (limit) { url += `limit=${limit}&`; }
        if (skip) { url += `skip=${skip}&`; }
        if (sort) { url += `sort=${sort.replace(' ', '+')}&`; }
        return this.http.postHttpJson(url, list);
    }
    savePersonroomDesign(personroomID: string, data: PersonroomDesign): Observable<StringResponse> {
        const url = `/v1/api/personrooms/postsavepersonroomdesign?personroomID=${personroomID}`;
        return this.http.postHttpJson(url, data);
    }

    getPersonroomDesign(personroomID: string, userId: string, force?: boolean): Observable<PersonroomDesignResponse> {
        let url = `/v1/api/personrooms/getpersonroomdesign?personroomID=${personroomID}&`;
        if (userId) {url += `userId=${userId}&`; }
        if (force) {url += `_force=true&`; }
        return this.http.getHttpJson(url);
    }
    deletePersonroomDesign(personroomID: string): Observable<StringResponse> {
        const url = `/v1/api/personrooms/deletepersonroomdesign?personroomID=${personroomID}&timestamp=${Date.now()}`;
        return this.http.getHttpJson(url, true);
    }
    setDesignStatus(personroomID: string, status: 'active'|'public'): Observable<StringResponse> {
        const url = `/v1/api/personrooms/setpersonroomdesignstatus?personroomID=${personroomID}&status=${status}`;
        return this.http.getHttpJson(url, true);
    }



}
