    import { Component, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
    import { Router } from '@angular/router';
    import { AuthService } from '../services/auth.service';


    @Component({
        selector: 'app-post-login',
        templateUrl: './post-login.component.html',
        styleUrls: ['./post-login.component.css'],
        changeDetection: ChangeDetectionStrategy.OnPush,

    })

    export class PostLoginComponent implements AfterViewInit {

        router: Router;
        constructor(
            private _router: Router,
            private auth: AuthService,
            private cd: ChangeDetectorRef,
        ) {
            this.router = _router;
        }

        ngAfterViewInit() {
            this.auth.setSession(this.router.url);
            this.cd.detectChanges();
        }

    }
