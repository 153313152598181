import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ProductRoutingModule } from './product-routing.module';
import { ProductSnippetComponent } from './product-snippet/product-snippet.component';
// import { ProductSnippetSmallComponent } from './product-snippet-small/product-snippet-small.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { ProfileModule } from '../profile/profile.module';
import { SellerStoreComponent } from './seller-store/seller-store.component';
// import { SafeHtmlProduct } from './product-detail/product-detail.component';
import { NgxJsonLdModule } from 'ngx-json-ld';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SharedModule } from '../shared/shared.module';
// import { BrandStoreSnippetComponent } from './brand-store-snippet/brand-store-snippet.component';
// import { RoomStoreSnippetComponent } from './room-store-snippet/room-store-snippet.component';
import { CategoryStoreSnippetComponent } from './category-store-snippet/category-store-snippet.component';
import { ProductsComponent } from './products/products.component';
import { TagsFilterComponent } from './tags-filter/tags-filter.component';
// import { ProductDetailsComponent } from './product-details/product-details.component';
import { ZoomImageComponent } from './zoom-image/zoom-image.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SimilarProductsComponent } from './product-detail/similar-products/similar-products.component';
import { AssociatedProductsComponent } from './product-detail/associated-products/associated-products.component';
import { CartService } from '../purchase/cart/cart.service';
import { FilterOptionList } from './filter-option/filter-option-list.component';
import { Sidebar } from './sidebar/sidebar.component';
import { ProductService } from '../services/product.service';
import { SidebarNavigationService } from '../services/SidebarNavigationService';
// import { StyleStoreSnippetComponent } from './style-store-snippet/style-store-snippet.component';
// import {FacebookModule} from 'ngx-facebook';
// import { BrandsListComponent } from './brands-list/brands-list.component';
// import { CategoryListComponent } from './category-list/category-list.component';
@NgModule({
    imports: [
        CommonModule,
        ProductRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        // ProfileModule,
        SharedModule,
        NgxJsonLdModule,
        LazyLoadImageModule,
        SlickCarouselModule
        // FacebookModule.forRoot(),
    ],
    exports: [
        ProductSnippetComponent,
        FilterOptionList,
        Sidebar
        //     ProductSnippetSmallComponent,

    ],
    declarations: [
        ProductListComponent,
        ProductDetailComponent,
        ProductSnippetComponent,
        SimilarProductsComponent,
        AssociatedProductsComponent,
        // ProductSnippetSmallComponent,
        SellerStoreComponent,
        // BrandStoreSnippetComponent,
        // RoomStoreSnippetComponent,
        CategoryStoreSnippetComponent,
        ProductsComponent,
        TagsFilterComponent,
        // ProductDetailsComponent,
        ZoomImageComponent,
        FilterOptionList,
        Sidebar
        // StyleStoreSnippetComponent,
        // BrandsListComponent,
        // CategoryListComponent,
        // UiImageLoaderDirective,
        // SafeHtmlProduct,
    ],
    providers: [
        CartService,
        ProductService,
        SidebarNavigationService
    ]
})
export class ProductModule { }
