import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef, OnChanges } from '@angular/core';
import { Tag } from '../../classes/tag';
import { Store, Product } from '../../classes/product';
import { TagService } from '../../services/tag.service';
import { first } from 'rxjs/operators';
import { NavigationService } from '../../services/navigation.service';
import { UtilsService } from '../../services/utils.service';

class RoomCategory {
    categoryName: string;
    thumbnail: string;
    count: number;
    categoryId: string;
    invert: string;
}


@Component({
  selector: 'app-category-store-snippet',
  templateUrl: './category-store-snippet.component.html',
  styleUrls: ['./category-store-snippet.component.css']
})
export class CategoryStoreSnippetComponent implements OnInit, OnChanges {
    categoryStore: Store;
    @Input() products: Product[];
    categoryTags: Map<string, Tag>;
    @Input() aggregations: Map<string, Map<string, number>>;
    roomCategories: RoomCategory[];
    @Input() storeType: 'room'|'category'|'brand'|'style';

    @Output() openCategory: EventEmitter<any> = new EventEmitter();
    @Input() category: Tag;

    constructor(
        private tagService: TagService,
        private cd: ChangeDetectorRef,
        private navigationService: NavigationService,
        private utilService: UtilsService,

    ) { }

    ngOnInit() {
        this.categoryStore = <Store>{
            name: this.category.name,
            mainPicture: this.category.mainPicture,
            seo_description: this.utilService.htmlToPlaintext(this.category.description),
            _id: this.category._id,
        };

        this.tagService.cachedTagsList('category').pipe(first()).subscribe(result => {
            this.categoryTags = result;
            this.roomCategories = this.findProductInCategory();
            this.cd.detectChanges();
        });
    }

    ngOnChanges() {
// console.log('58 cat store snippet ', this.category)
        this.roomCategories = this.findProductInCategory();
        this.cd.detectChanges();
    }

    openCategoryClicked(catId: string): void {
        this.openCategory.emit([this.categoryTags[catId]]);
    }

    // htmlToPlaintext(text) {
    //     return text ? String(text).replace(/<[^>]+>/gm, '') : '';
    // }

    findProductInCategory(): RoomCategory[] {
        const output = <RoomCategory[]>[];
        const alreadyChosenIndices = <number[]>[];

        const categoriesMap = this.aggregations['category'];
// console.log('76', categoriesMap)
        if (!categoriesMap) { return output; }
        Object.keys(categoriesMap).forEach(k => {
// console.log('79', k, this.categoryTags[k]);

            if (output.length < 6 && this.categoryTags[k]) {
                const insertItem = <RoomCategory>{
                    categoryName: this.categoryTags[k].name,
                    thumbnail: this.categoryTags[k].mainThumbnail,

                    count: categoriesMap[k],
                    categoryId: this.categoryTags[k]._id,
                    invert: '0.4',
                };
                for (let index = 0; index < this.products.length; index++) {
                    // const element = this.products[index];
                    if (this.products[index].category &&
                        this.products[index].category.indexOf(k) >= 0 &&
                        alreadyChosenIndices.indexOf(index) < 0) {

                        // this.products.splice(index,1);
                        insertItem.thumbnail = this.products[index].mainThumbnail;
                        insertItem.invert = '0';
                        alreadyChosenIndices.push(index);
                        index = this.products.length;
                    }
                }
                output.push(insertItem);

            }
        });
        // console.log(this.category);
        return output.sort((a, b) => b.count - a.count).filter(item =>
            this.category.type !== 'category' || item.categoryId.indexOf(this.category._id) >= 0

        );

    }
    tagInfoUrl(tag: Tag) {
        this.navigationService.tagPageWindow(tag._id);
    }
    getDescriptionText(tag: Tag): string {
        const limitLength = 250;
        const description = this.utilService.htmlToPlaintext(tag.description);
        return description.length > limitLength ? description.substring(0, limitLength) + '...' : description;
    }

}
