import { Injectable } from '@angular/core';
// import 'rxjs/add/observable/of';
import { switchMap, mergeMap } from 'rxjs/operators';

import { Observable, of } from 'rxjs';
import { HttpService } from './http.service';
import { Tag, TagResponse, TagListResponse } from '../classes/tag';
import { LocalStorage, UtilsService } from './utils.service';
import { NewHttpService } from './new.http.service.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class TagService {
  private allTags: Map<string, Map<string, Tag>> = new Map();
  oneDayMinutes: number = 24 * 60;
  styleTags = [];
  selectedStyles = [];

  constructor(
    private _http: HttpClient,
    private http: HttpService,
    private localStorage: LocalStorage,
    private utilService: UtilsService,
    private htttp: NewHttpService
  ) {
    this._getTags();
  }

  private getTagsList(): Observable<TagListResponse> {
    return this.http.getHttpJson('/v1/api/tags/getalltags');
  }

  getTagDetail(tagId: string): Observable<TagResponse> {
    return this.http.getHttpJson(`/v1/api/tags/gettagdetails?tagID=${tagId}`);
  }

  cachedTagDetail(tagId: string, type: string): Observable<Tag> {
    type = type || '_all';

    return this.cachedTagsList(type).pipe(
      switchMap((tagsList: Map<string, Tag>) => {
        return of(tagsList[tagId]);
      })
    );
  }
  cachedTagsList(type?: string): Observable<Map<string, Tag>> {
    return this.utilService.checkSophomore('cachedTagsList').pipe(
      mergeMap((result) => {
        type = type || '_all';

        const tagsStr = this.localStorage.getItem(`tags/${type}`);
        if (tagsStr) {
          try {
            return of(JSON.parse(tagsStr));
          } catch (e) { }
        }

        if (this.allTags && this.allTags.hasOwnProperty(type)) {
          return of(this.allTags[type]);
        }

        this.utilService.markSophomore('cachedTagsList');

        return this.getTagsList().pipe(
          switchMap((tagsList: TagListResponse) => {
            const list = tagsList.data.list.sort((x, y) =>
              x.name > y.name ? 1 : x.name < y.name ? -1 : 0
            );
            this.allTags = new Map();
            for (let i = 0; list && i < list.length; i++) {
              list[i].icon = list[i].name
                .split(' ')
                .map((x) => {
                  x = x.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
                  if (x && x[0]) {
                    return x[0].toUpperCase();
                  } else {
                    return 'T';
                  }
                })
                .join('')
                .slice(0, 2);
              for (const k in list[i]) {
                if (list[i].hasOwnProperty(k)) {
                  if (tagsList.data.jsonFields.indexOf(k) >= 0) {
                    try {
                      list[i][k] = JSON.parse(list[i][k]);
                    } catch (e) { }
                  }
                }
              }
              if (!this.allTags['_all']) {
                this.allTags['_all'] = new Map();
              }
              this.allTags['_all'][list[i]._id] = list[i];
              if (!this.allTags[list[i].type]) {
                this.allTags[list[i].type] = new Map();
              }
              this.allTags[list[i].type][list[i]._id] = list[i];
            }
            Object.keys(this.allTags).forEach((tagType) => {
              this.localStorage.setItem(
                `tags/${tagType}`,
                JSON.stringify(this.allTags[tagType]),
                this.oneDayMinutes
              );
            });
            this.utilService.clearSophomore('cachedTagsList');

            return of(this.allTags[type]);
          })
        );
      })
    );
  }

  _getTags(type?: string) {
    if (type) {
      const params = new HttpParams().set('type', type);
      return this._http.get(`${environment.apiNewUrl}/web/tags`, { params: params });
    } else {
      // currently there is no api('web/product-tags')
      // return this.http.getHttpNewJson('web/product-tags');
      return this._http.get(`${environment.apiNewUrl}/web/tags`);
    }
  }

  getStyleTags() {
    return this.styleTags;
  }

  setStyleTags(tags: any) {
    this.styleTags = tags;
  }

  setSelectedStyles(styles) {
    this.selectedStyles = styles;
    return of(true);
  }

  getSelectedStyles() {
    return of(this.selectedStyles);
  }

  saveVerdictStyle(data) {
    return this.http.postCustomerJsonBaseUrl(`/api/customer/style-selector`, data);
  }
}
